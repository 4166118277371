import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useFormik } from "formik";
import { useMediaQuery } from "react-responsive";
import InputFiles from "react-input-files";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import * as Yup from "yup";
import "react-circular-progressbar/dist/styles.css";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import Summary from "../../../components/molecules/Summary";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import {
  randomColor,
  returnInitals,
  UpperCaseFirst,
  isEnoughSummary,
} from "../../../HelperFunctions";
import Basic from "../../buildYourProfile/templates/Basic";
import {
  GET_USER,
  SAVE_SUMMARY,
  GET_NOTIFICATION_SETTING,
  CHANGE_SUBSCRIPTION,
  SAVE_SKILL,
} from "../../../apolloUtils";
import Model from "../../../components/molecules/Model";
import ImageUploadModal from "../../../components/orgnisms/ImageUploadModal";
import VideoCvTemplate from "../../../components/templates/VideoCvTemplate";
import SkillsTemplate from "../../../components/templates/SkillsTemplate";
import ExperienceTemplate from "../../../components/templates/ExperienceTemplate";
import EducationTemplate from "../../../components/templates/EducationTemplate";
import AppPassword from "../../../components/molecules/AppPassword";
import AppCircularProgress from "../../../components/atoms/AppCircularProgress";
import { fire } from "../../../firebase";
import NotificationOption from "../../../components/molecules/NotificationsOption";
import UploadCv from "../../../components/molecules/UploadCv";

// import { updateBasic } from "../../../redux/userDucks";

export default function Profile() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  // const dispatch = useDispatch();
  const skills = useSelector((state) => state.Skill);
  const { user, tip } = useSelector((state) => state.User);
  // const { user, strength, tip } = useSelector((state) => state.User);

  const [saveSummary] = useMutation(SAVE_SUMMARY);
  const [saveSkill] = useMutation(SAVE_SKILL);
  // const [setAlertsAndPromotions] = useMutation(SET_ALERTS);
  const [changeSubscription, { loading: subscribeLoading }] =
    useMutation(CHANGE_SUBSCRIPTION);

  const [fetchUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    pollInterval: 0.5,
  });
  const {
    loading,
    data,
    refetch: refetchSettings,
  } = useQuery(GET_NOTIFICATION_SETTING, {
    variables: { input: user.id },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [temp, setTemp] = useState(null);
  const [image, setImage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [promotions, setPromotions] = useState(null);
  const [save, setSave] = useState(false);
  const [error, setError] = useState(false);

  const useStyles = makeStyles({
    dropAvatar: {
      height: isDesktop ? "120px" : "60px",
      width: isDesktop ? "120px" : "60px",
      backgroundColor: randomColor(user.fullName),
    },
  });
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      summary: user.summary || "",
    },
    validationSchema: Yup.object().shape({
      summary: Yup.string()
        .required("Summary is required")
        .test(
          "word number",
          "Please enter a minimum of 50 characters",
          (value) => isEnoughSummary(value)
        ),
    }),
    onSubmit: (value) => {
      setIsLoading(true);
      saveSummary({
        variables: { input: { userId: user.id, summary: value.summary } },
      }).then(() => {
        saveSkill({ variables: { input: skills } });
        setIsLoading(false);
        fetchUser();
      });

      // here
    },
  });

  const passwordState = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .min(6, "New password short should'nt be less than 6")
        .matches(/\d+/, "New password must contain a Number")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .required()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "passwords must match with new password"
        ),
    }),
    onSubmit: (value, { resetForm }) => {
      setIsChangingPassword(true);
      const user = fire.auth().currentUser;
      user
        .updatePassword(value.newPassword)
        .then(() => {
          setIsChangingPassword(false);
          setIsSuccess(true);
          resetForm();
        })
        .catch((err) => {
          if (err.code === "auth/requires-recent-login") {
            setIsChangingPassword(false);
            const credential = fire.auth.EmailAuthProvider.credential(
              user.email,
              value.oldPassword
            );
            user
              .reauthenticateWithCredential(credential)
              .then(() => {
                user.updatePassword(value.newPassword).then(() => {
                  setIsChangingPassword(false);
                  setIsSuccess(true);
                  resetForm();
                });
              })
              .catch((e) => {
                if (e) {
                  setIsError(true);
                }
              });
          }
        });
    },
  });

  // const alertsFormik = useFormik({
  //   initialValues: {},
  // });

  const promotionsFormik = useFormik({
    initialValues: {
      emails: false,
      pushNotification: true,
      sms: false,
    },
    onSubmit: (values) => {
      const sendSettings = {
        user: user.id,
      };

      if (promotions.emails !== values.emails) {
        sendSettings.emails = values.emails;
      }
      if (promotions.sms !== values.sms) {
        sendSettings.sms = values.sms;
      }
      if (Object.keys(sendSettings).length) {
        changeSubscription({ variables: { input: sendSettings } }).then(() => {
          refetchSettings();
        });
      }
    },
  });

  useEffect(() => {
    if (!loading && data) {
      promotionsFormik.setFieldValue(
        "emails",
        data.getNotificationSettings.emails
      );
      promotionsFormik.setFieldValue("sms", data.getNotificationSettings.sms);
      setPromotions(data.getNotificationSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  const handlePromotionOptionChange = (value) => {
    promotionsFormik.setFieldValue(value, !promotionsFormik.values[value]);
  };
  // const handleOptionChange = () => {};

  const handleFile = (file) => {
    const acceptedFormats = ["image/jpeg", "image/png", "image/jpg"];
    if (acceptedFormats.find((format) => format === file[0]?.type)) {
      setTemp(window.URL.createObjectURL(file[0]));
      setImage(file[0]);
      setPreviewImage(true);
    }
  };

  const handleDone = () => {
    fetchUser();
  };

  return (
    <div className="d-flex justify-content-center _bg_color">
      <div
        className={`${isDesktop ? "w-75" : isTablet ? "w-82" : "w-95_"
          }  d-flex justify-content-between flex-wrap`}
      >
        <div className={`${isDesktop ? "w-50 pe-2" : "w-100"} pt-3`}>
          <div className="_panel">
            <div
              className={`p-3 d-flex ${isDesktop ? "align-items-center" : ""}`}
            >
              <div className="me-4">
                <Avatar
                  className={classes.dropAvatar}
                  src={user.photoUrl || null}
                >
                  <AppText size="_26" color="_c0">
                    {returnInitals(`${user.firstName} ${user.lastName}`)}
                  </AppText>
                </Avatar>
              </div>
              <div>
                <div style={{ marginBottom: "-0.3rem" }}>
                  <AppText size={isDesktop ? "_26" : "_18"}>{`${UpperCaseFirst(
                    user.firstName
                  )} ${UpperCaseFirst(user.lastName)}`}</AppText>
                </div>
                {/* <AppText size='_14' color='_c90'>
                  software developer
                </AppText> */}
                <div className="mt-2">
                  <InputFiles accept="" onChange={(file) => handleFile(file)}>
                    <AppButton color="_white">
                      <AppText size="_14">Change Photo</AppText>
                    </AppButton>
                  </InputFiles>
                </div>
              </div>
            </div>
            <div className="_sep_" />
            <div
              className={`p-3 d-flex align-items-center ${!isDesktop ? "flex-wrap" : ""
                }`}
            >
              <div className="me-3">
                <AppCircularProgress value={100} />
              </div>
              <div className="w-30">
                <AppText size="_18" weight="_medium">
                  {100}%
                </AppText>
                <div style={{ marginTop: "-0.42rem" }}>
                  <AppText size="_14" color="_c90">
                    Profile Strength
                  </AppText>
                </div>
              </div>
              {tip !== "" && (
                <div className={isDesktop ? "ms-3 d-flex" : "mt-4"}>
                  {isDesktop ? (
                    <div className="_sep2 me-3" />
                  ) : (
                    <AppText size="_12" color="_c90">
                      TIP
                    </AppText>
                  )}

                  <AppText size="_14" color="_c90">
                    {tip}
                  </AppText>
                </div>
              )}
            </div>
          </div>
          <div className="my-3">
            <Basic
              isProfile={true}
              data={user}
              edit={true}
              done={(res) => handleDone(res)}
            />
          </div>

          <div id="password">
            <BoardingPanel
              heading="Password"
              subHeading="Change your account password"
              onSubmitText="Change Password"
              onSubmit={passwordState.handleSubmit}
              isLoading={isChangingPassword}
            >
              {isSuccess && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <AppText size="_14" color="_g90">
                    <strong>Password</strong> has been changed.
                  </AppText>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setIsSuccess(false)}
                  ></button>
                </div>
              )}
              {isError && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <AppText size="_14" color="_r100">
                    <strong>Old password</strong> is wrong!.
                  </AppText>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setIsError(false)}
                  ></button>
                </div>
              )}

              <div className="mb-3">
                <AppPassword
                  name="oldPassword"
                  value={passwordState.values.oldPassword}
                  onChange={passwordState.handleChange}
                  onBlur={passwordState.handleBlur}
                  label="ENTER OLD PASSWORD"
                  placeholder="ENTER OLD PASSWORD"
                />
                <div>
                  {passwordState.touched.oldPassword &&
                    passwordState.errors.oldPassword ? (
                    <AppText size="_12" color="_r100">
                      {passwordState.errors.oldPassword}
                    </AppText>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <AppPassword
                  name="newPassword"
                  value={passwordState.values.newPassword}
                  onChange={passwordState.handleChange}
                  onBlur={passwordState.handleBlur}
                  label="ENTER NEW PASSWORD"
                  placeholder="ENTER NEW PASSWORD"
                />
                <div>
                  {passwordState.touched.newPassword &&
                    passwordState.errors.newPassword ? (
                    <AppText size="_12" color="_r100">
                      {passwordState.errors.newPassword}
                    </AppText>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <AppPassword
                  name="confirmPassword"
                  value={passwordState.values.confirmPassword}
                  onChange={passwordState.handleChange}
                  onBlur={passwordState.handleBlur}
                  label="CONFIRM NEW PASSWORD"
                  placeholder="CONFIRM NEW PASSWORD"
                />
                <div>
                  {passwordState.touched.confirmPassword &&
                    passwordState.errors.confirmPassword ? (
                    <AppText size="_12" color="_r100">
                      {passwordState.errors.confirmPassword}
                    </AppText>
                  ) : null}
                </div>
              </div>
            </BoardingPanel>

            <div className="mt-3" />

            <BoardingPanel
              heading="Notifications"
              subHeading="Receive notifications about new job alerts, job application status and other information from Tap Recruit."
              onSubmitText="Save"
              onSubmit={promotionsFormik.handleSubmit}
              isLoading={subscribeLoading}
            >
              <div className="d-flex flex-wrap" id="#noti">
                <div className="w-50">
                  <AppText weight="_medium" size="_16">
                    Promotions
                  </AppText>
                  <div className="mt-3" />
                  <div>
                    <NotificationOption
                      label="Email"
                      onChange={() => handlePromotionOptionChange("emails")}
                      name="emails"
                      checked={promotionsFormik.values.emails}
                    />
                    <div className="mt-2" />
                    <NotificationOption
                      disabled={true}
                      label="Push notifications"
                      onChange={() =>
                        handlePromotionOptionChange("pushNotification")
                      }
                      name="pushNotification"
                      checked={promotionsFormik.values.pushNotification}
                      details="For your mobile or tablet device"
                    />
                    <div className="mt-2" />
                    <NotificationOption
                      label="Text messages"
                      onChange={() => handlePromotionOptionChange("sms")}
                      name="sms"
                      checked={promotionsFormik.values.sms}
                    />
                  </div>
                </div>
                {/* <div className="w-50">
                  <AppText weight="_medium" size="_16">
                    Job Alerts
                  </AppText>
                  <div className="mt-3" />
                  <div>
                    <NotificationOption
                      label="Email"
                      onChange={() => handleOptionChange("email")}
                      name="email"
                      checked={alertsFormik.values.email}
                    />
                    <div className="mt-2" />
                    <NotificationOption
                      label="Push notifications"
                      onChange={() => handleOptionChange("pushNotification")}
                      name="pushNotification"
                      checked={alertsFormik.values.pushNotification}
                      details="For your mobile or tablet device"
                    />
                    <div className="mt-2" />
                    <NotificationOption
                      label="Text messages"
                      onChange={() => handleOptionChange("sms")}
                      name="sms"
                      checked={alertsFormik.values.sms}
                    />
                  </div>
                  <div className="mt-3" />
                </div> */}
              </div>
            </BoardingPanel>
          </div>
        </div>
        <div className={`${isDesktop ? "w-50 ps-2" : "w-100"} mt-3`}>
          <BoardingPanel
            heading="My Profile"
            subHeading="Setup my profile"
            onSubmitText="Save Changes"
            onSubmit={formik.handleSubmit}
            isLoading={isLoading}
          >
            <Summary
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="summary"
              value={formik.values.summary}
            />

            {formik.errors.summary && formik.touched.summary ? (
              <div>
                <AppText size="_14" color="_r100">
                  {formik.errors.summary}
                </AppText>
              </div>
            ) : null}

            <div className="mt-3 d-flex flex-column">
              <AppText size="_14" color="_c50">
                VIDEO
              </AppText>
              <VideoCvTemplate />
            </div>
            <div className='mt-3 d-flex flex-column'>
              <AppText size='_14' color='_c50'>
                CV
              </AppText>
              <div className={'py-2'}>
                <UploadCv
                  setSave={setSave}
                  save={save}
                  setError={setError}
                  error={error}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  title="Updated"
                  url={user.cvUrl}
                />
              </div>
            </div>
            <div className="mt-3">
              <ExperienceTemplate />
            </div>
            <div className="mt-3">
              <EducationTemplate />
            </div>
            <div className="mt-3">
              <SkillsTemplate />
            </div>
          </BoardingPanel>
        </div>
      </div>
      {previewImage && (
        <Model title="Image Preview" onClose={() => setPreviewImage(false)}>
          <ImageUploadModal
            image={image}
            temp={temp}
            onClose={() => setPreviewImage(false)}
          />
        </Model>
      )}
    </div>
  );
}
