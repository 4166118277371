import moment from "moment";

export const returnInitals = (str) => {
  let name = str;
  name = name?.split(" ");
  return name?.map((n) => n.slice(0, 1).toUpperCase());
};

export const randomColor = (name) => {
  const colors = ["#1765d8", "#E6492D", "#000000", "#F6AB2F", "#03CA98"];
  const letter = UpperCaseFirst(name?.split("")[0]);

  switch (letter) {
    case "S":
      return colors[0];
    case "R":
      return colors[1];
    case "P":
      return colors[2];
    case "K":
      return colors[3];
    case "N":
      return colors[4];
    default:
      return colors[0];
  }
};

export const isSquareNumber = (len) => {
  const nonSqures = [1, 2, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19, 20, 22];
  if (len) {
    for (let n of nonSqures) {
      if (n === len) return true;
    }
    return false;
  }
};
export const isFourSquare = (len = 20) => {
  // start from 4
  const fours = [4];

  // loop the length
  for (let i = 1; i <= len; i++) {
    // add 4 to last index
    const n = fours[fours.length - 1] + 4;
    fours.push(n);
  }

  if (len) {
    for (let n of fours) {
      if (n === len) return false;
    }
    return true;
  }
};

export const UpperCaseFirst = (str) => {
  return str?.substring(0, 1).toUpperCase() + str?.substring(1);
};

export const textCut = (str, n = 20) => {
  if (str?.length > n) {
    let newStr = str?.substr(0, n - 1).trim();
    if (newStr.endsWith(".") || newStr.endsWith(",")) {
      newStr = newStr.slice(0, -1).trim();
      if (newStr.endsWith(".") || newStr.endsWith(",")) {
        newStr = newStr.slice(0, -1).trim();
      }
    }
    newStr = newStr + "...";
    return newStr;
  }
  return str;
};

export const makeDate = (str) => {
  return moment(parseInt(str)).fromNow();
};

// calender logic

export const isTheSameOrBefore = (sd, ed) => {
  console.log(sd, ed);
  // let sdn = sd?.split(",");
  // let edn = ed?.split(",");

  // const sm = sdn[0];
  // const sy = sdn[1];

  // const em = edn[0];
  // const ey = edn[1];

  // if (sy < ey && sm <= em) {
  //   return true;
  // } else if (sy === ey && sm < em) {
  //   return true;
  // } else if (sy < ey && sm > em) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const isValidImage = (file) => {
  const acceptedFormats = ["image/jpeg", "image/png", "image/jpg"];
  if (acceptedFormats.find((format) => format === file[0]?.type)) {
    return { temp: window.URL.createObjectURL(file[0]), blob: file[0] };
  } else {
    return false;
  }
};

export const impression = (data) => {
  let impression = 20;
  let tip = "";

  if (data?.experience?.length >= 1) {
    impression = impression + 20;
  } else {
    tip =
      "Add a Work Experience to your profile and increase your chance of getting hired";
  }
  if (data?.education?.length >= 1) {
    impression = impression + 20;
  } else {
    tip =
      "Add Education to your profile and increase your chance of getting hired";
  }
  if (data?.skills?.length >= 1) {
    impression = impression + 20;
  } else {
    tip =
      "Add skills to your profile and increase your chance of getting hired";
  }
  if (data?.videoUrl || data?.embedCode) {
    impression = impression + 20;
  } else {
    tip =
      "Add a Video to your profile and increase your chance of getting hired.";
  }
  if (data) {
    return { strength: impression, tip };
  }
};

export const isEnoughSummary = (s) => {
  if (s?.length >= 100) {
    return true;
  } else {
    return false;
  }
};

export const getMinAndMax = async (jobs) => {
  if (jobs === null || jobs === undefined) {
    return [0, 0];
  }
  let min = 0;
  let max = 0;
  for (let i in jobs) {
    if (i === "0") {
      max = jobs[i].salaryRange.end;
      min = jobs[i].salaryRange.end;
    }
    if (parseInt(jobs[i].salaryRange.end) > parseInt(max)) {
      max = jobs[i].salaryRange.end;
    }
    if (parseInt(jobs[i].salaryRange.end) < parseInt(min)) {
      min = jobs[i].salaryRange.end;
    }
  }
  return [min, max];
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const initials = (string) => {
  const strArr = string.trim().split(" ");
  if (strArr.length <= 1) {
    return string;
  }
  return strArr[0]?.charAt(0) + strArr[1]?.charAt(0);
};

export const processHeadings = (str) => {
  return str.toLowerCase();
};

export const getTime = (time) => {
  const dateTime = moment(parseInt(time)).format("YYYY-MM-DD");
  if (!moment().subtract(1, "days").isAfter(dateTime)) {
    return moment(parseInt(time)).format("LT");
  } else if (
    moment(dateTime).isBetween(
      moment().subtract(2, "days"),
      moment().subtract(1, "days")
    )
  ) {
    return "Yesterday " + moment(parseInt(time)).format("LT");
  } else if (
    moment(dateTime).isBetween(
      moment().subtract(7, "days"),
      moment().subtract(2, "days")
    )
  ) {
    return moment(parseInt(time)).format("ddd LT");
  } else {
    return moment(parseInt(time)).format("MMM DD/YYYY LT");
  }
};

export const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const sortChats = (chats, id) => {
  if (id) {
    const newOrder = [...chats].sort((a, b) => {
      return a.id === id.conversationId
        ? -1
        : b.id === id.conversationId
        ? 1
        : 0;
    });
    return newOrder;
  } else {
    const newOrder = [...chats].sort((a, b) => {
      return parseFloat(b.lastUpdate) - parseFloat(a.lastUpdate);
    });
    return newOrder;
  }
};

export const closeDate = (time) => {
  if (
    moment(parseInt(time)).subtract(1, "day").isBefore(moment().add(7, "days"))
  ) {
    if (moment(parseInt(time)).subtract(1, "day").isSame(moment(), "day")) {
      return `Closes in less than a day`;
    } else {
      return `Closes ${moment(parseInt(time))
        .subtract(1, "day")
        .endOf("days")
        .fromNow()}`;
    }
  } else {
    return false;
  }
};

export const validatePostalCode = (postalCode) => {
  if (postalCode[4] === " " && postalCode[7] === " ") {
    return postalCode.substring(8);
  } else {
    return postalCode;
  }
};

export const validateEmbedUrl = (url) => {
  if (url.embedCode.startsWith("https://www.youtube")) {
    if (url.embedCode.startsWith("https://www.youtube.com/channel/")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const logout = (fire) => {
  fire
    .auth()
    .signOut()
    .then(() => {
      window.location.replace("/login");
    });
};

export const calculateTime = (startTime) => {
  const endTime = new Date();
  let timeDiff = endTime - startTime;
  timeDiff /= 1000;
  return Math.round(timeDiff);
}

export const getPagination = (value) => {
  if (value <= 1) {
    return 0
  } else {
    return ((value - 1) * 12);
  }
}
