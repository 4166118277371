import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import AppText from "../../../components/atoms/AppText";
import JobCard from "../../../components/molecules/JobCard";
import Jumbotoron from "../../../components/orgnisms/Jumbotoron";
import CardSkeleton from "../../../components/atoms/CardSkeleton";
import JobCardSm from "../../../components/molecules/JobCardSm";
import { useHistory } from "react-router-dom";

const JOBS_FOR_ME = gql`
  query ($input: ID!) {
    jobsForMe(userId: $input) {
      type
      jobs {
        id
        jobTitle
        company {
          id
          name
          logo
          header
          location
          services
          websiteLink
          instagramLink
          linkedinLink
          twitterLink
          facebookLink
          founded
          culture
          bio
          overview
          size
        }
        mapCordinates {
          lat
          lng
        }
        location
        description
        experienceLevel
        employmentType
        remotePossible
        applicationsCount
        industry
        createdAt
        skills
        assignedTo {
          id
        }
        salaryRange {
          defaultType
          start
          end
        }
      }
    }
  }
`;

export default function FindJob() {
  const { user } = useSelector((state) => state.User);
  const history = useHistory();

  const { loading, data, refetch } = useQuery(
    JOBS_FOR_ME,
    { variables: { input: user.id } },
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });

  const jid = window.localStorage.getItem("job");

  if (jid) {
    history.replace(`/job/${jid}`);
  }

  return (
    <div className="_bg_color">
      <div className="d-flex flex-column align-items-center _jumbo_cover">
        <Jumbotoron />
      </div>
      <div className="d-flex justify-content-center">
        <div className={isDesktop || isTablet ? "w-75" : "w-100 px-4"}>
          <div className="py-3">
            {!loading && data && (
              <AppText size="_18">{data?.jobsForMe?.type} for you</AppText>
            )}
            {/* merge must work */}
          </div>

          {loading && (
            <div className="d-flex flex-wrap justify-content-between">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </div>
          )}
          <div
            className={`grid_gap mt-2 mb-5 ${
              isDesktop ? "c4" : isTablet ? "c2" : isIpadPro ? "c3" : ""
            }`}
          >
            {!loading && data
              ? data.jobsForMe.jobs.map((job, i) =>
                  isDesktop || isTablet || isIpadPro ? (
                    <span key={i} style={{ minWidth: "270px" }}>
                      <JobCard job={job} onSearch={true} showsStatus={false} />
                    </span>
                  ) : (
                    <JobCardSm key={i} job={job} showsStatus={false} />
                  )
                )
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}
