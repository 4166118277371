import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client";
import MapReact from "google-map-react";
import AppText from "../atoms/AppText";
import { processHeadings, textCut } from "../../HelperFunctions";
import OutsideClickHandler from "react-outside-click-handler";
import Moment from "moment"
import AppIconButton from "../atoms/AppIconButton";
import { Check, Star, StarBorderOutlined } from "@material-ui/icons";
import AppButton from "../molecules/AppButton";
import { addFav } from "../../pages/homeStack/HomeDuck";
import { STAR_JOB } from "../../apolloUtils";
import { mapStyles } from "./Helpers/mapStyles";
import numbro from "numbro";

export default function Map({ data, loading, filter }) {

  const { jobs } = useSelector((state) => state.JobSearch);
  // const { sortBy } = useSelector((state) => state.Sort);
  const history = useHistory()

  const { user } = useSelector((state) => state.User);
  const { favourites } = useSelector((state) => state.Home);
  const status = useSelector((state) => state.Status);


  const Marker = ({ lat, lng, job }) => {

    const didApply = status.find((j) => j.job?.id === job?.id);
    const [showTip, setShowTip] = useState(false)
    const dispatch = useDispatch();

    const [addToFavourites] = useMutation(STAR_JOB);
    // const [sendApplicationNotification] = useMutation(SEND_APPLICATION_NOTIFICATION);
    // const [appTojob] = useMutation(APPLYTOJOB);

    const addTofavs = (e) => {
      e.stopPropagation();
      const { id } = job;

      addToFavourites({ variables: { input: { job: id, candidate: user.id } } });
      dispatch(addFav({ job }));
    };

    const handleShow = (e) => {
      e.stopPropagation()
      setShowTip(true)
    }

    // const applyJob = (e) => {
    //   e.stopPropagation();
    //   appTojob({ variables: { input: { jobId: job.id, candidate: user.id } } }).then(() => {
    //     sendApplicationNotification({
    //       variables: {
    //         input: {
    //           string: `${user.firstName} ${user.lastName} Applied to ${job.jobTitle}`,
    //           action: 5,
    //           toUser: job.assignedTo.id,
    //           senderFullName: `${user.firstName} ${user.lastName}` || "",
    //           senderPhotoUrl: user.photoUrl || "",
    //         },
    //       },
    //     });
    //     dispatch(setAppied({ job, status: 1 }));
    //   });

    // };

    const handleClick = () => {
      history.push(`/job/${job.id}`, job);
    };

    return (<div lat={lat} lng={lng}>
      <OutsideClickHandler onOutsideClick={() => setShowTip(false)}>
        <div className="position-relative" onClick={handleClick}>
          <img className='rounded _dow' src={job.company.logo} alt='nla' height='50' onClick={handleShow} />
          {showTip && <div className='m334_card p-4'>
            <div className="d-flex justify-content-between">
              <img src={job.company.logo} alt='nla' height='50' />
              <AppText size='_14' color='_c90'>
                {textCut(Moment(parseInt(job?.createdAt)).fromNow(), 14)}
              </AppText>
            </div>
            <div className="mt-2">
              <AppText size='_16' weight='_medium'>
                <span style={{ textTransform: "capitalize" }}>
                  {processHeadings(textCut(job?.jobTitle, 23))}
                </span>

              </AppText>
            </div>
            <div className="mt-2">
              <AppText size='_14'>

                <span className="_medium">{textCut(job?.company.name, 11)}</span> • <span className="_c90">{job?.location.split(",")[0]}</span>
              </AppText>
            </div>
            <div className="mt-2">
              {job?.salaryRange?.defaultType !== 'hourly' ? (
                <AppText size='_14' weight='_medium' color='_b100'>
                  £
                  {job?.salaryRange?.start !== 0 ?
                    (job?.salaryRange?.start < 1000 ?
                      numbro(job?.salaryRange?.start).format({mantissa: 2}) :
                      numbro(job?.salaryRange?.start).format({ thousandSeparated: true, average: true, })
                    ) : null
                  }
                  {job?.salaryRange?.start !== 0 ? " - £" : ""}
                  {job?.salaryRange?.end < 1000 ?
                    numbro(job?.salaryRange?.end).format({mantissa: 2}) :
                    numbro(job?.salaryRange?.end).format({
                      thousandSeparated: true,
                      average: true,
                    })}
                </AppText>
              ) : (
                  <AppText size='_14' weight='_medium' color='_b100'>
                    £
                    {job?.salaryRange?.end < 1000 ?
                      numbro(job?.salaryRange?.end).format({mantissa: 2}) :
                      numbro(job?.salaryRange?.end).format({
                        thousandSeparated: true,
                        average: true,
                      })}/hr
                  </AppText>
                )}
            </div>

            <div className="mt-4 d-flex justify-content-between">
              <div className="w-75">
                {typeof didApply === "undefined" ? (
                  <AppButton width='_full' color='_white'>
                    <AppText size='_14'>Apply Now</AppText>
                  </AppButton>
                ) : didApply?.status === 4 ? (
                  <AppButton width='_full' color='_green' state={true}>
                    <AppText size='_14'>Applied</AppText>
                  </AppButton>
                ) : (
                      <AppButton width='_full' color='_green'>
                        <div>
                          <Check style={{ color: "#fff" }} />{" "}

                          <AppText size='_14' color='_c0'>
                            Applied
                      </AppText>
                        </div>
                      </AppButton>
                    )}

              </div>
              <div className="w-25  d-flex justify-content-center">

                {favourites.find((el) => el.job.id === job?.id) ? (
                  <span className='px-2 d-flex justify-content-center'>
                    <AppIconButton onClick={addTofavs}>
                      <Star style={{ fontSize: "17px", color: "#03CA98" }} />
                    </AppIconButton>
                  </span>
                ) : (
                    <span className='px-2 d-flex justify-content-center'>
                      <AppIconButton onClick={addTofavs}>
                        <StarBorderOutlined style={{ fontSize: "17px", color: "#BCBCBC" }} />

                      </AppIconButton>
                    </span>
                  )}
              </div>
            </div>
            <div>

            </div>
          </div>}
        </div>

      </OutsideClickHandler>

    </div>)
  }

  const [zoom] = useState(8);

  const handleApiLoaded = (map, maps) => { };

  return (
    <div>
      <div style={{ height: "84vh" }}>
        <MapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          center={{ lat: 51.509865, lng: -0.118092 }}
          zoom={zoom}
          defaultOptions={{
            styles: mapStyles
          }}
        >
          {jobs.map((job, i) => (
            <Marker job={job} key={i} lat={job.mapCordinates.coordinates[1]} lng={job.mapCordinates.coordinates[0]} />
          ))}
        </MapReact>
      </div>
    </div>
  );
}
