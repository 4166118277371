import uniqBy from "lodash.uniqby";

const SET_OLD_CHATS = "SET_OLD_CHATS";
const SET_UNREAD = "SET_UNREAD";

const initialState = {
  inChat: [],
  unread: [],
};

const ChatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OLD_CHATS:
      let newChats = [...state.inChat, ...action.payload];
      newChats = uniqBy(newChats, (e) => e.id);
      return { ...state, inChat: newChats };
    case SET_UNREAD:
      return { ...state, unread: action.payload };
    default:
      return state;
  }
};

export const setChats = (chats) => {
  return {
    type: SET_OLD_CHATS,
    payload: chats,
  };
};
export const setUnread = (ids) => {
  return {
    type: SET_UNREAD,
    payload: ids,
  };
};

export default ChatsReducer;
