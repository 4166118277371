const SET_USER = "SET_USER";

const initialState = {};

const AppAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const setUserState = (st) => {
  return {
    type: SET_USER,
    payload: st,
  };
};
export default AppAuthReducer;
