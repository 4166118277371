import React, { useState, useEffect, useRef } from "react";
import AppText from "../atoms/AppText";
import AppInput from "./AppInput";

export default function ApiSearchInput({
  onChange,
  formik,
  defaultValue = "",
  hasError,
}) {
  const [value, setValue] = useState(defaultValue);
  const [results, setResults] = useState(null);
  const [output, setOutput] = useState(null);

  const dropDownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setResults(null);
      }
    });
  }, []);

  const handleKeyup = async () => {
    if (value.trim() !== "") {
      try {
        const req = await fetch(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`
        );
        const res = await req.json();
        setResults(res);
      } catch (err) {
        console.log(err);
      }
    } else {
      setResults(null);
    }
  };

  const handleClick = (data) => {
    setValue(data.name);
    onChange(data);
    setResults(null);
  };

  const handleBlur = () => {
    if (value.trim() !== "") {
      if (output) {
        onChange(output);
        setOutput(null);
      } else {
        onChange({
          name: value,
          logo: "0",
        });
      }
    } else {
      onChange("");
    }
  };

  return (
    <div className="position-relative">
      <AppInput
        hasError={hasError}
        label="COMPANY"
        name="company"
        placeholder="Company Name"
        value={value}
        onChange={(txt) => setValue(txt.target.value)}
        onKeyUp={handleKeyup}
        onBlur={handleBlur}
      />

      {results && (
        <div
          ref={dropDownRef}
          className="position-absolute bg-white drop-search w-100"
        >
          {results.map((r, i) => (
            <div
              key={i}
              className="ps-3 py-1 pointer"
              onClick={() => handleClick(r)}
            >
              <AppText size="_14">{r.name}</AppText>
            </div>
          ))}
        </div>
      )}
      {formik.touched.company && formik.errors.company && (
        <AppText size="_12" color="_r100">
          {formik.errors.company}
        </AppText>
      )}
    </div>
  );
}
