import React, { useState } from "react";
import AppText from "../atoms/AppText";
import AWS from "aws-sdk";
import { uid } from "uid";
import { useMutation, gql } from "@apollo/client";
import ModalFooter from "../molecules/ModalFooter";
import InputFiles from "react-input-files";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import LinearProgress from "@material-ui/core/LinearProgress";
import { useSelector } from "react-redux";


const SAVE_VIDEO_CV = gql`
  mutation saveVideoCv($input: editUserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;

export default function UploadVideoModal({ refetch, onClose }) {
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [saveVideoCv] = useMutation(SAVE_VIDEO_CV);
  const { user } = useSelector((state) => state.User);

  const handleFile = (file) => {
    if (
      (file[0].type === "video/mp4" || file[0].type === "video/quicktime") &&
      file[0].size <= 200000000
    ) {
      setFileName(file[0].name);
      const s3Bucket = new AWS.S3({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRETE_ACCESS_KEY,
        region: process.env.REACT_APP_S3_REGION,
      });
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `${uid(10)}-${file[0].name}`,
        Body: file[0],
        ContentDisposition: "attachment",
      };
      const upload = s3Bucket.upload(params);

      upload.send((err, data) => {
        if (err) console.log(err);
        saveVideoCv({ variables: { input: { videoUrl: data.Location, userId: user.id } } }).then(() => {
          refetch();
          onClose();
        });
      });
      upload.on("httpUploadProgress", (event) => {
        const uploaded = Math.round((event.loaded / event.total) * 100);
        setProgress(uploaded);
      });
    } else {
      alert("file not supported or too large");
    }
  };
  return (
    <div>
      <div className="px-4 py-3">
        {progress > 0 ? (
          <div>
            <div className="px-4 py-4 progress-container mb-1">
              <div className="d-flex justify-content-between">
                <AppText size="_12">{fileName}</AppText>
                <AppText size="_12" color="_c50">
                  {progress} of 100%
                </AppText>
              </div>
              <div className="mt-1">
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={{
                    borderRadius: "2px",
                    background: "#EAECEE",
                  }}
                />
              </div>
            </div>
            <AppText size="_12" color="_c50">
              Uploading...
            </AppText>
          </div>
        ) : (
          <div>
            <div className='px-4 py-4 drop-area'>
              <AppText size='_14' color='_c50'>
                <div className='d-flex align-items-center'>
                  <SaveAltIcon style={{ marginRight: "10px", fontSize: "18px" }} />
                  Drag files here to upload or
                  <span className='_g90 ml-1'>
                    <InputFiles accept='*' onChange={(file) => handleFile(file)}>
                      <AppText size='_14' color='_g90'>
                        Select from device
                      </AppText>
                    </InputFiles>
                  </span>
                </div>
              </AppText>
            </div>
            <AppText size='_14' color='_c50'>
              MP4 and MOV format supported. Max. size 200MB
            </AppText>
          </div>
        )}
      </div>
      <ModalFooter onClose={onClose} canSave={false} />
    </div>
  );
}
