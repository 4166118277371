const SET_UID = "SET_UID";
const SET_APPIED = "SET_APPIED";

const initialState = [];

const StatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UID:
      return [...action.payload];
    case SET_APPIED:
      return [...state, action.payload];
    default:
      return state;
  }
};

export const setJobs = (st) => {
  return {
    type: SET_UID,
    payload: st,
  };
};
export const setAppied = (st) => {
  return {
    type: SET_APPIED,
    payload: st,
  };
};

export default StatusReducer;
