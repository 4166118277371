import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useMutation, gql } from "@apollo/client";
import All from "./templates/All";
import { clearAlert } from "../../../redux/userDucks";
import Processing from "./templates/Processing";
import Interview from "./templates/Interview";
import Offer from "./templates/Offer";
import Unsuccessful from "./templates/Unsuccessful";

const UPDATE_STATUS_LAST = gql`
  mutation ($input: LastSeenInput) {
    seekerUpdateLastSeen(input: $input)
  }
`;

const useStyles = makeStyles({
  root: {
    height: "50px",
    fontWeight: "normal",
    justifyContent: "start",
  },
  tab: {
    height: "50px",
    textTransform: "none",
    fontFamily: "roboto",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#03CA98",
  },
});

export default function Status() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const [updateStateLastSeen] = useMutation(UPDATE_STATUS_LAST);

  useEffect(() => {
    updateStateLastSeen({
      variables: { input: { userId: user.id, statusLastSeen: true } },
    });
    dispatch(clearAlert({ unseenStatusNofications: [] }));
    return () => {
      updateStateLastSeen({
        variables: { input: { userId: user.id, statusLastSeen: true } },
      });
      dispatch(clearAlert({ unseenStatusNofications: [] }));
    };
  }, [updateStateLastSeen, user.id, dispatch]);

  function statuses() {
    switch (value) {
      case 1:
        return <Processing />;
      case 2:
        return <Interview />;
      case 3:
        return <Offer />;
      case 4:
        return <Unsuccessful />;
      default:
        return <All />;
    }
  }

  return (
    <div>
      <div className="_status_header d-flex justify-content-center">
        <div className={isDesktop ? "w-75" : "w-100"}>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={(w, v) => setValue(v)}
            className={classes.root}
            classes={{ indicator: classes.indicator }}
          >
            <Tab disableRipple label="All" className={classes.tab} />
            <Tab disableRipple label="Processing" className={classes.tab} />
            <Tab disableRipple label="Interview" className={classes.tab} />
            <Tab disableRipple label="Offer" className={classes.tab} />
            <Tab disableRipple label="Unsuccessful" className={classes.tab} />
          </Tabs>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div className={isDesktop ? "w-75" : isTablet ? "w-82" : "w-95"}>
          {statuses()}
        </div>
      </div>
    </div>
  );
}
