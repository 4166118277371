import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import AppText from "../atoms/AppText";

const useStylesLoading = makeStyles((theme) => ({
  root: {
    position: "absolute",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#03E0A9",
    animationDuration: "650ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function FullLoader(props) {
  const classes = useStylesLoading();
  return (
    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={80}
          thickness={2}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={80}
          thickness={2}
          {...props}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            className="loading-img"
            alt="loading"
            src="/loading.png"
            height="60"
            width="60"
          />
        </Box>
      </Box>
      <div className="mt-2" />
      <AppText
        size="_16"
        color={"_c90"}
        style={{ "font-family": "Roboto-Regular" }}
      >
        Loading...
      </AppText>
    </div>
  );
}

export default FullLoader;
