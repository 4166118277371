const CHANGE_FILTERS = "CHANGE_FILTERS";
const CLEAR_FILTERS = "CLEAR_FILTERS";
const CLEAR_JOBTYPE = "CLEAR_JOBTYPE"
const TOGGLE_JOBTYPES = "TOGGLE_JOBTYPES"
const RESET_FILTER = "RESET_FILTER"

const initialState = {
    filtersShow: false,
    updated: false,
    datePost: "Anytime",
    experience: "Any",
    location: "",
    jobTypesArray: [],
    salary: {
        defaultType: "annually",
        salary: { start: "", end: "" }
    },
    remotePossible: false,
    fullTime: false,
    partTime: false,
    contract: false,
    internship: false,
};

const FilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_FILTERS:
            return { ...state, ...action.payload };
        case CLEAR_FILTERS:
            return initialState;
        case CLEAR_JOBTYPE:
            const cleanTypes = {
                "Full-Time": "fullTime",
                "Part-Time": "partTime",
                "Contract": "contract",
                "Internship": "internship",
                "Remote": "remotePossible"
            }

            const newTypes = [...state.jobTypesArray]
            const id = newTypes.findIndex(type => type === action.payload)

            if (id !== -1) {
                newTypes.splice(id, 1)
            }

            return { ...state, jobTypesArray: newTypes, [cleanTypes[action.payload]]: false };
        case TOGGLE_JOBTYPES:
            const newJobTypesArray = [...state.jobTypesArray]
            const cleanTypesReverse = {
                fullTime: "Full-Time",
                partTime: "Part-Time",
                contract: "Contract",
                internship: "Internship",
                remotePossible: "Remote"
            }

            const index = newJobTypesArray.findIndex(i => i === cleanTypesReverse[action.payload])
            if (index === -1) {
                newJobTypesArray.push(cleanTypesReverse[action.payload])

            } else {
                newJobTypesArray.splice(index, 1)
            }
            return {
                ...state,
                [action.payload]: !state[action.payload],
                jobTypesArray: newJobTypesArray,
                updated: false
            }
        case RESET_FILTER:
            return { ...state, [action.payload.key]: action.payload.value }
        default:
            return state;
    }
};

export const changeFilters = (filter) => {
    return {
        type: CHANGE_FILTERS,
        payload: filter,
    };
};
export const clearFilters = () => {
    return {
        type: CLEAR_FILTERS,
    };
};
export const clearJobType = (type) => {
    return {
        type: CLEAR_JOBTYPE,
        payload: type
    };
};
export const toggleCheck = (check) => {
    return {
        type: TOGGLE_JOBTYPES,
        payload: check
    };
};

export const resetFilter = (key, value) => {
    return {
        type: RESET_FILTER,
        payload: { key, value }
    };
};
export default FilterReducer;
