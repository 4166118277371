import React, { useState, useEffect } from "react";
import AppText from "../atoms/AppText";
import DropDownArrow from "../atoms/DropDownArrow";
import OutSide from "react-outside-click-handler";

export default function AppDropDownFilter({
  onChange,
  sortOption,
  sortOptions,
}) {
  const [showDrop, setShowDrop] = useState(false);
  const [value, setValue] = useState(sortOption);

  const handleClick = (value) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(sortOption);
  }, [sortOption]);

  return (
    <div className="position-relative">
      <OutSide onOutsideClick={() => setShowDrop(false)}>
        <div
          onClick={() => setShowDrop(true)}
          className="_gbtn_container px-3 d-flex justify-content-between align-items-center pointer"
        >
          <AppText size="_14" color="_c90">
            <span className="d-flex align-items-center">
              <span className="ms-1 me-2" style={{ color: "#3E3F42" }}>
                {value}
              </span>
            </span>
          </AppText>
          <DropDownArrow />
        </div>

        {showDrop && (
          <div className="position-absolute _droper">
            {sortOptions.map((option, index) => (
              <div
                className="_drop_item"
                key={index}
                onClick={() => {
                  handleClick(option);
                  setShowDrop(false);
                }}
              >
                <AppText size="_14">{option}</AppText>
              </div>
            ))}
          </div>
        )}
      </OutSide>
    </div>
  );
}
