import React from "react";
import { ArrowBack } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import AppIconButton from "../../../components/atoms/AppIconButton";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";

export default function NotFound() {
  const history = useHistory();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const goHome = () => {
    history.replace("/");
  };

  if (window.location.pathname === '/login'){
    history.push('/')
  }

  return (
    <div className='d-flex justify-content-center'>
      <div className={isDesktop || isTablet ? "w-75" : "w-95"}>
        <div className='my-2'>
          <AppIconButton onClick={goHome}>
            <ArrowBack />
          </AppIconButton>
        </div>
        <div className='d-flex flex-column  align-items-center'>
          <img src='/404_Illustration.svg' alt='404' height='370' />

          <div className='mt-3 mb-2'>
            <AppText size='_26' weight='_medium'>
              Oops! We are terribly sorry.
            </AppText>
          </div>
          <div className={isDesktop || isTablet ? "w-40 mb-4" : "w-82 mb-4"}>
            <AppText align='_center' size='_14' color='_c90'>
              It seems the page you're looking for does not exist or has been removed. Anyways let's
              get you back on track
            </AppText>
          </div>

          <AppButton onClick={goHome}>
            <AppText size='_14' color='_c0'>
              Go Home
            </AppText>
          </AppButton>
        </div>
      </div>
    </div>
  );
}
