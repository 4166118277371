import React, { useEffect, useState } from "react";
import AppText from "../atoms/AppText";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import AppButton from "./AppButton";
// import AppInputIcon from "../atoms/AppInputIcon";
import AppInput from "../molecules/AppInput";
import AppDropDownFilter from "./AppDropDownFilter";
import {
  changeFilters,
  clearFilters,
  toggleCheck,
} from "../../redux/filterDucks";
import SalaryRange from "../orgnisms/SalaryRange";

export default function Filters({
  mobile = false,
  onFilter,
  filters,
  onClearFilters,
  minAndMax = ["0", "0"],
}) {
  const dispatch = useDispatch();
  const sortByDateOptions = [
    "Last 3 Days",
    "Last 7 Days",
    "Last 14 Days",
    "Anytime",
  ];
  const sortByExperienceOptions = [
    "Entry Level",
    "Intermediate",
    "Mid Level",
    "Senior",
  ];
  const filters2 = useSelector((state) => state.Filter);
  const [isFixed, setIsFixed] = useState(false);
  const [isHourly, setIsHourly] = useState(false);

  const formik = useFormik({
    initialValues: {
      datePosted: filters2?.datePost ? filters2?.datePost : "Anytime",
      location: "",
      experience: "Any" || "",
      salary: {
        salary: {
          start: "",
          end: "",
        },
      },
    },
    onSubmit: (values) => {
      let salaryRange = values.salary.salary;
      if (
        values.salary.salary.start === "" &&
        values.salary.salary.end !== ""
      ) {
        salaryRange.start = "0";
      }
      if (
        values.salary.salary.end === "" &&
        values.salary.salary.start !== ""
      ) {
        salaryRange.end = minAndMax[1];
      }

      dispatch(
        changeFilters({
          datePost: values.datePosted,
          filtersShow: true,
          updated: true,
          location: values.location,
          experience: values.experience,
          salary: {
            defaultType:
              !isFixed && !isHourly ? "annually" : isFixed ? "fixed" : "hourly",
            salary: {
              start: salaryRange.start,
              end: salaryRange.end,
            },
          },
        })
      );

      onFilter();
    },
  });

  useEffect(() => {
    formik.setFieldValue("datePosted", filters2.datePost);
    formik.setFieldValue("experience", filters2.experience);
    formik.setFieldValue("location", filters2.location);
    formik.setFieldValue("salary", filters2.salary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters2.datePost,
    filters2.experience,
    filters2.location,
    filters2.salary,
  ]);

  const handleChangeFormik = (value) => {
    dispatch(toggleCheck(value));
  };

  const handleReset = (e) => {
    dispatch(clearFilters());
    onFilter();
  };

  const handleChangeSlaray = (e, key, subKey) => {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      const values = e.target.value;
      formik.setFieldValue(key, values);
      const newSlary = {
        ...formik.values[key],
        [subKey]: values,
      };
      formik.setFieldValue(key, newSlary);
      formik.handleChange(e);
    }
  };

  return (
    <div className={`px-4 ${!mobile ? "_fiter_card" : "_fiter_card_sm pe-5"}`}>
      <AppText size="_12" weight="_medium">
        FILTERS
      </AppText>
      <div className="mt-3">
        <AppText size="_16" weight="_medium">
          Date Posted
        </AppText>
        <div className="mt-2">
          <AppDropDownFilter
            sortOption={filters2?.datePost || formik.values.datePosted}
            sortOptions={sortByDateOptions}
            onChange={(value) => formik.setFieldValue("datePosted", value)}
          />
        </div>
      </div>
      <div className="_sep_ my-3" />
      <div className="mt-3">
        <AppText size="_16" weight="_medium">
          Job Type
        </AppText>
        <div className="mt-2">
          <Checkbox
            name="fullTime"
            value="fullTime"
            checked={filters2.fullTime}
            onChange={(e) => {
              handleChangeFormik("fullTime");
            }}
            style={{
              color: "#03CA98",
              padding: 0,
            }}
          />{" "}
          <AppText size="_14" color="_c90">
            Permanent Full - Time
          </AppText>
        </div>
        <div className="mt-2">
          <Checkbox
            name=".partTime"
            value="partTime"
            checked={filters2.partTime}
            onChange={(e) => {
              handleChangeFormik("partTime");
            }}
            style={{
              color: "#03CA98",
              padding: 0,
            }}
          />{" "}
          <AppText size="_14" color="_c90">
            Part - Time
          </AppText>
        </div>
        <div className="mt-2">
          <Checkbox
            name="contract"
            value="contract"
            checked={filters2.contract}
            onChange={(e) => {
              handleChangeFormik("contract");
            }}
            style={{
              color: "#03CA98",
              padding: 0,
            }}
          />{" "}
          <AppText size="_14" color="_c90">
            Contract
          </AppText>
        </div>
        <div className="mt-2">
          <Checkbox
            name="internship"
            value="internship"
            checked={filters2.internship}
            onChange={(e) => {
              handleChangeFormik("internship");
            }}
            style={{
              color: "#03CA98",
              padding: 0,
            }}
          />{" "}
          <AppText size="_14" color="_c90">
            Internship/Trainee
          </AppText>
        </div>
      </div>
      <div className="_sep_ my-3" />
      <div className="mt-3">
        <AppText size="_16" weight="_medium">
          Experience Level
        </AppText>
        <div className="mt-2">
          <AppDropDownFilter
            sortOption={filters2?.experience || formik.values.experience}
            sortOptions={sortByExperienceOptions}
            onChange={(value) => formik.setFieldValue("experience", value)}
          />
        </div>
      </div>
      <div className="_sep_ my-3" />
      <div className="mt-3">
        <AppText size="_16" weight="_medium">
          Location
        </AppText>
        <div className="mt-2">
          <AppInput
            placeholder="Enter Location"
            name="location"
            value={formik.values.location}
            onChange={formik.handleChange}
          />
        </div>
        <div className="mt-2">
          <Checkbox
            name="remotePossile"
            value="remotePossible"
            checked={filters2.remotePossible}
            onChange={() => handleChangeFormik("remotePossible")}
            style={{
              color: "#03CA98",
              padding: 0,
            }}
          />{" "}
          <AppText size="_14" color="_c90">
            Open to remote
          </AppText>
        </div>
      </div>
      <div className="_sep_ my-3" />
      <div className="mt-3">
        <SalaryRange
          onChange={handleChangeSlaray}
          formik={formik}
          isFixed={isFixed}
          isHourly={isHourly}
          setIsFixed={setIsFixed}
          setIsHourly={setIsHourly}
        />
        <div>
          <div className="_sep_ my-3" />
          <div className="d-flex justify-content-between">
            <div>
              <AppButton
                color="_white"
                width="_full"
                onClick={(e) => {
                  handleReset(e);
                }}
                type={"button"}
              >
                <AppText size="_14">Clear</AppText>
              </AppButton>
            </div>
            <div>
              <AppButton width="_full" onClick={formik.handleSubmit}>
                <AppText size="_14" color="_c0">
                  Apply Filters
                </AppText>
              </AppButton>
            </div>
          </div>
          <div className="py-4" />
        </div>
      </div>
    </div>
  );
}
