import React, { useState } from "react";
import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ErrorOutline } from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";

export default function BoardingPanelSkills({
  heading,
  subHeading,
  children,
  onBack,
  state,
  onSubmit,
  onSubmitText = "Next",
  onSkip,
  isLoading = false,
  help = false,
  helpText = false,
  herf = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="_panel">
      <div className="px-4 py-3 botm">
        <div>
          <AppText size="_18" weight="_medium">
            {heading}
          </AppText>
        </div>
        {subHeading !== undefined ? (
          <div className={"d-flex flex-row"}>
            <AppText size="_14" color="_c50">
              {subHeading}{" "}
              {help ? (
                <AppText size="_14" color="_c50">
                  {helpText[0]}{" "}
                  <a href={herf} target="_blank" rel="noopener noreferrer">
                    here
                  </a>{" "}
                  {helpText[1]}
                </AppText>
              ) : null}
            </AppText>
            <div
              className={'d-flex justify-content-center align-items-center'}
              onMouseEnter={handlePopoverOpen}
            >
              <ErrorOutline style={{ fontSize: "16px", color: "#9EA0A5", marginLeft: "3px", cursor: "pointer" }} />
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div 
                className={'p-3 d-flex flex-column align-items-center'} 
                style={{maxWidth: "400px"}}
                onMouseLeave={handlePopoverClose}
                >
                  <AppText size="_14"><AppText size="_14" weight={"_medium"}>Hard skills</AppText> {" "}- these are skills related to a particular field. Examples include coding ability, computer and language skills.</AppText>
                  <span className={'mt-2'}>
                    <AppText size="_14"><AppText size="_14" weight={"_medium"}>Soft skills</AppText> {" "}- these are skills that are self-developed through experience. Examples include problem solving, leadership, communication and team working.</AppText>
                  </span>
                </div>
              </Popover>
            </div>
          </div>
        ) : null}
      </div>
      <div className="px-4 py-3">{children}</div>
      {state === "preview" ? null : (
        <div className="px-4 py-3 d-flex justify-content-between topm _darc">
          <div>
            {onBack !== undefined ? (
              <AppButton color="_white" onClick={onBack}>
                <span className="mx-3">
                  <AppText size="_14">Back</AppText>
                </span>
              </AppButton>
            ) : null}
          </div>
          <div>
            {onSkip !== undefined && (
              <span onClick={onSkip} className="me-4 pointer">
                <AppText size="_14">Skip</AppText>
              </span>
            )}

            <AppButton onClick={onSubmit}>
              {isLoading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "30px", width: "30px" }}
                />
              ) : (
                <span className="mx-3">
                  <AppText color="_c0" size="_14">
                    {onSubmitText}
                  </AppText>
                </span>
              )}
            </AppButton>
          </div>
        </div>
      )}
    </div>
  );
}
