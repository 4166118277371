const CHANGE_TO_SORT = "CHANGE_TO_SORT";

const initialState = {
  sortBy: "Most recent",
};

const SortReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TO_SORT:
      return { ...state, sortBy: action.payload };
    default:
      return state;
  }
};

export const changeToSort = (st) => {
  return {
    type: CHANGE_TO_SORT,
    payload: st,
  };
};
export default SortReducer;
