import React from "react";
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "react-responsive";

export default function CardSkeleton() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return (
    <div className={`${isDesktop || isTablet ? "_cardskeleton" : "_cardskeleton_sm"} p-4`}>
      <Skeleton style={{ height: "70px", marginTop: "-12px", width: "40px" }} />
      <Skeleton style={{ height: "30px", marginTop: "-12px" }} />
      <Skeleton style={{ height: "30px", marginTop: "-4px" }} />
      <Skeleton style={{ height: "55px", marginTop: "10px", width: "120px" }} />
    </div>
  );
}
