import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { fetchSkills } from "../../pages/buildYourProfile/templates/Ducks/SkillDucks";
import { fire } from "../../firebase/index";

import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";
import Model from "../molecules/Model";
import SkillModal from "../orgnisms/SkillModal";
import { CircularProgress } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { ErrorOutline } from "@material-ui/icons";

const FETCH_SKILLS = gql`
  query ($id: String!) {
    getCurrentUser(uid: $id) {
      skills {
        value
        label
      }
    }
  }
`;

export default function SkillsTemplate({ immutable = true }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const skills = useSelector((state) => state.Skill);
  const [addSkill, setAddSkill] = useState(false);
  const open = Boolean(anchorEl);

  const { loading, data } = useQuery(FETCH_SKILLS, {
    fetchPolicy: "no-cache",
    variables: {
      id: fire.auth().currentUser.uid,
    },
  });

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!loading && data) {
      dispatch(fetchSkills(data.getCurrentUser.skills));
    }
  }, [data, loading, dispatch]);

  const general = skills.filter((el) => el.value === "general");
  const softSkills = skills.filter((el) => el.value === "softSkills");
  const toolsAndTechnologies = skills.filter(
    (el) => el.value === "toolsAndTechnologies"
  );
  const others = skills.filter((el) => el.value === "others");

  if (loading) return <CircularProgress style={{ color: "#9ea0a6" }} />;

  return (
    <div>
      <div className='d-flex flex-row mb-2'>
        <AppText size='_12' color='_c50'>
          SKILLS
        </AppText>
        <div
          className={'d-flex justify-content-center align-items-center'}
          onMouseEnter={handlePopoverOpen}
        >
          <ErrorOutline style={{ fontSize: "16px", color: "#9EA0A5", marginLeft: "3px", cursor: "pointer" }} />
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div
              className={'p-3 d-flex flex-column align-items-center'}
              style={{ maxWidth: "400px" }}
              onMouseLeave={handlePopoverClose}
            >
              <AppText size="_14"><AppText size="_14" weight={"_medium"}>Hard skills</AppText> {" "}- these are skills related to a particular field. Examples include coding ability, computer and language skills.</AppText>
              <span className={'mt-2'}>
                <AppText size="_14"><AppText size="_14" weight={"_medium"}>Soft skills</AppText> {" "}- these are skills that are self-developed through experience. Examples include problem solving, leadership, communication and team working.</AppText>
              </span>
            </div>
          </Popover>
        </div>
      </div>
      {general.length > 0 ? (
        <div className="mb-2 mt-1">
          <AppText size="_14" color="_c90">
            General
          </AppText>
        </div>
      ) : null}

      <div className="d-flex flex-wrap">
        {general.map((g, i) => (
          <div
            key={i}
            className={isDesktop || isTablet ? "w-45 mb-1" : "w-100 mb-1"}
          >
            <AppText size="_16">{g.label}</AppText>
          </div>
        ))}
      </div>

      {toolsAndTechnologies.length > 0 ? (
        <div>
          <div className="_separate my-2" />
          <AppText size="_14" color="_c90">
            Tools & Technologies
          </AppText>
        </div>
      ) : null}

      <div className="d-flex flex-wrap">
        {toolsAndTechnologies.map((t, i) => (
          <div
            key={i}
            className={isDesktop || isTablet ? "w-45 mb-1" : "w-100 mb-1"}
          >
            <AppText size="_16">{t.label}</AppText>
          </div>
        ))}
      </div>

      {softSkills.length > 0 ? (
        <div>
          <div className="_separate my-2" />
          <AppText size="_14" color="_c90">
            Soft Skills
          </AppText>
        </div>
      ) : null}

      <div className="d-flex flex-wrap">
        {softSkills.map((t, i) => (
          <div
            key={i}
            className={isDesktop || isTablet ? "w-45 mb-1" : "w-100 mb-1"}
          >
            <AppText size="_16">{t.label}</AppText>
          </div>
        ))}
      </div>
      {others.length > 0 ? (
        <div>
          <div className="_separate my-2" />
          <AppText size="_14" color="_c90">
            Others
          </AppText>
        </div>
      ) : null}

      <div className="d-flex flex-wrap">
        {others.map((t, i) => (
          <div
            key={i}
            className={isDesktop || isTablet ? "w-45 mb-1" : "w-100 mb-1"}
          >
            <AppText size="_16">{t.label}</AppText>
          </div>
        ))}
      </div>

      <div className="mt-2">
        {immutable && (
          <AppButton color="_white" onClick={() => setAddSkill(true)}>
            <AddIcon style={{ color: "#9EA0A5" }} />{" "}
            <AppText size="_14"></AppText>Add Skill
          </AppButton>
        )}
      </div>

      {addSkill && (
        <Model onClose={() => setAddSkill(false)} title="Add Skills">
          <SkillModal onClose={() => setAddSkill(false)} />
        </Model>
      )}
    </div>
  );
}
