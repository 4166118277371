import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import AppText from "../../../components/atoms/AppText";
import Basic from "./Basic";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
// import Summary from "../../../components/molecules/Summary";
import { useQuery, useMutation, gql } from "@apollo/client";
import { GET_USER } from "../../../apolloUtils";
// import SkillsTemplate from "../../../components/templates/SkillsTemplate";
// import VideoCvTemplate from "../../../components/templates/VideoCvTemplate";
// import ExperienceTemplate from "../../../components/templates/ExperienceTemplate";
// import EducationTemplate from "../../../components/templates/EducationTemplate";
import { setStep } from "../../../redux/appDucks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fire } from "../../../firebase/index";

const COMPLETE_ACCOUNT_SETUP = gql`
  mutation ($input: editUserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;

export default function Preview() {
  const history = useHistory();

  const { loading, data, error } = useQuery(
    GET_USER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: fire.auth().currentUser.uid
      }
    });

  const [isLoading, setIsLoading] = useState(false);
  const [completeAccountSetup] = useMutation(COMPLETE_ACCOUNT_SETUP);
  const { user } = useSelector((state) => state.User);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStep(6));
  }, [dispatch]);

  if (error) console.log(error);

  if (loading) return <CircularProgress style={{ color: "#9ea0a6" }} />;

  const moveBack = () => {
    // history.goBack();
    history.push("/build-your-profile/resume")
  };

  const finshAccountSetup = () => {
    setIsLoading(true);
    completeAccountSetup({
      variables: { input: { accountComplete: true, accountStage: 6, userId: user.id } },
    }).then(() => {
      window.location.replace("/");
    });
  };

  return (
    <div>
      {!loading && data.getCurrentUser && (
        <div>
          
          <div className="mt-3">
            <BoardingPanel
              heading="My Resume"
              onBack={moveBack}
              onSubmit={finshAccountSetup}
              onSubmitText="Create Profile"
              isLoading={isLoading}
            >
              <Basic state="preview" data={data.getCurrentUser} />
              {/* <Summary disabled={true} value={data.getCurrentUser.summary} />
              <div className="mt-3">
                <AppText size="_14" color="_c50">
                  VIDEO
                </AppText>
                <VideoCvTemplate immutable={false} preview = {true}/>
              </div>
              <div className="mt-3">
                <ExperienceTemplate immutable={false} />
              </div>
              <div className="mt-3">
                <EducationTemplate immutable={false} />
              </div>
              <div className="mt-3">
                <SkillsTemplate immutable={false} />
              </div> */}
            </BoardingPanel>
          </div>
        </div>
      )}
    </div>
  );
}
