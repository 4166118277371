import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { storeSummary } from "./Ducks/WorkDucks";
import { useFormik } from "formik";
// import * as Yup from "yup";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
// import Summary from "../../../components/molecules/Summary";

import { SAVE_SUMMARY } from "../../../apolloUtils";
// import ExperienceTemplate from "../../../components/templates/ExperienceTemplate";
// import { isEnoughSummary } from "../../../HelperFunctions";
import { setStep } from "../../../redux/appDucks";
import UploadCv from "../../../components/molecules/UploadCv";

export default function Resume({ state }) {
  const work = useSelector((state) => state.Work);
  const { user } = useSelector((state) => state.User);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [save, setSave] = useState(false);
  const [error, setError] = useState(false);
  // const { submitForm } = useFormikContext();

  const [saveSummary] = useMutation(SAVE_SUMMARY);

  useEffect(() => {
    if (state !== "preview") {
      dispatch(setStep(2));
    }
  }, [dispatch, state]);

  const formik = useFormik({
    initialValues: {
      summary: work.summary || user?.summary || "",
    },
    // validationSchema: Yup.object().shape({
    //   summary: Yup.string()
    //     .required("Summary is required")
    //     .test(
    //       "word number",
    //       "Please enter a minimum of 50 characters",
    //       (value) => isEnoughSummary(value)
    //     ),
    // }),
    onSubmit: (values) => {
      // setIsLoading(true);
      // saveSummary({
      //   variables: { input: { summary: values.summary, accountStage: 2, userId: user.id } },
      // }).then((data) => {
      //   if (data) {
      //     dispatch(storeSummary(values.summary));
      //     history.push("/build-your-profile/education");
      //     setIsLoading(false);
      //   }
      // });
    },
  });

  const moveBack = () => {
    saveSummary({
      variables: { input: { summary: formik.values.summary, userId: user.id } },
    }).then(() => {
      dispatch(storeSummary(formik.values.summary));
      history.push("/build-your-profile/");
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (!isLoading) {
      saveSummary({
        variables: { input: { accountStage: 5, userId: user.id } },
      }).then((data) => {
        if (data?.data?.updateUser?.cvUrl) {
          dispatch(storeSummary(''));
          history.push("/build-your-profile/preview");
          setIsLoading(false);
        } else {
          setError(`Please make sure to upload your CV`)
          setIsLoading(false);
        }
      });
    }
  }

  // const onSkip = () => {
  //   updateUser({ variables: { input: { accountStage: 2 } } });
  //   history.push("/build-your-profile/education");
  // };

  return (
    <div>
      {/* <BoardingPanel
        heading='Your Profile'
        subHeading='Set up your digital work profile'
        onBack={moveBack}
        onSubmit={formik.handleSubmit}
        state={state}
        isLoading={isLoading}
        help={true}
        helpText={[
          "Don't know where to start with your profile, click",
          "to view our tools and tips guide",
        ]}
        herf={
          "https://taptooling.s3.eu-west-2.amazonaws.com/Job+seeker+guide.pdf"
        }
        onSkip={user.class === "A" ? onSkip : undefined}
      >
        <div>
          <Summary
            disabled={false}
            value={formik.values.summary}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="summary"
          />
          {formik.errors.summary && formik.touched.summary ? (
            <div>
              <AppText size="_14" color="_r100">
                {formik.errors.summary}
              </AppText>
            </div>
          ) : null}
          <div className='mt-3'></div>
          <div className={'d-flex flex-column'}>
            <AppText size='_14'>
              {`• Describe your strong character traits in just a couple of words.`}
            </AppText>
            <AppText size='_14'>
              {`• Mention your current job title and professional experience.`}
            </AppText>
            <AppText size='_14'>
              {`• Say how you want to help the employer achieve their goals.`}
            </AppText>
            <AppText size='_14'>
              {`• Add info on your key achievements to prove you can deliver results when hired.`}
            </AppText>
          </div>
          <div className="mt-3">
            <ExperienceTemplate />
          </div>
        </div>
      </BoardingPanel> */}
      <BoardingPanel
        heading='Your Profile'
        subHeading='Set up your digital work profile'
        onBack={moveBack}
        onSubmit={handleSubmit}
        // state={state}
        isLoading={isLoading}
      // onSkip={user.class === 'A' ? onSkip : undefined}
      >
        <UploadCv
          setSave={setSave}
          save={save}
          setError={setError}
          error={error}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </BoardingPanel>

      <div className="py-5" />
    </div>
  );
}
