import React, { createRef } from "react";
import AppText from "../atoms/AppText";
import { useMediaQuery } from "react-responsive";
import { Create, DeleteOutline, WorkOutline } from "@material-ui/icons";
import Appddown from "../molecules/Appddown";
import Moment from "moment";

export default function ExperienceBox({
  data,
  index,
  len,
  onDelete,
  onEdit,
  preview,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const dropRef = createRef(null);

  const handleEdit = () => {
    dropRef.current.close();
    onEdit();
  };
  const handleDelete = () => {
    dropRef.current.close();
    onDelete();
  };

  return (
    <div className={`d-flex mb-3 pb-3 ${index === len - 1 ? "" : "_bbotm"}`}>
      {data.company.logoUrl !== "0" ? (
        <img
          src={data.company.logoUrl}
          alt="com"
          height={isDesktop || isTablet ? "50" : "36"}
          width={isDesktop || isTablet ? "50" : "36"}
        />
      ) : (
        <div className="_emp_logo">
          <WorkOutline style={{ fontsSize: "22px" }} />
        </div>
      )}

      <div className="ms-3 w-95">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <AppText
              size={isDesktop || isTablet ? "_18" : "_16"}
              weight="_medium"
            >
              {data.myRole}
            </AppText>
          </div>
          <div>
            <span className="etype px-2 py-1">
              <AppText size="_12" color="_c90">
                {data?.employmentType?.toUpperCase()}
              </AppText>
            </span>
            {!preview && (
              <span className="pointer _ws">
                <Appddown vertical="bottom" horizontal="center" ref={dropRef}>
                  <div className="ps-2 pe-5">
                    <div
                      className="d-flex align-items-center py-1 pointer"
                      onClick={handleEdit}
                    >
                      <Create
                        style={{ marginRight: "1rem", fontSize: "16px" }}
                      />
                      <AppText size="_14">Edit</AppText>
                    </div>
                    <div
                      className="d-flex align-items-center py-1 pointer"
                      onClick={handleDelete}
                    >
                      <DeleteOutline
                        style={{
                          marginRight: "1rem",
                          fontSize: "16px",
                          color: "#E6492D",
                        }}
                      />
                      <AppText size="_14" color="_r100">
                        Delete
                      </AppText>
                    </div>
                  </div>
                </Appddown>
              </span>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center flex-wrap">
            <div className="">
              <AppText size="_14" color="_c50">
                {data.company.name}
              </AppText>
            </div>
            <div className="mx-1">
              <AppText size="_14" color="_c50">
                |
              </AppText>
            </div>

            <div className="">
              <AppText size="_14" color="_c50">
                {data.company.location}
              </AppText>
            </div>
          </div>
          <div>
            <AppText size="_14" color="_c50">
              {Moment(data.startDate).format("MMM YYYY")} {"- "}
              {data.workHere
                ? "Present"
                : Moment(data.endDate).format("MMM YYYY")}
            </AppText>
            {!preview && <span className="_ws" />}
          </div>
        </div>
      </div>
    </div>
  );
}
