import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import AppInput from "../../../components/molecules/AppInput";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import AppDropdown from "../../../components/molecules/AppDropdown";
import { cities, countries } from "../data/basic";
import AppPhoneInput from "../../../components/atoms/PhoneInput";
import AppText from "../../../components/atoms/AppText";
import Avatar from "@material-ui/core/Avatar";
import {
  isValidImage,
  randomColor,
  returnInitals,
} from "../../../HelperFunctions";
import UploadButton from "../../../components/atoms/UploadButton";
import Model from "../../../components/molecules/Model";
import ImageUploadModal from "../../../components/orgnisms/ImageUploadModal";
import AppButton from "../../../components/molecules/AppButton";
import { DeleteOutline } from "@material-ui/icons";
import { SAVE_PROFILE_IMAGE, SUBSCRIBE_TO_SMS } from "../../../apolloUtils";
import { updateBasic, updatePhoto } from "../../../redux/userDucks";
import { setStep } from "../../../redux/appDucks";
import { fire } from "../../../firebase";

const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($input: editUserInput) {
    updateUser(input: $input) {
      fullName
      firstName
      lastName
      email
      phoneNumber
      country
      city
      heardUs
      accountStage
      strength
    }
  }
`;

export default function Basic({
  state,
  data = {},
  edit = false,
  done,
  isProfile = false,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [isLoading, setIsLoading] = useState(false);
  const [temp, setTemp] = useState(null);
  const [error, setError] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);

  const { path } = useRouteMatch();
  const history = useHistory();
  const auth = useSelector((state) => state.Auth);
  const { user } = useSelector((state) => state.User);
  const currentUser = fire.auth().currentUser;

  const dispatch = useDispatch();
  const [updateUser] = useMutation(UPDATE_USER_INFO);
  const [saveProfileImage] = useMutation(SAVE_PROFILE_IMAGE);
  const [subscribePhoneNumber] = useLazyQuery(SUBSCRIBE_TO_SMS);

  useEffect(() => {
    if (state !== "preview") {
      dispatch(setStep(1));
    }
  }, [dispatch, state]);

  const formik = useFormik({
    initialValues: {
      firstName:
        data?.firstName ||
        user?.firstName ||
        (auth?.firstName && state !== "preview" ? auth?.firstName : "") ||
        "",
      lastName:
        data?.lastName ||
        user?.lastName ||
        (auth?.lastName && state !== "preview" ? auth?.lastName : "") ||
        "",
      email:
        auth?.email ||
        data?.email ||
        user?.email ||
        fire.auth().currentUser.email ||
        "",
      phoneNumber: data?.phoneNumber || user?.phoneNumber || "",
      country: data?.country || user?.country || "",
      city: data?.city || user?.city || "",
      heardUs: data?.heardUs || user?.heardUs || "",
      desiredJob:
        data?.desiredJob ||
        user?.desiredJob ||
        auth?.desiredJob ||
        (auth?.desiredJob && state !== "preview" ? auth?.desiredJob : "") ||
        "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required("Your first Name is required")
        .matches("^[^0-9]+$", "Must not contain numbers")
        .matches(
          "^[^<>/$@%#&+'?,/.|()]+$",
          "Must not contain special characters"
        ),
      lastName: Yup.string()
        .required("Your Last Name is required")
        .matches("^[^0-9]+$", "Must not contain numbers")
        .matches(
          "^[^<>/$@%#&+'?,/.|()]+$",
          "Must not contain special characters"
        ),
      email: Yup.string()
        .required("Email is a required field")
        .email("Email is a required field"),
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .min(11, "Invalid phone number"),
      country: Yup.string().required("Country is a required field"),
      city: Yup.string().required("City is a required field"),
      heardUs: Yup.string().required("This is a required field"),
      desiredJob: Yup.string()
        .required("desired job is required")
        .matches("^[^0-9]+$", "Must not contain numbers")
        .matches(
          "^[^<>/$@%#&+'?,/.|()]+$",
          "Must not contain special characters"
        ),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const userInfo = {
        ...values,
        accountStage: 1,
        strength: 20,
        userId: user.id,
      };
      updateUser({ variables: { input: userInfo } }).then(() => {
        subscribePhoneNumber({
          variables: {
            input: { userId: user.id, phone: values.phoneNumber },
          },
        });
        history.push(`${path}/resume`);
        setIsLoading(false);
        dispatch(updateBasic(userInfo));
        if (done !== undefined) {
          done(true);
        }
      });
    },
  });

  const handleChange = (file) => {
    const { temp } = isValidImage(file);
    setTemp(temp);
    setError("");
    setPreviewImage(true);
  };

  const removePhoto = () => {
    saveProfileImage({
      variables: { input: { photoUrl: null, userId: user.id } },
    }).then(({ data }) => {
      dispatch(updatePhoto(data.updateUser.photoUrl));
    });
  };

  return (
    <div>
      {!isProfile && state !== "preview" ? (
        <div className="mb-4 _panel">
          <div className="px-4 py-3 botm">
            <AppText size="_18" weight="_medium">
              Add a profile photo
            </AppText>
          </div>
          <div className="d-flex align-items-center my-3 px-4">
            <Avatar
              style={{
                height: "60px",
                width: "60px",
                backgroundColor: randomColor(
                  user?.firstName
                    ? user?.firstName
                    : `${currentUser?.displayName?.split(" ")[0]} ${
                        currentUser?.displayName?.split(" ")[1]
                      }`
                ),
              }}
              src={user?.photoUrl || ""}
            >
              <AppText weight="_medium" color="_c0">
                {returnInitals(
                  user?.firstName
                    ? `${user.firstName} ${user.lastName}`
                    : `${currentUser?.displayName?.split(" ")[0]} ${
                        currentUser?.displayName?.split(" ")[1]
                      }`
                )}
              </AppText>
            </Avatar>
            <span className="ms-4">
              {user?.photoUrl ? (
                <AppButton color="_white" onClick={removePhoto}>
                  <DeleteOutline
                    style={{
                      fontSize: "17px",
                      color: "#E6492D",
                      marginRight: "10px",
                    }}
                  />
                  <AppText size="_14" color="_r100">
                    Remove Photo
                  </AppText>
                </AppButton>
              ) : (
                <UploadButton
                  label="Upload photo"
                  onChange={(file) => handleChange(file)}
                />
              )}
            </span>
          </div>
          {error && (
            <div className="px-4 pb-3 botm">
              <AppText size="_14" color="_r100">
                {error}
              </AppText>
            </div>
          )}
        </div>
      ) : null}

      <BoardingPanel
        heading="Basic Information"
        subHeading="Let us know more about you"
        state={state}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onSubmitText={edit ? "Save Changes" : "Next"}
      >
        <form>
          <div className="d-flex flex-wrap justify-content-between">
            <div className={isDesktop || isTablet ? "w-45" : "w-100"}>
              <AppInput
                label="FIRST NAME"
                placeholder="First Name"
                editable={state === "preview" ? false : true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                name="firstName"
                hasError={
                  formik.touched.firstName && formik.errors.firstName
                    ? true
                    : false
                }
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <AppText size="_12" color="_r100">
                  {formik.errors.firstName}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-45" : "w-100 mt-3"}>
              <AppInput
                label="LAST NAME"
                placeholder="Last Name"
                editable={state === "preview" ? false : true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                name="lastName"
                hasError={
                  formik.touched.lastName && formik.errors.lastName
                    ? true
                    : false
                }
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <AppText size="_12" color="_r100">
                  {formik.errors.lastName}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-45 mt-3" : "w-100 mt-3"}>
              <AppInput
                label="EMAIL ADDRESS"
                placeholder="Email address"
                editable={false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                hasError={
                  formik.touched.email && formik.errors.email ? true : false
                }
              />
              {formik.touched.email && formik.errors.email && (
                <AppText size="_12" color="_r100">
                  {formik.errors.email}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-45 mt-3" : "w-100 mt-3"}>
              {state === "preview" ? (
                <AppInput
                  label="COUNTRY"
                  placeholder="Country"
                  editable={state === "preview" ? false : true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                  name="country"
                />
              ) : (
                <AppDropdown
                  defaultValue={formik.values.country}
                  formik={formik}
                  name="country"
                  label="COUNTRY"
                  placeholder="Country"
                  items={countries}
                  onChange={(value) => formik.setFieldValue("country", value)}
                />
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-45 mt-3" : "w-100 mt-3"}>
              {state === "preview" ? (
                <AppInput
                  label="CITY/TOWN"
                  placeholder="City"
                  editable={state === "preview" ? false : true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  name="city"
                />
              ) : (
                <AppDropdown
                  defaultValue={formik.values.city}
                  formik={formik}
                  name="city"
                  label="CITY/TOWN"
                  placeholder="City"
                  items={cities}
                  onChange={(value) => formik.setFieldValue("city", value)}
                />
              )}
            </div>

            <div className={isDesktop || isTablet ? "w-45 mt-3" : "w-100 mt-3"}>
              <AppPhoneInput
                formik={formik}
                defaultValue={formik.values.phoneNumber}
                editable={state === "preview" ? false : true}
              />
            </div>
            {!isProfile && (
              <div
                className={isDesktop || isTablet ? "w-45 mt-3" : "w-100 mt-3"}
              >
                {state === "preview" ? (
                  <AppInput
                    label="HOW DID YOU HEAR ABOUT US?"
                    placeholder="How did you hear about us"
                    editable={state === "preview" ? false : true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.heardUs}
                    name="heardUs"
                  />
                ) : (
                  <AppDropdown
                    defaultValue={formik.values.heardUs}
                    formik={formik}
                    name="heardUs"
                    label="HOW DID YOU HEAR ABOUT US?"
                    placeholder="How did you hear about us"
                    items={[
                      "Indeed",
                      "Facebook",
                      "Twitter",
                      "Search Engine",
                      "Instagram",
                      "Other",
                    ]}
                    onChange={(value) => formik.setFieldValue("heardUs", value)}
                    searchDisabled={true}
                  />
                )}
              </div>
            )}

            <div className={isDesktop || isTablet ? "w-45 mt-3" : "w-100 mt-3"}>
              <AppInput
                label="DESIRED JOB"
                placeholder="Desired Job"
                editable={state === "preview" ? false : true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.desiredJob}
                name="desiredJob"
                hasError={
                  formik.touched.desiredJob && formik.errors.desiredJob
                    ? true
                    : false
                }
              />

              {formik.touched.desiredJob && formik.errors.desiredJob && (
                <AppText size="_12" color="_r100">
                  {formik.errors.desiredJob}
                </AppText>
              )}
            </div>
          </div>

          <div className="mt-3" />
        </form>
      </BoardingPanel>
      {state !== "preview" && !isProfile && (
        <div>
          <div className="pt-5" />
          <div className="pt-5" />
        </div>
      )}
      {previewImage && (
        <Model onClose={() => setPreviewImage(false)}>
          <ImageUploadModal
            temp={temp}
            onClose={() => setPreviewImage(false)}
          />
        </Model>
      )}
    </div>
  );
}
