import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  matchPath,
} from "react-router-dom";
import { useSubscription, gql, useQuery, useMutation } from "@apollo/client";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useSelector, useDispatch } from "react-redux";
import AppHeader from "../../components/orgnisms/AppHeader";
import CompanyProfile from "./templates/CompanyProfile";
import Favourites from "./templates/Favourites";
import FindJob from "./templates/FindJob";
import Job from "./templates/Job";
import Profile from "./templates/Profile";
import NotFound from "./templates/NotFound";
import Results from "./templates/Results";
import Status from "./templates/Status";
import Chat from "./templates/Chat";
import Notifications from "./templates/Notifications";
import { setJobs } from "./templates/StatusDucks";
import { setUnread } from "./templates/ChatsDucks";
import { setFavs } from "./HomeDuck";

import {
  pushNotification,
  pushAllNotifications,
  setNotifications,
  setAllNotifications,
  setStrength,
  setTip,
} from "../../redux/userDucks";
import {
  GET_MY_JOBS,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_NOTIFICATIONS,
  GET_USER_IMPRESSION,
  GET_MY_FAVS,
  SIGNOUT,
} from "../../apolloUtils";
import { impression, logout } from "../../HelperFunctions";
import { fire } from "../../firebase/index";

const GET_MY_UNREAD_MESSAGES = gql`
  query ($input: ID!) {
    getMyUnreadMessages(userId: $input) {
      id
      conversationId
      messageContent
    }
  }
`;

export default function HomeStack(props) {
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();

  const { loading, data } = useSubscription(FETCH_NOTIFICATIONS, {
    variables: { input: user.id },
  });
  const [signOutUser] = useMutation(SIGNOUT);

  const {
    loading: unreadMessageLoading,
    data: unreadMessageData,
    refetch: unreadMessagesRefetch,
  } = useQuery(GET_MY_UNREAD_MESSAGES, {
    variables: { input: user.id },
    pollInterval: 2000,
  });
  const { loading: loadingData, data: notiData } = useQuery(
    FETCH_ALL_NOTIFICATIONS,
    {
      variables: { input: user.id },
      pollInterval: 2000,
    }
  );
  const {
    loading: loadingMyJobs,
    data: myJobsData,
    refetch: refetchMyJobs,
  } = useQuery(GET_MY_JOBS, {
    variables: { input: user.id },
    fetchPolicy: "no-cache",
  });
  const { loading: loadingImpression, data: impressionData } = useQuery(
    GET_USER_IMPRESSION,
    {
      pollInterval: 2000,
      variables: {
        id: fire?.auth()?.currentUser?.uid,
      },
    }
  );
  const { loading: favLoading, data: favData } = useQuery(GET_MY_FAVS, {
    variables: { input: user.id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loadingMyJobs && myJobsData) {
      dispatch(setJobs(myJobsData.getAppliedJobs));
    }
  }, [loadingMyJobs, myJobsData, dispatch]);

  useEffect(() => {
    if (!unreadMessageLoading && unreadMessageData) {
      dispatch(setUnread(unreadMessageData.getMyUnreadMessages));
    }
  }, [unreadMessageLoading, unreadMessageData, dispatch]);

  useEffect(() => {
    if (!loadingData && notiData) {
      const unSeen = notiData.getMyNotifications.filter(
        (el) => el.read === false
      );
      if (unSeen.length !== 0) {
        dispatch(setNotifications(unSeen));
      }
      dispatch(setAllNotifications(notiData.getMyNotifications));
    }
  }, [user?.notificationsLastSeen, loadingData, notiData, dispatch]);

  useEffect(() => {
    if (!loading && data) {
      dispatch(pushNotification(data.notificationListener));
      dispatch(pushAllNotifications(data.notificationListener));
      unreadMessagesRefetch({ variables: { input: user.id } });
      refetchMyJobs({ variables: { input: user.id } });
    }
  }, [loading, data, dispatch, refetchMyJobs, user.id, unreadMessagesRefetch]);

  useEffect(() => {
    if (!loadingImpression && impressionData) {
      const { strength, tip } = impression(impressionData.getCurrentUser);
      dispatch(setStrength(strength));
      dispatch(setTip(tip));
    }
  }, [loadingImpression, impressionData, dispatch]);

  useEffect(() => {
    const logUserOut = (inter) => {
      signOutUser({
        variables: { id: fire.auth().currentUser.uid },
      }).then(({ data }) => {
        if (data.signOutUser) {
          clearInterval(inter);
          logout(fire);
        }
      });
    };

    if (!loadingImpression && impressionData) {
      const exp = impressionData.getCurrentUser.sessionExp * 1000;

      const inter = setInterval(() => {
        if (exp < new Date().valueOf()) {
          logUserOut(inter);
        }
      }, 1000 * 6);

      return () => {
        clearInterval(inter);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingImpression, impressionData]);

  useEffect(() => {
    if (!favLoading && favData) {
      dispatch(setFavs(favData.getMyFavs));
    }
  }, [favLoading, favData, dispatch]);

  const routes = [
    { path: "/" },
    { path: "/search/:query" },
    { path: "/favourites" },
    { path: "/job/:id" },
    { path: "/company/:id" },
    { path: "/profile" },
    { path: "/chat" },
    { path: "/status" },
    { path: "/notifications" },
  ];

  const history = createBrowserHistory();

  Sentry.init({
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });

  return (
    <div className="_bg_color">
      <div className="_xcoller">
        <div style={{ height: "50px" }} />
        <Router history={history}>
          <Switch>
            <Route path="/" exact>
              <FindJob />
            </Route>
            <Route path="/search/:query">
              <Results />
            </Route>
            <Route path="/favourites">
              <Favourites />
            </Route>
            <Route path="/job/:id">
              <Job />
            </Route>
            <Route path="/company/:id">
              <CompanyProfile />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/chat">
              <Chat />
            </Route>
            <Route path="/status">
              <Status />
            </Route>
            <Route path="/notifications">
              <Notifications />
            </Route>
            <Route component={NotFound} />
          </Switch>
          <AppHeader {...props} impression={impression} />
        </Router>
      </div>
    </div>
  );
}
