import React from "react";
import NotificationCard from "../../../../components/molecules/NotificationCard";
import NoStatus from "../../../../components/molecules/NoStatus";

export default function Status({ notification }) {
  return (
    <>
      {notification
        .sort((a, b) => (a.sentAt > b.sentAt ? -1 : a.sentAt < b.sentAt ? 1 : 0))
        .map((noti, i) => (
          <div className='app_panel px-3 pt-2 mb-2' key={i}>
            <NotificationCard notification={noti} />
          </div>
        ))}
      {notification.length === 0 ? (
        <NoStatus height={250} />
      ) : null}
    </>
  );
}
