import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ArrowBackOutlined, Search } from "@material-ui/icons";
import Query from "query-string";
import { useParams } from "react-router-dom";
import { toggleMap } from "../../pages/homeStack/HomeDuck";
import AppIconButton from "../atoms/AppIconButton";
import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";
import SearchBox2 from "../molecules/SearchBox2";
import { textCut } from "../../HelperFunctions";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

export default function AppTopBar({ onSubmit }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [searchOpen, setSearchOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const home = useSelector((state) => state.Home);

  const params = useParams();

  const handleToggle = () => {
    dispatch(toggleMap());
  };

  const goBack = () => {
    history.replace("/");
  };

  const handleSubmit = (data) => {
    history.push(
      `/search/q=${data.query}&location=${data.location}&cord=${data.cords}`
    );
    onSubmit();
  };

  return (
    <div>
      {searchOpen ? (
        <Slide direction="bottom" in={searchOpen}>
          <div className="_topbar_sm">
            <div className="px-4 d-flex py-4">
              <div className="me-3 mt-1">
                <AppIconButton onClick={() => setSearchOpen(false)}>
                  <ArrowBackOutlined
                    style={{ color: "#6B6C6F", fontSize: "20px" }}
                  />
                </AppIconButton>
              </div>
              <SearchBox2
                values={{
                  location: Query.parse(params.query).location,
                  query: Query.parse(params.query).q,
                  cords: [
                    Query.parse(params.query).cord.split(",")[0],
                    Query.parse(params.query).cord.split(",")[1],
                  ],
                }}
                onSubmit={(data) => handleSubmit(data)}
              />
            </div>
          </div>
        </Slide>
      ) : (
        <div className="_topbar d-flex justify-content-center">
          <div
            className={`${
              isDesktop ? "w-75" : isTablet ? "w-95" : "w-100 px-4"
            }  d-flex justify-content-between align-items-center`}
          >
            <div
              className={`d-flex align-items-center ${
                isDesktop || isTablet ? "_str" : "_str_sm"
              }`}
            >
              <div className="me-3">
                <AppIconButton onClick={goBack}>
                  <ArrowBackOutlined
                    style={{ color: "#6B6C6F", fontSize: "20px" }}
                  />
                </AppIconButton>
              </div>
              {isDesktop || isTablet ? (
                <SearchBox2
                  values={{
                    location: Query.parse(params.query).location,
                    query: Query.parse(params.query).q,
                    cords: [
                      Query.parse(params.query).cord.split(",")[0],
                      Query.parse(params.query).cord.split(",")[1],
                    ],
                  }}
                  onSubmit={(data) => handleSubmit(data)}
                />
              ) : (
                <div className="_searcc" onClick={() => setSearchOpen(true)}>
                  <div className="mx-2">
                    <AppText size="_14">
                      {Query.parse(params.query).q === "allJobs"
                        ? ""
                        : textCut(Query.parse(params.query).q, 10)}
                    </AppText>
                  </div>
                  {Query.parse(params.query).q &&
                    Query.parse(params.query)?.location && (
                      <AppText color="_c90">|</AppText>
                    )}

                  <div className="mx-2">
                    <AppText size="_14" color="_c90">
                      {textCut(
                        Query.parse(params.query)?.location.substr(8),
                        15
                      )}
                    </AppText>
                  </div>

                  <div className="_seIcon">
                    <Search style={{ color: "#CECFD2", fontSize: "18px" }} />
                  </div>
                </div>
              )}
            </div>
            {isDesktop || isTablet ? (
              <AppButton color="_white" onClick={handleToggle}>
                <span className={isDesktop || isTablet ? "me-2" : ""}>
                  <i
                    className="icon-map"
                    style={{ color: "#9EA0A5", fontSize: "18px" }}
                  />
                </span>
                {home.showsMap ? (
                  <AppText size="_14">Hide Map</AppText>
                ) : (
                  <AppText size="_14">Show Map</AppText>
                )}
              </AppButton>
            ) : (
              <IconButton
                onClick={handleToggle}
                style={{ borderRadius: "4px", border: "1px solid #E2E5ED" }}
              >
                <i
                  className="icon-map"
                  style={{ color: "#9EA0A5", fontSize: "15px" }}
                />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
