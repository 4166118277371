const SAVE_SUMMARY = "SAVE_SUMMARY";

const initialState = {
  summary: "",
};
 


const WorkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SUMMARY:
      return { ...state, summary: action.payload };
    default:
      return state;
  }
};

export const storeSummary = (st) => {
  return {
    type: SAVE_SUMMARY,
    payload: st,
  };
};
export default WorkReducer;
