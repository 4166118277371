const SET_USER_ = "SET_USER_";
const ADD_TO_FAV = "ADD_TO_FAV";
const ADD_KEY_WORD = "ADD_KEY_WORD";

const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
const SET_NOTIFICATION = "SET_NOTIFICATION";
const SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS";
const PUSH_ALL_NOTIFICATIONS = "PUSH_ALL_NOTIFICATIONS";
const CLEAR_ALERT = "CLEAR_ALERT";

const UPDATE_PHOTO = "UPDATE_PHOTO";
const UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO";
const UPDATE_STRENGTH = "UPDATE_STRENGTH";
const UPDATE_TIP = "UPDATE_TIP";

const initialState = {
  user: {},
  unreadNotications: [],
  unseenMessageNofications: [],
  unseenStatusNofications: [],
  allNotifications: [],
  strength: null,
  tip: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_:
      return { ...state, user: action.payload };
    case ADD_KEY_WORD:
      return {
        ...state,
        user: { ...state.user, keywords: [...state.user.keywords, action.payload] },
      };
    case ADD_TO_FAV:
      return state;

    case PUSH_NOTIFICATION:
      if (action.payload.action === 1) {
        return {
          ...state,
          unreadNotications: [...state.unreadNotications, action.payload],
          unseenMessageNofications: [...state.unseenMessageNofications, action.payload],
        };
      } else if (action.payload.action === 3) {
        return {
          ...state,
          unreadNotications: [...state.unreadNotications, action.payload],
          unseenStatusNofications: [...state.unseenStatusNofications, action.payload],
        };
      }
      return { ...state, unreadNotications: [...state.unreadNotications, action.payload] };
    case SET_NOTIFICATION:
      return { ...state, unreadNotications: [...action.payload] };
    case SET_ALL_NOTIFICATIONS:
      return { ...state, allNotifications: [...action.payload] };
    case PUSH_ALL_NOTIFICATIONS:
      return { ...state, allNotifications: [...state.allNotifications, action.payload] };
    case CLEAR_ALERT:
      return { ...state, ...action.payload };
    case UPDATE_PHOTO:
      return { ...state, user: { ...state.user, photoUrl: action.payload } };
    case UPDATE_BASIC_INFO:
      return { ...state, user: { ...state.user, ...action.payload } };
    case UPDATE_STRENGTH:
      return { ...state, strength: action.payload };
    case UPDATE_TIP:
      return { ...state, tip: action.payload };
    default:
      return state;
  }
};

export const setUser = (user) => {
  return {
    type: SET_USER_,
    payload: user,
  };
};

export const addToFav = (job) => {
  return {
    type: ADD_TO_FAV,
    payload: job,
  };
};

export const pushNotification = (noti) => {
  return {
    type: PUSH_NOTIFICATION,
    payload: noti,
  };
};
export const setNotifications = (noti) => {
  return {
    type: SET_NOTIFICATION,
    payload: noti,
  };
};

export const setAllNotifications = (noti) => {
  return {
    type: SET_ALL_NOTIFICATIONS,
    payload: noti,
  };
};

export const pushAllNotifications = (noti) => {
  return {
    type: PUSH_ALL_NOTIFICATIONS,
    payload: noti,
  };
};

export const clearAlert = (act) => {
  return {
    type: CLEAR_ALERT,
    payload: act,
  };
};

export const updatePhoto = (url) => {
  return {
    type: UPDATE_PHOTO,
    payload: url,
  };
};
export const updateBasic = (info) => {
  return {
    type: UPDATE_BASIC_INFO,
    payload: info,
  };
};

export const setStrength = (v) => {
  return {
    type: UPDATE_STRENGTH,
    payload: v,
  };
};
export const setTip = (v) => {
  return {
    type: UPDATE_TIP,
    payload: v,
  };
};
export const addKeyword = (keyword) => {
  return {
    type: ADD_KEY_WORD,
    payload: keyword,
  };
};

export default UserReducer;
