import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import AppText from "../atoms/AppText";

export default function AppInput({
  label,
  onChange,
  value,
  onBlur,
  name,
  placeholder,
  hasError = false,
}) {
  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input: {
      paddingTop: "12px",
      height: "43px",
      paddingBottom: "12px",
      fontFamily: "Roboto",
      color: "#3E3F42",
      border: hasError ? "1px solid #E6492D" : "1px solid #D8DCE6",
    },
    btn: {
      padding: "0!important",
      "&:hover": {
        background: "none",
      },
      "&:focus": {
        outline: 0,
      },
    },
  });

  const classes = useSyles();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <div className={classes.label}>
        <AppText size="_12" color="_c50" weight="_medium">
          {label}
        </AppText>
      </div>

      <div>
        <TextField
          variant="outlined"
          name={name}
          value={value}
          onBlur={onBlur}
          fullWidth
          placeholder={placeholder}
          size="small"
          type={showPassword ? "text" : "password"}
          onChange={onChange}
          className={classes.root}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.btn}
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            className: classes.input,
          }}
        />
      </div>
    </div>
  );
}
