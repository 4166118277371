const TOGGLE_MAP = "TOGGLE_MAP";
const TOGGLE_GRID = "TOGGLE_GRID";
const SET_JOB_RESULTS = "SET_JOB_RESULTS";
const SET_LOADING = "SET_LOADING";
const SET_FAVS = "SET_FAVS";
const ADD_FAV = "ADD_FAV";

const initialState = {
  showsMap: false,
  showsGrid: true,
  jobResults: [],
  isLoading: true,
  favourites: [],
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MAP:
      return { ...state, showsMap: !state.showsMap };
    case TOGGLE_GRID:
      return { ...state, showsGrid: action.payload };
    case SET_JOB_RESULTS:
      return { ...state, jobResults: action.payload };
    case SET_FAVS:
      return { ...state, favourites: action.payload };
    case ADD_FAV:
      const newFavs = [...state.favourites];
      const job = newFavs.findIndex((el) => el.job.id === action.payload.job.id);
      if (job >= 0) {
        newFavs.splice(job, 1);
        return { ...state, favourites: newFavs };
      }
      return { ...state, favourites: [...newFavs, action.payload] };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export const toggleMap = () => {
  return {
    type: TOGGLE_MAP,
  };
};

export const toggleGrid = (toggler) => {
  return {
    type: TOGGLE_GRID,
    payload: toggler,
  };
};

export const setJobResults = (res) => {
  return {
    type: SET_JOB_RESULTS,
    payload: res,
  };
};

export const setFavs = (job) => {
  return {
    type: SET_FAVS,
    payload: job,
  };
};
export const addFav = (job) => {
  return {
    type: ADD_FAV,
    payload: job,
  };
};
export const setIsLoading = (v) => {
  return {
    type: SET_LOADING,
    payload: v,
  };
};

export default HomeReducer;
