import React from "react";
import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function BoardingPanel({
  heading,
  subHeading,
  children,
  onBack,
  state,
  onSubmit,
  onSubmitText = "Next",
  onSkip,
  isLoading = false,
  help = false,
  helpText = false,
  herf = false,
}) {
  return (
    <div className="_panel">
      <div className="px-4 py-3 botm">
        <div>
          <AppText size="_18" weight="_medium">
            {heading}
          </AppText>
        </div>
        {subHeading !== undefined ? (
          <div className={"d-flex flex-row"}>
            <AppText size="_14" color="_c50">
              {subHeading}{" "}
              {help ? (
                <AppText size="_14" color="_c50">
                  {helpText[0]}{" "}
                  <a href={herf} target="_blank" rel="noopener noreferrer" style={{color: "#07BF91"}}>
                    here
                  </a>{" "}
                  {helpText[1]}
                </AppText>
              ) : null}
            </AppText>
          </div>
        ) : null}
      </div>
      <div className="px-4 py-3">{children}</div>
      {state === "preview" ? null : (
        <div className="px-4 py-3 d-flex justify-content-between topm _darc">
          <div>
            {onBack !== undefined ? (
              <AppButton color="_white" onClick={onBack}>
                <span className="mx-3">
                  <AppText size="_14">Back</AppText>
                </span>
              </AppButton>
            ) : null}
          </div>
          <div>
            {onSkip !== undefined && (
              <span onClick={onSkip} className="me-4 pointer">
                <AppText size="_14">Skip</AppText>
              </span>
            )}

            <AppButton onClick={onSubmit}>
              {isLoading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "30px", width: "30px" }}
                />
              ) : (
                <span className="mx-3">
                  <AppText color="_c0" size="_14">
                    {onSubmitText}
                  </AppText>
                </span>
              )}
            </AppButton>
          </div>
        </div>
      )}
    </div>
  );
}
