import React, { useRef, useLayoutEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import AppText from "../atoms/AppText";

export default function LocationSearchMapbox({
  label,
  onChange,
  defaultValue = null,
  labeled = true,
  onBlur,
  hasError,
}) {
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (defaultValue?.address) {
      inputRef.current.refs.input.value = defaultValue.address;
    } else {
      inputRef.current.refs.input.value = defaultValue;
    }
  }, [defaultValue]);

  return (
    <div className="w-100">
      {labeled && (
        <div className="ld">
          <AppText size="_12" color="_c50" weight="_medium">
            {label}
          </AppText>
        </div>
      )}
      <div
        className={
          labeled && hasError
            ? "_bolx_err"
            : labeled && !hasError
            ? "_bolx"
            : ""
        }
      >
        <Autocomplete
          ref={inputRef}
          className="_loc_input"
          onBlur={onBlur}
          defaultValue={defaultValue}
          placeholder="Job Location"
          types={["geocode"]}
          onPlaceSelected={(place) =>
            onChange({
              address: place.address_components[0].long_name,
              cords: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
            })
          }
        />
      </div>
    </div>
  );
}
