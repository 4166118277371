import React from "react";
import { useMediaQuery } from "react-responsive";

export default function Welcome({ children }) {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  return (
    <div
      className={`${
        isDesktop || isTablet ? "welcome" : "welcome-sm"
      }  d-flex justify-content-center mt-5 align-items-center`}
    >
      <div
        className={`h-100 py-2 ${
          isDesktop ? "w-70" : isTablet ? "w-60" : "w-95"
        } d-flex align-items-center`}
      >
        {children}
      </div>
    </div>
  );
}
