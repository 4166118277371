import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DropDownArrow from "../atoms/DropDownArrow";
import AppText from "../atoms/AppText";

export default function AppDateDropdown({ disabled = false, value, label, onChange }) {
  const dropdownRef = useRef(null);
  return (
    <div>
      <div className='mb-2'>
        <AppText size='_12' color='_c50' weight='_medium'>
          {label}
        </AppText>
      </div>
      <div className={`_date_pi d-flex justify-content-between ${disabled && "_ddis"}`}>
        <CalendarTodayIcon style={{ color: "#9ea0a5" }} />
        <DatePicker
          className='_dp'
          selected={value}
          onChange={onChange}
          dateFormat='MM/yyyy'
          showMonthYearPicker
          showFullMonthYearPicker
          disabled={disabled}
          ref={dropdownRef}
        />
        <span className='pointer' onClick={() => dropdownRef.current.setFocus()}>
          <DropDownArrow />
        </span>
      </div>
    </div>
  );
}
