import React, { useEffect, useState } from "react";
import {
  ArrowBackSharp,
  Check,
  PeopleOutlineTwoTone,
} from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import Moment from "moment";
import { addFav } from "../HomeDuck";
import { useHistory, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery, gql } from "@apollo/client";
import {
  STAR_JOB,
  APPLYTOJOB,
  UPDATE_JOB_VIEW,
  SIMILAR_JOBS,
  SEND_APPLICATION_NOTIFICATION,
  GET_JOB_BY_ID,
} from "../../../apolloUtils";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import { setAppied } from "./StatusDucks";
import { processHeadings, textCut, closeDate } from "../../../HelperFunctions";
import numbro from "numbro";

const OPEN_CHAT = gql`
  mutation ($input: ChatInput) {
    openChat(input: $input)
  }
`;

export default function Job({ location }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [appTojob] = useMutation(APPLYTOJOB);
  const [updateJobViews] = useMutation(UPDATE_JOB_VIEW);
  const [sendApplicationNotification] = useMutation(
    SEND_APPLICATION_NOTIFICATION
  );

  const [addToFavourites] = useMutation(STAR_JOB);
  const { user } = useSelector((state) => state.User);
  const { favourites } = useSelector((state) => state.Home);
  const appliedJob = useSelector((state) => state.Status);
  const [isClosing, setIsClosing] = useState("");
  const [canApply, setCanApply] = useState(true);
  const [jobTitle, setJobTitle] = useState("");
  const params = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, data } = useQuery(SIMILAR_JOBS, {
    variables: { query: jobTitle },
  });
  const {
    loading: jobLoading,
    data: jobData,
    refetch: jobRefetch,
  } = useQuery(GET_JOB_BY_ID, { variables: { id: params.id } });
  const [openChat] = useMutation(OPEN_CHAT);

  const didApply = appliedJob.find((j) => j.job?.id === params.id);

  const goBack = () => {
    if (history?.location?.state?.page !== undefined) {
      history.push(history.location.state.location, {
        page: history.location.state.page,
        location: history.location.state.location,
      });
    } else {
      if (window.localStorage.getItem("job")) {
        window.localStorage.removeItem("job");
        history.replace("/");
      } else {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    if (!jobLoading && jobData) {
      setIsClosing(closeDate(jobData?.getJobById?.closingDate));
      setJobTitle(jobData?.getJobById?.jobTitle);

      return () => {
        updateJobViews({
          variables: {
            input: {
              companyId: jobData?.getJobById?.company.id,
              jobId: jobData?.getJobById?.id,
            },
          },
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobLoading, jobData, updateJobViews]);

  useEffect(() => {}, [jobTitle]);

  const addTofavs = () => {
    const { id } = jobData?.getJobById;

    addToFavourites({ variables: { input: { job: id, candidate: user.id } } });
    dispatch(addFav({ job: jobData?.getJobById }));
  };

  const applyJob = (canApply) => {
    if (canApply) {
      const { id } = jobData?.getJobById;
      appTojob({
        variables: { input: { jobId: id, candidate: user.id } },
      }).then(({ data }) => {
        if (data.appyForJob !== null) {
          sendApplicationNotification({
            variables: {
              input: {
                string: `${user.firstName} ${user.lastName} Applied to ${jobData.getJobById.jobTitle}`,
                action: 5,
                toUser: jobData.getJobById.assignedTo.id,
                senderFullName: "",
                senderPhotoUrl: "",
              },
            },
          });
          jobRefetch();
          dispatch(setAppied({ job: jobData?.getJobById, status: 1 }));
          setCanApply(false);
          if (jobData?.getJobById?.applyLinkUrl) {
            window.open(jobData?.getJobById?.applyLinkUrl, "_blank");
          }
        }
      });
    } else {
      setCanApply(false);
      if (jobData?.getJobById?.applyLinkUrl) {
        window.open(jobData?.getJobById?.applyLinkUrl, "_blank");
      }
    }
  };

  const onOpenChat = () => {
    openChat({
      variables: {
        input: { seeker: user.id, manager: jobData.getJobById.assignedTo.id },
      },
    }).then(({ data }) => {
      history.push(`/chat/${data.openChat}`, {
        id: data.openChat,
        manager: {
          id: jobData.getJobById.assignedTo.id,
          fullName: jobData.getJobById.assignedTo.fullName,
          avatarUrl: jobData.getJobById.assignedTo.avatarUrl,
          roles: jobData.getJobById.assignedTo.roles,
        },
      });
    });
  };

  return (
    <div className="d-flex justify-content-center _bg_color ">
      {!jobLoading && jobData ? (
        <div
          className={`${
            isDesktop ? "w-75 d-flex" : isTablet ? "w-82" : "w-100 px-1"
          } pt-4`}
        >
          <div className={isDesktop ? "w-70 pe-3" : "w-100"}>
            <div
              className={isDesktop || isTablet ? "_job_cover" : "_job_cover_sm"}
            >
              <img
                alt="er3"
                src={jobData.getJobById.company.header}
                className="_ccover"
              />
              <button className="_back_btn" onClick={goBack}>
                <ArrowBackSharp style={{ color: "#fff" }} />
              </button>
            </div>
            <div className="pt-4 bg-white _bs">
              <div
                className={`${
                  isDesktop || isTablet
                    ? "d-flex justify-content-between"
                    : null
                } px-4`}
              >
                <div className="d-flex align-items-start me-2">
                  <div className="me-3">
                    <img
                      alt="er3"
                      src={jobData.getJobById.company.logo}
                      className="_clogo"
                    />
                  </div>
                  <div className="">
                    <div className="">
                      <AppText size="_18" weight="_medium">
                        <span style={{ textTransform: "capitalize" }}>
                          {processHeadings(jobData.getJobById.jobTitle)}
                        </span>
                      </AppText>
                    </div>
                    <AppText size="_14" color="_c90">
                      <AppText weight="_medium" size="_14">
                        {jobData.getJobById.company.name}
                      </AppText>{" "}
                      • {jobData.getJobById.location.split(",")[0]}
                    </AppText>
                    <div />
                    <AppText size="_12" weight="_medium" color="_g90">
                      <PeopleOutlineTwoTone style={{ fontSize: "17px" }} />{" "}
                      {jobData.getJobById.applicationsCount} Applicants
                    </AppText>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <div
                    className={
                      isDesktop || isTablet ? "d-flex" : "d-flex mt-3 _loco"
                    }
                  >
                    {didApply || !canApply ? (
                      <div
                        className={
                          isDesktop || isTablet
                            ? "d-flex justify-content-center"
                            : "w-82"
                        }
                      >
                        <AppButton
                          color="_green"
                          width={isDesktop || isTablet ? "_custom" : "_full"}
                          style={{ width: "112px" }}
                          state={didApply?.status === 4 ? true : false}
                          onClick={(e) => applyJob(canApply)}
                        >
                          <div>
                            <Check style={{ color: "#fff" }} />{" "}
                            <AppText size="_14" color="_c0">
                              Applied
                            </AppText>
                          </div>
                        </AppButton>
                      </div>
                    ) : (
                      <div className={isDesktop || isTablet ? "" : "w-75"}>
                        <AppButton
                          width={isDesktop || isTablet ? "_custom" : "_full"}
                          onClick={(e) =>
                            canApply ? applyJob(canApply) : null
                          }
                          style={{ width: "112px" }}
                        >
                          <AppText size="_14" color="_c0">
                            Apply Now
                          </AppText>
                        </AppButton>
                      </div>
                    )}

                    <div className="ms-2" />
                    {favourites.find(
                      (el) => el.job.id === jobData?.getJobById.id
                    ) ? (
                      <AppButton color="_white" onClick={addTofavs}>
                        <div>
                          <AppText size="_14">Saved</AppText>
                        </div>
                      </AppButton>
                    ) : (
                      <AppButton color="_white" onClick={addTofavs}>
                        <AppText size="_14">Save</AppText>
                      </AppButton>
                    )}
                  </div>
                  <div className="mt-2" />
                  <div className="d-flex flex-column">
                    <AppText size="_12" color="_c90">
                      Posted{" "}
                      {Moment(
                        parseInt(jobData?.getJobById?.createdAt)
                      ).fromNow()}
                    </AppText>
                    <div className="px-1" />
                    {!isClosing ? null : (
                      <span className="_12 _medium">
                        <span style={{ color: "#F6AB2F" }}> {isClosing}</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3" />
              <div className="_sep_ mx-4" />
              <div
                className={`d-flex my-4 px-4 ${
                  isDesktop || isTablet ? "" : "flex-wrap"
                }`}
              >
                <div className={isDesktop || isTablet ? "w-25" : "w-50"}>
                  <AppText size="_12" color="_c90">
                    Experience level
                  </AppText>
                  <div>
                    <AppText size="_14">
                      {jobData?.getJobById?.experienceLevel}
                    </AppText>
                  </div>
                </div>
                {jobData?.getJobById?.industry && (
                  <div className={isDesktop || isTablet ? "w-25" : "w-50"}>
                    <AppText size="_12" color="_c90">
                      Industry
                    </AppText>
                    <div>
                      <AppText size="_14">
                        {jobData?.getJobById?.industry}
                      </AppText>
                    </div>
                  </div>
                )}

                <div className={isDesktop || isTablet ? "w-25" : "w-50"}>
                  <AppText size="_12" color="_c90">
                    Employment Type
                  </AppText>
                  <div>
                    <AppText size="_14">
                      {jobData.getJobById.employmentType}
                    </AppText>
                  </div>
                </div>
                <div className={isDesktop || isTablet ? "w-25" : "w-50"}>
                  <AppText size="_12" color="_c90">
                    Salary
                  </AppText>
                  <div>
                    {jobData.getJobById.salaryRange?.defaultType !==
                    "hourly" ? (
                      <AppText size="_14">
                        £
                        {jobData.getJobById.salaryRange?.start !== 0
                          ? jobData.getJobById.salaryRange?.start < 1000
                            ? numbro(
                                jobData.getJobById.salaryRange?.start
                              ).format({ mantissa: 2 })
                            : numbro(
                                jobData.getJobById.salaryRange?.start
                              ).format({
                                thousandSeparated: true,
                                average: true,
                              })
                          : null}
                        {jobData.getJobById.salaryRange?.start !== 0
                          ? " - £"
                          : ""}
                        {jobData.getJobById.salaryRange?.end < 1000
                          ? numbro(jobData.getJobById.salaryRange?.end).format({
                              mantissa: 2,
                            })
                          : numbro(jobData.getJobById.salaryRange?.end).format({
                              thousandSeparated: true,
                              average: true,
                            })}
                      </AppText>
                    ) : (
                      <AppText size="_14">
                        £
                        {jobData.getJobById.salaryRange?.end < 1000
                          ? numbro(jobData.getJobById.salaryRange?.end).format({
                              mantissa: 2,
                            })
                          : numbro(jobData.getJobById.salaryRange?.end).format({
                              thousandSeparated: true,
                              average: true,
                            })}
                        /hr
                      </AppText>
                    )}
                  </div>
                </div>
              </div>
              <div className="_sep_" />
              <div
                className={
                  isDesktop || isTablet ? "d-flex" : "d-flex flex-wrap"
                }
              >
                <div
                  className={`${
                    isDesktop || isTablet ? "w-90" : "w-100"
                  }  px-4 pt-4 pb-4`}
                >
                  <AppText size="_14" weight="_medium">
                    Job Description
                  </AppText>
                  <div
                    className="mt-2"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Roboto, sans-serif",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: jobData.getJobById.description,
                    }}
                  />
                </div>
              </div>
              <div className="_sep_" />
              <div
                className={
                  isDesktop || isTablet ? "d-flex" : "d-flex flex-wrap"
                }
              >
                <div
                  className={`${
                    isDesktop || isTablet ? "w-100" : "w-100"
                  }  px-4 pt-4 pb-4`}
                >
                  <AppText size="_14" weight="_medium">
                    Need to know more?
                  </AppText>
                  <div
                    className={`mt-1 ${
                      isTablet || isDesktop ? "w-60" : "w-100"
                    }`}
                  >
                    <AppText size="_14" color="_c90">
                      Have questions about this this role or this company? Start
                      a discussion with one of the Hiring Managers
                    </AppText>
                  </div>
                  <div className=" mt-4 d-flex justify-content-end">
                    <AppButton color="_white" onClick={onOpenChat}>
                      <AppText size="_14">Talk to a hiring manager</AppText>
                    </AppButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5" />
          </div>
          <div
            className={
              isDesktop ? "w-30 ps-3" : isTablet ? "w-50 mb-4" : "w-100 mb-4"
            }
          >
            <div className="bg-white _companybox">
              <div className="pt-3 px-4">
                <AppText size="_14" color="_c90">
                  COMPANY PROFILE
                </AppText>
              </div>

              <div className="d-flex mt-3 px-4">
                <img
                  alt="er3"
                  src={jobData.getJobById.company.logo}
                  className="_clogo me-3"
                />
                <div>
                  <AppText size="_18">
                    {jobData.getJobById.company.name}
                  </AppText>
                  <div>
                    <AppText size="_14" color="_c90">
                      {jobData.getJobById.company.services} |{" "}
                      {jobData.getJobById.company.location}
                    </AppText>
                  </div>
                </div>
              </div>
              <div className="py-3 px-4">
                <AppText size="_14" color="_c90">
                  {textCut(jobData.getJobById.company.bio, 100)}
                </AppText>
              </div>
              <div className="bt-r">
                <button className="btn_p">
                  <Link
                    to={{
                      pathname: `/company/${jobData.getJobById.company.id}`,
                      state: {
                        ...jobData.getJobById.company,
                        assignedTo: jobData.getJobById.assignedTo,
                      },
                    }}
                  >
                    <AppText size="_12" color="_c90">
                      VIEW PROFILE
                    </AppText>
                  </Link>
                </button>
                <button className="btn_p _br">
                  <Link
                    to={`/search/q=${jobData.getJobById.company.name}&location=&cord=`}
                  >
                    <AppText size="_12" color="_c90">
                      SEE JOBS
                    </AppText>
                  </Link>
                </button>
              </div>
            </div>
            <div className="mt-4 bg-white _companybox">
              <div className="py-3 px-4">
                <AppText weight="_medium">Explore Similar Jobs</AppText>
              </div>
              <div className="_sep_" />
              <div className="px-4 pt-2">
                {loading && <p>loading...</p>}
                {!loading && data?.similarJobs.length <= 1 && (
                  <AppText>No similar jobs</AppText>
                )}
                {!loading &&
                  data &&
                  data?.similarJobs
                    .filter((el) => el.id !== jobData?.getJobById.id)
                    .map((job, index) => {
                      if (index < 6) {
                        return (
                          <div key={job.id} className="my-3">
                            <Link
                              to={{ pathname: `/job/${job.id}`, state: job }}
                            >
                              <div className="d-flex mb-3 align-items-center">
                                <div>
                                  <img
                                    src={job?.company.logo}
                                    alt="js"
                                    height="46"
                                    width="46"
                                    className="me-3"
                                  />
                                </div>
                                <div>
                                  <AppText size="_18" weight="_medium">
                                    {" "}
                                    {textCut(job.jobTitle, 19)}
                                  </AppText>
                                  <br />
                                  <AppText size="_14" color="_c90">
                                    {textCut(job.company.name, 11)} |{" "}
                                    {job.location.split(",")[0]}
                                  </AppText>
                                </div>
                              </div>
                            </Link>

                            {index !== data?.similarJobs.length - 2 && (
                              <div className="_sep_" />
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
              </div>
              <div className="py-3" />
              <div className="_sep_" />
              {/* Implement later */}
              {/* <div className='d-flex justify-content-center py-2'>
              <AppText size='_12' color='_c90' weight='_medium'>
                EXPLORE MORE JOBS
              </AppText>
            </div> */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
