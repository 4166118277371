import React from "react";
import AppText from "./AppText";

export default function AppChip({ children }) {
  return (
    <div className='_chip'>
      <AppText size='_14'>{children}</AppText>
    </div>
  );
}
