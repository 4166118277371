import React, { useState, useEffect, useRef } from "react";
import AppText from "../atoms/AppText";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/styles/makeStyles";
import DropDownArrow from "../atoms/DropDownArrow";

export default function AppDropdown({
  label,
  onChange,
  formik,
  name,
  items = [],
  placeholder,
  searchDisabled = false,
  editable = true,
  defaultValue = "",
}) {
  const [showsDropdown, setShowdropdown] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [itemState, setItemState] = useState([...items]);

  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
      "& :disabled": {
        WebkitTextFillColor: "#3E3F42",
      },
    },
    input: {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      paddingTop: "12px",
      height: "39px",
      paddingBottom: "12px",
      fontFamily: "Roboto",
      color: "#3E3F42",
      border:
        formik?.touched[name] && formik?.errors[name]
          ? "1px solid #E6492D"
          : "1px solid #D8DCE6",
      boxShadow: "0 1px 2px #00000014",
    },
    btn: {
      padding: "0!important",
      "&:hover": {
        background: "none",
      },
      "&:focus": {
        outline: 0,
      },
    },
    drop: {
      background: "#fff",
      border: "1px solid #D8DCE6",
      boxShadow: "0 1px 2px #00000014",
      borderRadius: "4px",
      marginTop: "0.2rem",
      position: "absolute",
      width: "100%",
      display: showsDropdown && editable ? "block" : "none",
      zIndex: "999",
      maxHeight: "240px",
      minHeight: "60px",
      overflowX: "scroll",
    },
  });
  const classes = useSyles();

  const inputDropRef = useRef(null);
  const elRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (
        inputDropRef.current &&
        !inputDropRef.current.contains(e.target) &&
        elRef.current &&
        !elRef.current.contains(e.target)
      ) {
        setShowdropdown(false);
      }
    });
  }, []);

  const handleClick = (value) => {
    setValue(value);
    onChange(value);
    setShowdropdown(false);
  };

  const handleMouseDown = () => {
    setShowdropdown(true);
  };

  const handleTyping = () => {
    const newItems = [...items];
    const matcher = new RegExp(`^${value.toLowerCase()}`, "g");
    const filtered = newItems.filter((word) =>
      word.toLowerCase().match(matcher)
    );
    setItemState(filtered);
  };

  return (
    <div className="position-relative">
      <div className="mb-2">
        <AppText size="_12" color="_c50" weight="_medium">
          {label || "DEFAULT"}
        </AppText>
      </div>
      <div>
        <TextField
          value={value}
          name={name}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          size="small"
          onChange={(e) => setValue(e.target.value)}
          onBlur={formik?.handleBlur(name || "")}
          disabled={searchDisabled}
          onMouseDown={handleMouseDown}
          onKeyUp={handleTyping}
          className={classes.root}
          ref={inputDropRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  className={classes.btn}
                  onClick={() => setShowdropdown(true)}
                >
                  <DropDownArrow />
                </IconButton>
              </InputAdornment>
            ),
            className: classes.input,
          }}
        />
      </div>
      <div className={classes.drop} ref={elRef}>
        {itemState.map((e, i) => (
          <div
            key={i}
            className="py-2 px-3 _opt pointer"
            onClick={() => handleClick(e)}
          >
            <AppText size="_14">{e}</AppText>
          </div>
        ))}
      </div>
      {formik?.touched[name] && formik?.errors[name] && (
        <AppText size="_12" color="_r100">
          {formik?.errors[name]}
        </AppText>
      )}
    </div>
  );
}
