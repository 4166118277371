import { Create, DeleteOutline } from "@material-ui/icons";
import React, { createRef } from "react";
import AppText from "../atoms/AppText";
import SchoolIcon from "../atoms/SchoolIcon";
import Appddown from "../molecules/Appddown";
import Moment from "moment";

export default function EducationBox({
  data,
  index,
  len,
  onDelete,
  onEdit,
  preview,
}) {
  const dropRef = createRef(null);

  const handleEdit = () => {
    dropRef.current.close();
    onEdit();
  };
  const handleDelete = () => {
    dropRef.current.close();
    onDelete();
  };

  return (
    <div className={`d-flex mb-3 pb-3 ${index === len - 1 ? "" : "_bbotm"}`}>
      <SchoolIcon />
      <div className="w-95">
        <div className="d-flex justify-content-start flex-wrap align-items-center">
          <div className=" w-95">
            <AppText size="_18" weight="_medium">
              {data.field}
            </AppText>
          </div>
          {!preview && (
            <Appddown vertical="bottom" horizontal="center" ref={dropRef}>
              <div className="ps-2 pe-5">
                <div
                  className="d-flex align-items-center py-1 pointer"
                  onClick={handleEdit}
                >
                  <Create style={{ marginRight: "1rem", fontSize: "16px" }} />
                  <AppText size="_14">Edit</AppText>
                </div>
                <div
                  className="d-flex align-items-center py-1 pointer"
                  onClick={handleDelete}
                >
                  <DeleteOutline
                    style={{
                      marginRight: "1rem",
                      fontSize: "16px",
                      color: "#E6492D",
                    }}
                  />
                  <AppText size="_14" color="_r100">
                    Delete
                  </AppText>
                </div>
              </div>
            </Appddown>
          )}
          <div className="w-100">
            <AppText size="_14" color="_c50">
              {data.school}
            </AppText>
          </div>

          <div>
            <AppText size="_14" color="_c50">
              {Moment(data.startDate).format("MMM YYYY")} {"- "}
              {data.workHere
                ? "Present"
                : Moment(data.endDate).format("MMM YYYY")}
            </AppText>
          </div>
        </div>
      </div>
    </div>
  );
}
