import React from "react";
// import { Modal } from "react-responsive-modal";
import { useMediaQuery } from "react-responsive";
import AppText from "../atoms/AppText";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Zoom from "@material-ui/core/Zoom";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default function Model({
  isDelete = false,
  children,
  onClose,
  title,
  isModal = true,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const useStyles = makeStyles({
    root: {
      padding: "0px",
      "&:hover": { background: "none" },
      "&:focus": { outline: 0 },
    },
    modal: {
      width:
        isDesktop && isModal
          ? "40%"
          : isTablet && isModal
          ? "60%"
          : (isDesktop || isTablet) && !isModal
          ? "25%"
          : "95%",
      position: "absolute",
      backgroundColor: "#fff",
      marginTop: "4rem",
      borderRadius: "4px",
      maxHeight: isDesktop || isTablet ? "" : "90%",
      overflow: isDesktop || isTablet ? "" : "scroll",
      outline: "none"
    },
    modalContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  return (
    <Modal
      className={classes.modalContainer}
      open={true}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,.50)" } }}
    >
      <Zoom in={true}>
        <div className={classes.modal}>
          {!isDelete ? (
            <>
              <div className="px-4 py-3 d-flex justify-content-between align-items-center">
                <AppText size="_20" weight="_medium">
                  {title}
                </AppText>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  className={classes.root}
                  onClick={onClose}
                >
                  <CloseIcon style={{ color: "#9EA0A5", fontSize: "20px" }} />
                </IconButton>
              </div>
              <div className="_bbotm" />
            </>
          ) : null}

          <div>{children}</div>
        </div>
      </Zoom>
    </Modal>
  );
}
