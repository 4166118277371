import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c55053991529408798715a74b73144c0@o876170.ingest.sentry.io/5831539",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["app.taprecruit.co.uk", /^\//],
      }),
    ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
