const SAVE_BASIC_INFO = "SAVE_BASIC_INFO";

const initialState = {};

const BasicReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BASIC_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const saveBasicInfo = (st) => {
  return {
    type: SAVE_BASIC_INFO,
    payload: st,
  };
};
export default BasicReducer;
