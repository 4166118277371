import React from "react";
import { useMediaQuery } from "react-responsive";
import AppText from "../atoms/AppText";
import ExperienceBox from "../orgnisms/ExperienceBox";
import ExperienceBoxMobile from "../orgnisms/ExperienceBoxMobile";

export default function Experiences({
  experiences,
  editExperience,
  onDeleteExperience,
  refetch,
  preview = false,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });


  function compare(a, b) {
    if (new Date(a.endDate) > new Date(b.endDate)) {
      return -1;
    }
    if (new Date(a.endDate) < new Date(b.endDate)) {
      return 1;
    }
    return 0;
  }
  const sorted = [...experiences].sort(compare);

  return (
    <div>
      <div className='mb-2'>
        <AppText size='_12' color='_c50'>
          WORK EXPERIENCE
        </AppText>
      </div>
      {sorted?.map((e, i) =>
        isDesktop || isTablet ? (
          <ExperienceBox
            preview={preview}
            key={i}
            data={e}
            index={i}
            onDelete={() => onDeleteExperience(e.id)}
            len={experiences.length}
            onEdit={() => (preview ? null : editExperience(e))}
          />
        ) : (
          <ExperienceBoxMobile
            preview={preview}
            key={i}
            data={e}
            index={i}
            onDelete={() => onDeleteExperience(e.id)}
            len={experiences.length}
            onEdit={() => (preview ? null : editExperience(e))}
          />
        )
      )}
    </div>
  );
}
