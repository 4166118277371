import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStylesLoading = makeStyles((theme) => ({
    root: {
        position: 'absolute',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        position: 'absolute',
        left: 0,
    }
}));

const AppCircularProgress = ({value}) => {
    const classes = useStylesLoading();
    return (
        <div>
            <Box position="relative" display="inline-flex" >
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={46}
                    thickness={8}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    disableShrink
                    className={classes.top}
                    size={46}
                    thickness={8}
                    value={value}
                    style={{color: value === 100 ? '#34AA44' : value > 50 ? "#F6AB2F" : "#E6492D"}}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                </Box>
            </Box>
        </div>
    )
}

export default AppCircularProgress