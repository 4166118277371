import AppText from "../atoms/AppText";
import CheckyBox from "../atoms/CheckyBox";

export default function NotificationOption({
  details,
  label,
  checked,
  name,
  onChange,
  disabled,
}) {
  return (
    <div>
      <div className="d-flex align-items-start">
        <CheckyBox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          name={name}
        />
        <span className="ms-2">
          <AppText size="_14">{label}</AppText>
          {details && (
            <AppText size="_11" color="_c90">
              {details}
            </AppText>
          )}
        </span>
      </div>
    </div>
  );
}
