import React, { useState } from "react";
import AppInput from "../molecules/AppInput";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import ModalFooter from "../molecules/ModalFooter";
import AppDateDropdown from "../molecules/AppDateDropdown";
import AppText from "../atoms/AppText";
import Moment from "moment";

const SAVE_EDUCATION = gql`
  mutation saveEducation($input: educationInput) {
    addOrUpdateEducation(input: $input)
  }
`;
export default function EducationModal({ refetch, onClose, data = {} }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [saveEducation] = useMutation(SAVE_EDUCATION);
  const [isLoading, setIsLoading] = useState(false);

  const DEFAULT_DATE = new Date();

  const DEFAULT_STARDATE = data.startDate !== undefined ? new Date(data.startDate) : DEFAULT_DATE;

  const DEFAULT_ENDDATE = data.endDate !== undefined ? new Date(data.endDate) : DEFAULT_DATE;

  const formik = useFormik({
    initialValues: {
      school: data?.school || "",
      degree: data?.degree || "",
      field: data?.field || "",
      startDate: DEFAULT_STARDATE,
      endDate: DEFAULT_ENDDATE,
    },
    validationSchema: Yup.object().shape({
      school: Yup.string().required("School is required"),
      degree: Yup.string().required("Degree is required"),
      field: Yup.string().required("Field of study is required"),
      startDate: Yup.string().required(),
      endDate: Yup.string()
        .required()
        .test("is", "End date can't before start Date", (value) =>
          Moment(formik.values.startDate).isSameOrBefore(value)
        ),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const education = {
        school: values.school,
        degree: values.degree,
        field: values.field,
        startDate: values.startDate,
        endDate: values.endDate,
      };
      if (data) {
        education.id = data.id;
      }

      saveEducation({ variables: { input: education } }).then(() => {
        refetch();
        onClose();
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='px-4 py-3'>
          <div className='d-flex justify-content-between flex-wrap'>
            <div className={isDesktop || isTablet ? "w-48" : "w-100"}>
              <AppInput
                label='SCHOOL'
                placeholder='School Name'
                name='school'
                value={formik.values.school}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={formik.touched.school && formik.errors.school ? true : false}
              />

              {formik.touched.school && formik.errors.school && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.school}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-48" : "w-100 mt-3"}>
              <AppInput
                label='DEGREE'
                placeholder="Degree (Ex: Bachelor's)"
                name='degree'
                value={formik.values.degree}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={formik.touched.degree && formik.errors.degree ? true : false}
              />

              {formik.touched.degree && formik.errors.degree && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.degree}
                </AppText>
              )}
            </div>
            <div className='w-100 mt-3 mb-3'>
              <AppInput
                name='field'
                label='FIELD OF STUDY'
                value={formik.values.field}
                placeholder='Field of study (Ex: Business Admin)'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={formik.touched.field && formik.errors.field ? true : false}
              />
              {formik.touched.field && formik.errors.field && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.field}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-48" : "w-100 mt-3"}>
              <AppDateDropdown
                value={formik.values.startDate}
                label='START DATE'
                onChange={(v) => formik.setFieldValue("startDate", v)}
              />
            </div>
            <div className={isDesktop || isTablet ? "w-48" : "w-100 mt-3"}>
              <AppDateDropdown
                value={formik.values.endDate}
                label='END DATE'
                onChange={(v) => formik.setFieldValue("endDate", v)}
              />
              <div className='pt-1'>
                <AppText size='_12' color='_c50'>
                  Or expected date of graduation
                </AppText>
              </div>
              {formik.touched.endDate && formik.errors.endDate && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.endDate}
                </AppText>
              )}
            </div>
          </div>
        </div>
        <ModalFooter
          onClose={onClose}
          hasErrors={
            !formik.errors.degree && !formik.errors.school && !formik.errors.field ? false : true
          }
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}
