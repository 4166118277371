import Button from "@material-ui/core/Button";
import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";

export default function AppButton({
  children,
  onClick,
  type,
  width = "_half",
  color = "_green",
  ref,
  state = false,
  height = "40px",
  fontColor = "none",
  style = {},
}) {
  const useStyles = makeStyles({
    root: {
      textTransform: "none",
      width:
        width === "_full" ? "100%" : width === "_custom" ? style.width : "auto",
      height,
      borderRadius: "4px",
      color: fontColor,
      border:
        color === "_green"
          ? "1px solid #07BF91"
          : color === "_rc"
          ? "1px solid #E6492D"
          : "1px solid #D8DCE6",
      boxShadow:
        color === "_green" ? "0 1px 1px #161D251A" : "0 1px 2px #00000014",
      background:
        color === "_green"
          ? "#03CA98"
          : color === "_gredient"
          ? "#F6F7F9"
          : color === "_gc"
          ? "#03E0A9"
          : color === "_rc"
          ? "#E6492D"
          : "#fff",
      "&:hover": {
        background:
          color === "_green"
            ? "#03CA98"
            : color === "_gc"
            ? "#03E0A9"
            : color === "_rc"
            ? "#E6492D"
            : "#fff",
      },
      "&:focus": {
        outline: 0,
      },
      "&:disabled": {
        background: color === "_white" ? "#fff" : "default",
      },
    },
  });

  const classes = useStyles();
  return (
    <Button
      variant="contained"
      onClick={onClick}
      type={type}
      disableRipple={true}
      disableElevation
      className={classes.root}
      ref={ref}
      disabled={state}
      style={state ? { border: "1px solid #DCDCDC" } : null}
    >
      {children}
    </Button>
  );
}
