import React from "react";
import { useMutation } from "@apollo/client";
import { StarBorderOutlined, Star } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import { STAR_JOB } from "../../apolloUtils";
import { addFav } from "../../pages/homeStack/HomeDuck";
import AppIconButton from "../atoms/AppIconButton";
import AppText from "../atoms/AppText";
import { processHeadings, textCut, closeDate } from "../../HelperFunctions";
import numbro from "numbro";

export default function JobCardSm({ job, showsStatus = true, page }) {
  const [addToFavourites] = useMutation(STAR_JOB);
  const { user } = useSelector((state) => state.User);
  const { favourites } = useSelector((state) => state.Home);
  const status = useSelector((state) => state.Status);
  const isClosing = closeDate(parseInt(job.closingDate));
  const dispatch = useDispatch();
  const history = useHistory();

  const states = {
    1: "PROCESSING",
    2: "INTERVIEW",
    3: "OFFER",
    4: "UNSUCCESSFUL",
  };

  const didApply = status.find((j) => j.job?.id === job?.id);

  const addTofavs = (e) => {
    e.stopPropagation();
    const { id } = job;

    addToFavourites({ variables: { input: { job: id, candidate: user.id } } });
    dispatch(addFav({ job }));
  };

  const handleClick = () => {
    history.push(`/job/${job.id}`, {job, location: history.location.pathname, page: page});
  };

  return (
    <div
      className="px-4 py-4 mb-2 w-100 bg-white _j_container pointer"
      onClick={handleClick}
    >
      <div className="d-flex mb-3">
        <div>
          <img src={job?.company.logo} alt="js" height="46" width="46" />
        </div>
        <div className="ms-4">
          <AppText size="_18" weight="_medium">
            <span style={{ textTransform: "capitalize" }}>
              {processHeadings(textCut(job?.jobTitle))}
            </span>
          </AppText>

          <div>
            <AppText size="_14" color="_c90">
              <span className="_medium">{job?.company.name}</span> •{" "}
              {job?.location.split(",")[0]}
            </AppText>
          </div>
          <div className={"d-flex flex-column"}>
            {job?.salaryRange?.defaultType !== "hourly" ? (
              <AppText size="_14" weight="_medium" color="_b100">
                £
                {job?.salaryRange?.start !== 0
                  ? job?.salaryRange?.start < 1000
                    ? numbro(job?.salaryRange?.start).format({ mantissa: 2 })
                    : numbro(job?.salaryRange?.start).format({
                      thousandSeparated: true,
                      average: true,
                    })
                  : null}
                {job?.salaryRange?.start !== 0 ? " - £" : ""}
                {job?.salaryRange?.end < 1000
                  ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                  : numbro(job?.salaryRange?.end).format({
                    thousandSeparated: true,
                    average: true,
                  })}
              </AppText>
            ) : (
              <AppText size="_14" weight="_medium" color="_b100">
                £
                {job?.salaryRange?.end < 1000
                  ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                  : numbro(job?.salaryRange?.end).format({
                    thousandSeparated: true,
                    average: true,
                  })}
                /hr
              </AppText>
            )}
            {!isClosing ? null : (
              <span className={"d-flex"}>
                <AppText size="_14" color={"_o100"}>
                  {isClosing}
                </AppText>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="_sep_ mb-3" />
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {didApply && showsStatus ? (
            <span className={`_statetag ${states[didApply?.status]}`}>
              <AppText size="_12" weight="_medium" color="_c0">
                {states[didApply?.status]}
              </AppText>
            </span>
          ) : (
            <>
              {Moment(parseInt(job?.createdAt)).isAfter(
                Moment().subtract(7, "days")
              ) ? (
                <div className="_marker ms-2 d-flex align-items-center justify-content-center">
                  <AppText size="_11" color="_g100">
                    NEW
                  </AppText>
                </div>
              ) : (
                <AppText size="_14" color="_c90">
                  {textCut(Moment(parseInt(job?.createdAt)).fromNow(), 14)}
                </AppText>
              )}
            </>
          )}
        </div>
        <div>
          {favourites.find((el) => el.job.id === job.id) ? (
            <AppIconButton onClick={addTofavs}>
              <Star style={{ fontSize: "17px", color: "#03CA98" }} />
            </AppIconButton>
          ) : (
            <AppIconButton onClick={addTofavs}>
              <StarBorderOutlined
                style={{ fontSize: "17px", color: "#BCBCBC" }}
              />
            </AppIconButton>
          )}
        </div>
      </div>
    </div>
  );
}
