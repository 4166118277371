import React from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import JobCard from "../../../../components/molecules/JobCard";
import AppText from "../../../../components/atoms/AppText";
import JobCardSm from "../../../../components/molecules/JobCardSm";
import NoStatus from "../../../../components/molecules/NoStatus"

export default function Processing() {
  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });

  const status = useSelector((state) => state.Status);

  const processing = status.filter((j) => j.status === 1);

  if (processing.length) {
    return (
      <div className={`grid_gap ${isDesktop ? "c4" : isTablet ? "c2" : isIpadPro ? "c3" : ""}`}>
        {processing.map((j, i) =>
          isDesktop || isTablet || isIpadPro ? (
            <span key={i}>
              <JobCard job={j.job} isGrid={false} />
            </span>
          ) : (
            <JobCardSm job={j.job} key={i} />
          )
        )}
        {processing.length === 0 ? <AppText>No jobs in process</AppText> : null}
      </div>
    );
  }
  else {
    return (
      <NoStatus />
    )
  }
}
