const SET_STATE = "SET_STATE";
const VERIFY_EMAIL = "VERIFY_EMAIL";
const SET_STEP = "SET_STEP";

const initialState = {
  authStateChanged: false,
  emailVerified: false,
  points: 0,
  step: 1,
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case VERIFY_EMAIL:
      return { ...state, emailVerified: true };
    case SET_STEP:
      return { ...state, step: action.payload };
    default:
      return state;
  }
};

export const setAuthState = (st) => {
  return {
    type: SET_STATE,
    payload: st,
  };
};
export const verifyEmail = () => {
  return {
    type: VERIFY_EMAIL,
  };
};
export const setStep = (s) => {
  return {
    type: SET_STEP,
    payload: s,
  };
};
export default AppReducer;
