import React from "react";
import { useMediaQuery } from "react-responsive";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles";
import AppText from "../atoms/AppText";

export default function AppInput({
  label,
  onChange,
  value,
  name,
  onBlur,
  placeholder,
  editable = true,
  onKeyUp,
  hasError = false,
  fontSize = 16,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      backgroundColor: "#fff",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input: {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      fontFamily: "Roboto",
      marginTop: "-2px",
      height: "16.4px",
      color:
        name === "email" && !editable && (isDesktop || isTablet)
          ? "#ddd"
          : "#3E3F42",
      border: hasError ? "1px solid #E6492D" : "1px solid #D8DCE6",
      borderRadius: "4px",
      fontSize: fontSize,
    },
  });
  const classes = useSyles();
  return (
    <div>
      {label !== undefined && (
        <div className={classes.label}>
          <AppText size="_12" color="_c50" weight="_medium">
            {label}
          </AppText>
        </div>
      )}

      <div>
        <TextField
          value={value}
          name={name}
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          size="small"
          disabled={!editable}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.root}
          onKeyUp={onKeyUp}
          inputProps={{
            className: classes.input,
          }}
        />
      </div>
    </div>
  );
}
