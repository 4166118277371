export const countries = ["United Kingdom"];
export const cities = [
  "Aberdeen",
  "Armagh",
  "Ashford",
  "Atherton",
  "Aylesbury",
  "Bamber Bridge",
  "Bangor",
  "Bangor (NI)",
  "Barnsley",
  "Basildon",
  "Basingstoke",
  "Bath",
  "Batley",
  "Bedford",
  "Belfast",
  "Birkenhead",
  "Birmingham",
  "Blackburn",
  "Blackpool",
  "Bognor Regis",
  "Bolton",
  "Bournemouth",
  "Bracknell",
  "Bradford",
  "Brighton and Hove",
  "Bristol",
  "Burnley",
  "Burton upon Trent",
  "Bury",
  "Cambridge",
  "Cannock",
  "Canterbury",
  "Cardiff",
  "Carlisle",
  "Chatham",
  "Chelmsford",
  "Cheltenham",
  "Chester",
  "Chesterfield",
  "Chichester",
  "Colchester",
  "Coventry",
  "Craigavon",
  "Crawley",
  "Crewe",
  "Darlington",
  "Derby",
  "Dewsbury",
  "Doncaster",
  "Dudley",
  "Dundee",
  "Durham",
  "East Kilbride",
  "Eastbourne",
  "Eastleigh",
  "Edinburgh",
  "Ellesmere Port",
  "Ely",
  "Exeter",
  "Farnborough",
  "Gateshead",
  "Gillingham",
  "Glasgow",
  "Gloucester",
  "Gosport",
  "Grays",
  "Grimsby",
  "Guildford",
  "Halifax",
  "Harlow",
  "Harrogate",
  "Hartlepool",
  "Hastings",
  "Hemel Hempstead",
  "Hereford",
  "High Wycombe",
  "Huddersfield",
  "Hull",
  "Inverness",
  "Ipswich",
  "Lancaster",
  "Leeds",
  "Leicester",
  "Lichfield",
  "Lincoln",
  "Lisburn",
  "Liverpool",
  "London",
  "Londonderry",
  "Lowestoft",
  "Luton",
  "Maidenhead",
  "Maidstone",
  "Manchester",
  "Mansfield",
  "Margate",
  "Middlesbrough",
  "Milton Keynes",
  "Newcastle-under-Lyme",
  "Newport",
  "Newry",
  "Newtownabbey",
  "Northampton",
  "Norwich",
  "Nottingham",
  "Nuneaton",
  "Oldham",
  "Oxford",
  "Paignton",
  "Paisley",
  "Perth",
  "Peterborough",
  "Plymouth",
  "Poole",
  "Portsmouth",
  "Preston",
  "Rayleigh",
  "Reading",
  "Redditch",
  "Ripon",
  "Rochdale",
  "Rochester",
  "Rotherham",
  "Rugby",
  "Runcorn",
  "Sale",
  "Salford",
  "Salisbury",
  "Scarborough",
  "Scunthorpe",
  "Sheffield",
  "Shrewsbury",
  "Slough",
  "Solihull",
  "South Shields",
  "Southampton",
  "Southend",
  "Southport",
  "St. Albans",
  "St. Asaph",
  "St. Davids",
  "St. Helens",
  "Stafford",
  "Stevenage",
  "Stirling",
  "Stockport",
  "Stockton-on-Tees",
  "Stoke",
  "Stourbridge",
  "Sunderland",
  "Sutton Coldfield",
  "Swansea",
  "Swindon",
  "Tamworth",
  "Taunton",
  "Telford",
  "Truro",
  "Tynemouth",
  "Wakefield",
  "Wallasey",
  "Walsall",
  "Walton-on-Thames",
  "Warrington",
  "Washington",
  "Watford",
  "Wells",
  "West Bromwich",
  "Westminster",
  "Weston-Super-Mare",
  "Widnes",
  "Wigan",
  "Winchester",
  "Woking",
  "Wolverhampton",
  "Worcester",
  "Worthing",
  "Wrexham",
  "York",
];
