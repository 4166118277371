import React from "react";
import AppSearchDropdown from "../molecules/AppSearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  addSkill,
  deleteSkill,
} from "../../pages/buildYourProfile/templates/Ducks/SkillDucks";

import AppText from "../atoms/AppText";
import ChipButton from "../molecules/ChipButton";
import ModalFooter from "../molecules/ModalFooter";
import { allSkills } from "../../pages/buildYourProfile/data/skills";

export default function SkillModal({ onClose }) {
  const dispatch = useDispatch();
  const skills = useSelector((state) => state.Skill);

  const handleChange = (data) => {
    switch (data.value) {
      case "General":
        dispatch(addSkill({ label: data.label, value: "general" }));
        break;
      case "Tools":
        dispatch(
          addSkill({ label: data.label, value: "toolsAndTechnologies" })
        );
        break;
      case "Soft skills":
        dispatch(addSkill({ label: data.label, value: "softSkills" }));
        break;
      case "others":
        dispatch(addSkill({ label: data.label, value: "others" }));
        break;
      default:
        return null;
    }
  };

  const onRemoveSkill = (obj) => {
    dispatch(deleteSkill(obj));
  };

  const onSave = () => {
    onClose();
  };

  const general = skills.filter((el) => el.value === "general");
  const softSkills = skills.filter((el) => el.value === "softSkills");
  const toolsAndTechnologies = skills.filter(
    (el) => el.value === "toolsAndTechnologies"
  );
  const others = skills.filter((el) => el.value === "others");

  return (
    <div>
      <div className="px-4 py-3">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="w-100">
            <AppSearchDropdown
              label="SKILL"
              name="skill"
              items={allSkills}
              onChange={(data) => handleChange(data)}
            />
          </div>
        </div>
        <div className="mt-2">
          {general.length > 0 ? (
            <div className="mb-2">
              <AppText size="_14" color="_c90">
                General
              </AppText>
            </div>
          ) : null}

          {general.map((g, i) => (
            <ChipButton
              label={g.label}
              key={i}
              onClick={() => onRemoveSkill(g)}
            />
          ))}
          {toolsAndTechnologies.length > 0 ? (
            <div className="mb-2">
              <AppText size="_14" color="_c90">
                Tools & Technologies
              </AppText>
            </div>
          ) : null}

          {toolsAndTechnologies.map((t, i) => (
            <ChipButton
              label={t.label}
              key={i}
              onClick={() => onRemoveSkill(t)}
            />
          ))}
          {softSkills.length > 0 ? (
            <div className="mb-2">
              <AppText size="_14" color="_c90">
                Soft Skills
              </AppText>
            </div>
          ) : null}
          {softSkills.map((s, i) => (
            <ChipButton
              label={s.label}
              key={i}
              onClick={() => onRemoveSkill(s)}
            />
          ))}
          {others.length > 0 ? (
            <div className="mb-2">
              <AppText size="_14" color="_c90">
                Others
              </AppText>
            </div>
          ) : null}
          {others.map((s, i) => (
            <ChipButton
              label={s.label}
              key={i}
              onClick={() => onRemoveSkill(s)}
            />
          ))}
        </div>
      </div>
      <ModalFooter
        onClose={onClose}
        isLoading={false}
        onSave={onSave}
        hasErrors={
          general.length === 0 &&
          softSkills.length === 0 &&
          toolsAndTechnologies.length === 0
            ? true
            : false
        }
      />
    </div>
  );
}
