import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import AppText from "../../../../components/atoms/AppText";
import JobCard from "../../../../components/molecules/JobCard";
import JobCardSm from "../../../../components/molecules/JobCardSm";
import NoStatus from "../../../../components/molecules/NoStatus"

export default function All() {
  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });

  const status = useSelector((state) => state.Status);
  if (status.length) {
    return (
      <div className={`grid_gap ${isDesktop ? "c4" : isTablet ? "c2" : isIpadPro ? "c3" : ""}`}>
        {status.map(({ job }) =>
          isDesktop || isTablet || isIpadPro ? (
            <span key={job?.id}>
              <JobCard job={job} isGrid={true} />
            </span>
          ) : (
            <JobCardSm job={job} key={job?.id} />
          )
        )}
        {status.length === 0 ? <AppText>No job statuses</AppText> : null}
      </div>
    );
  }
  else {
    return (
      <NoStatus />
    )
  }

}
