import React, { useState, useRef, useCallback } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useMutation } from "@apollo/client";
import { uid } from "uid";
import { useDispatch, useSelector } from "react-redux";
import ModalFooter from "../molecules/ModalFooter";
import { updatePhoto } from "../../redux/userDucks";
import { SAVE_PROFILE_IMAGE } from "../../apolloUtils";
import FirebaseApp, { fire } from "../../firebase";

export default function ImageUploadModal({ temp, onClose }) {
  const [crop, setCrop] = useState({ width: 200, height: 200, unit: "px" });
  const [completedCrop, setCompletedCrop] = useState(null);
  const { user } = useSelector((state) => state.User);

  const [saveProfileImage] = useMutation(SAVE_PROFILE_IMAGE);
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const imageRef = useRef(null);

  const onSave = async () => {
    const croppedImg = await getCroppedImg(imageRef, completedCrop, "fileName");

    setLoading(true);

    const storageRef = FirebaseApp.storage().ref();

    const profileRef = storageRef.child(`profiles/${uid(10)}.jpg`);

    profileRef.put(croppedImg).then((snap) => {
      snap.ref.getDownloadURL().then((downloadUrl) => {
        saveProfileImage({
          variables: {
            input: {
              photoUrl: downloadUrl,
              userId: user.id || fire.auth().currentUser,
            },
          },
        }).then(({ data }) => {
          dispatch(updatePhoto(data.updateUser.photoUrl));
          setLoading(false);
          onClose();
        });
      });
    });
  };

  const onLoad = useCallback((img) => {
    imageRef.current = img;
  }, []);

  function getCroppedImg(image, crop) {
    const Image = image.current;

    const canvas = document.createElement("canvas");
    const scaleX = Image.naturalWidth / Image.width;
    const scaleY = Image.naturalHeight / Image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      Image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  return (
    <div>
      <div className="d-flex justify-content-center" style={{ maxHeight: 459 }}>
        <ReactCrop
          imageStyle={{ maxHeight: 459 }}
          src={temp}
          crop={crop}
          onImageLoaded={onLoad}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </div>
      <ModalFooter
        onClose={onClose}
        isLoading={isLoading}
        hasErrors={false}
        onSave={onSave}
      />
    </div>
  );
}
