import React from "react";
import AppText from '../atoms/AppText'
import AppButton from "../molecules/AppButton";
import { getWindowDimensions } from "../../HelperFunctions/index";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

export default function NoFavorites() {
    const screenSize = getWindowDimensions()
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const history = useHistory()

    return (
        <div className='d-flex justify-content-center w-100 align-items-center' style={{ height: screenSize.height - 150 }}>
            <div className={`d-flex flex-column align-items-center ${isMobile ? 'w-70' : 'w-40'}`}>
                <img alt={"message"} src='/Group-2127.svg' className={'mb-5'} height={'142'} width={'190'}></img>
                <AppText size='_18' weight='_medium' color={'_c100'}>
                    No favourite jobs
                </AppText>
                <span className={'d-flex mb-4'}>
                    <AppText size='_16' color={'_c50'} align={'_center'}>
                        You have not saved any job posts. Find a job and tap the ☆ icon to add it to your favorites.
                        
                    </AppText>
                </span>
                <AppButton width='_full' type='submit' onClick={() => { history.push('/') }}>
                    <AppText color='_c0' size='_14'>
                        Find a Job
                    </AppText>
                </AppButton>
            </div>
        </div>
    )
}