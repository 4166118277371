import React from "react";
import EducationBox from "../orgnisms/EducationBox";
import AppText from "../atoms/AppText";

export default function Educations({
  educations,
  editEducation,
  onDeleteEducation,
  preview = false,
}) {
  

  function compare(a, b) {
    if (new Date(a.endDate) > new Date(b.endDate)) {
      return -1;
    }
    if (new Date(a.endDate) < new Date(b.endDate)) {
      return 1;
    }
    return 0;
  }

  return (
    <div>
      <div className='mb-2'>
        <AppText size='_12' color='_c50'>
          EDUCATION
        </AppText>
      </div>
      {educations?.sort(compare).map((e, i) => (
        <EducationBox
          preview={preview}
          key={i}
          index={i}
          len={educations.length}
          data={e}
          onDelete={() => onDeleteEducation(e.id)}
          onEdit={() => editEducation(e)}
        />
      ))}
    </div>
  );
}
