import { Close } from "@material-ui/icons";
import React from "react";
import AppIconButton from "./AppIconButton";
import AppText from "./AppText";

export default function FilterChip({ onClick, children, isDefalut = false }) {
  return (
    <div className="_filter_chip px-2 py-1 me-2">
      <AppText size="_14">{children}</AppText>
      {!isDefalut && (
        <AppIconButton onClick={onClick}>
          <Close style={{ fontSize: "15px", marginLeft: "0.5rem" }} />
        </AppIconButton>
      )}
    </div>
  );
}
