import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation, gql } from "@apollo/client";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import { useFormik } from "formik";
import EducationTemplate from "../../../components/templates/EducationTemplate";
import { setStep } from "../../../redux/appDucks";
import { useSelector } from "react-redux";

const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($input: editUserInput) {
    updateUser(input: $input) {
      fullName
    }
  }
`;

export default function Education({ state }) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [updateUser] = useMutation(UPDATE_USER_INFO);
  const { user } = useSelector((state) => state.User);

  const moveBack = () => {
    history.goBack();
  };
  useEffect(() => {
    if (state !== "preview") {
      dispatch(setStep(3));
    }
  }, [dispatch, state]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      updateUser({ variables: { input: { accountStage: 3, userId: user.id } } });
      setIsLoading(true);
      history.push("/build-your-profile/skills");
      setIsLoading(false);
    },
  });

  return (
    <div>
      <BoardingPanel
        heading="Education"
        subHeading="Your school and course of study"
        onBack={moveBack}
        onSubmit={formik.handleSubmit}
        state={state}
        isLoading={isLoading}
      >
        <div>
          <EducationTemplate />
        </div>
      </BoardingPanel>
    </div>
  );
}
