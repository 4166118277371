import React, { useState } from "react";
import AppText from "../atoms/AppText";
import DropDownArrow from "../atoms/DropDownArrow";
import OutSide from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { changeToSort } from "../templates/SortDucks";

export default function AppDropDownButton({ sortOption }) {
  const sortOptions = ["Highest paid", "Most recent"];
  const [showDrop, setShowDrop] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="position-relative">
      <OutSide onOutsideClick={() => setShowDrop(false)}>
        <div
          onClick={() => setShowDrop(true)}
          className="_gbtn_container px-3 d-flex align-items-center pointer"
        >
          <AppText size="_14" color="_c90">
            <span className="d-flex align-items-center">
              Sort:{" "}
              <span className="ms-1 me-2" style={{ color: "#3E3F42" }}>
                {sortOption}
              </span>
              <DropDownArrow />
            </span>
          </AppText>
        </div>

        {showDrop && (
          <div className="position-absolute _droper">
            {sortOptions.map((option, index) => (
              <div
                className="_drop_item"
                key={index}
                onClick={() => {
                  dispatch(changeToSort(option));
                  setShowDrop(false);
                }}
              >
                <AppText size="_14">{option}</AppText>
              </div>
            ))}
          </div>
        )}
      </OutSide>
    </div>
  );
}
