import Button from "@material-ui/core/Button";
import React from "react";
import AppText from "../atoms/AppText";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    borderRadius: "4px",
    background: "#FBFBFD",
    border: "1px solid #EAEDF3",
    height: "30px",
    marginRight: "0.522rem",
    marginBottom: "0.522rem",
    "&:hover": {
      background: "#FBFBFD",
    },
    "&:focus": {
      outline: 0,
    },
  },
});

export default function ChipButton({ onClick, label }) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      disableElevation
      disableFocusRipple
      disableRipple
      className={classes.root}
    >
      <div className="d-flex align-items-center">
        <AppText size="_14">{label}</AppText>
        <span onClick={onClick} className="ms-3">
          <CloseIcon style={{ fontSize: "18px", color: "#BCBCBC" }} />
        </span>
      </div>
    </Button>
  );
}
