import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import NoStatus from "../../../../components/molecules/NoStatus"
import JobCard from "../../../../components/molecules/JobCard";
import JobCardSm from "../../../../components/molecules/JobCardSm";

export default function Interview() {
  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });

  const status = useSelector((state) => state.Status);

  const interview = status.filter((j) => j.status === 2);

  return (
    <div>
      <div className={`grid_gap ${isDesktop ? "c4" : isTablet ? "c2" : isIpadPro ? "c3" : ""}`}>
        {interview.map((j, i) =>
          isDesktop || isTablet || isIpadPro ? (
            <span key={i}>
              <JobCard job={j.job} isGrid={false} />
            </span>
          ) : (
              <JobCardSm job={j.job} key={i} />
            )
        )}

      </div>
      {interview.length === 0 && <div className="w-100"><NoStatus /></div>}
    </div>

  );
}
