import React, { useState, useEffect, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import Collapse from "@material-ui/core/Collapse";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useMutation, gql } from "@apollo/client";
import AppText from "../atoms/AppText";
import DropDownArrow from "../atoms/DropDownArrow";
import {
  randomColor,
  returnInitals,
  UpperCaseFirst,
} from "../../HelperFunctions";
import { fire } from "../../firebase";
import NotificationCard from "../molecules/NotificationCard";
import NoNotifications from "../molecules/NoNotifications";
import { clearAlert } from "../../redux/userDucks";
import { logout } from "../../HelperFunctions";
import { SIGNOUT } from "../../apolloUtils";

const UPDATE_NOTIFICATION_LAST = gql`
  mutation ($input: LastSeenInput) {
    seekerUpdateLastSeen(input: $input)
  }
`;

const MARK_NOTIFICATION = gql`
  mutation ($input: MarkReadInput) {
    markNotificationsRead(input: $input)
  }
`;

export default function AppHeader() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [markNotificationsRead] = useMutation(MARK_NOTIFICATION);
  const [signOutUser] = useMutation(SIGNOUT);

  const [drop, showDrop] = useState(false);
  const [notifications_, showNotifications_] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("/");
  const {
    user,
    unreadNotications,
    unseenStatusNofications,
    allNotifications,
    strength,
  } = useSelector((state) => state.User);
  const { unread } = useSelector((state) => state.Chats);
  const [menuOpen, setMenuOpen] = useState(false);

  const [updateLastSeen] = useMutation(UPDATE_NOTIFICATION_LAST);

  const dropRef = useRef(null);
  const buttonRef = useRef(null);

  const dispatch = useDispatch();

  const routes = [
    { name: "Find Jobs", path: "/", notifications: 0 },
    { name: "Favourites", path: "/favourites", notifications: 0 },
    {
      name: "Messages",
      path: "/chat",
      notifications: unread.length,
    },
    {
      name: "Status",
      path: "/status",
      notifications: unseenStatusNofications.length,
    },
  ];

  const { pathname } = useLocation();

  useEffect(() => {
    setCurrentRoute(pathname);
  }, [pathname]);

  const useStyles = makeStyles({
    root: {
      textTransform: "none",
      borderRadius: 0,
      paddingRight: "0!important",
      maxHeight: "50px!important",
      minHeight: "50px!important",
      paddingLeft: "1.6rem",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },

      width: "157px",
    },
    smbtn: {
      textTransform: "none",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      borderRadius: 0,
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    avatar: {
      height: "33px",
      width: "33px",
      marginRight: "10px",
      backgroundColor: randomColor(user.fullName),
    },
    dropAvatar: {
      height: "70px",
      width: "70px",
      backgroundColor: randomColor(user.fullName),
    },
    icon: {
      fontSize: "18px",
      color: "#9EA0A5",
    },
    icon1: {
      fontSize: "18px",
      color: "#BCBCBC",
    },
    icon2: {
      fontSize: "19px",
      color: "#BCBCBC",
    },
    iconsBtn: {
      padding: "0px!important",
      paddingRight: "12px!important",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    btn: {
      height: "45px",
      width: "100%",
      backgroundColor: "#FBFBFD",
      borderRadius: "0 0 4px 4px",
    },
    badge: {
      position: "absolute",
      right: isDesktop ? "-13px" : "0px",
      top: "50%",
      background: "#E6492D",
    },
    badge2: {
      background: "#E6492D",
    },
    drawer: {
      "& .MuiDrawer-paper": {
        top: "50px",
      },
    },
    rightDrawer: {
      "& .MuiDrawer-paper": {
        top: "50px",
        width: isDesktop ? "28%" : isTablet ? "40%" : "80%",
        border: "1px solid #EAEDF3",
        borderTop: "none",
        boxShadow: "0 16px 32px #0000000F",
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        dropRef.current &&
        !dropRef.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        showDrop(false);
      }
    });
  }, []);

  useEffect(() => {
    if (notifications_) {
      markNotificationsRead({ variables: { input: { manager: user.id } } });
    }
  }, [user.id, markNotificationsRead, notifications_]);

  const handleNotificationClose = () => {
    if (!notifications_) {
      showNotifications_(true);
    } else {
      showNotifications_(false);
      if (notifications_) {
        updateLastSeen({
          variables: {
            input: { userId: user.id, notificationsLastSeen: true },
          },
        });
        dispatch(clearAlert({ unreadNotications: [] }));
      }
    }
  };

  const routeName = routes.filter((r) => r.path === currentRoute);

  return (
    <div
      className={`header position-fixed _fixed ${
        isDesktop || isTablet ? "px-4" : "ps-4"
      } d-flex align-items-center justify-content-between`}
    >
      <div className="h-100 d-flex align-items-center">
        <Link to={"/"}>
          <img alt="logo" src="/logo.png" height="32" width="32" />
        </Link>
        <div className="h-75 line ms-3" />
        {!isDesktop && (
          <span className="ms-3">
            <AppText size="_16" weight="_medium">
              {routeName[0]?.name}
            </AppText>
          </span>
        )}

        {isDesktop ? (
          <div className="_routers h-100 ms-5 ps-5 h-100 d-flex align-items-center">
            {routes.map((route) => (
              <Link key={route.name} to={route.path}>
                <span className="me-5">
                  <Badge
                    classes={{ badge: classes.badge }}
                    badgeContent={route.notifications}
                    color="secondary"
                    max={9}
                  >
                    <AppText
                      size="_14"
                      weight={currentRoute === route.path ? "_medium" : ""}
                      color={currentRoute === route.path ? "_g90" : ""}
                    >
                      {route.name}
                    </AppText>
                  </Badge>
                </span>
              </Link>
            ))}
          </div>
        ) : null}
      </div>
      <div className="h-100">
        <div className="h-100 d-flex align-items-center">
          <div className="me-2 h-100 d-flex align-items-center">
            {/* <IconButton className={classes.iconsBtn} disableFocusRipple disableTouchRipple>
              <CalendarToday className={classes.icon1} />
            </IconButton> */}

            <div className={isDesktop || isTablet ? "position-relative" : ""}>
              <IconButton
                onClick={handleNotificationClose}
                className={classes.iconsBtn}
                disableFocusRipple
                disableTouchRipple
              >
                <Badge
                  classes={{ badge: classes.badge2 }}
                  badgeContent={unreadNotications.length}
                  color="secondary"
                >
                  <NotificationsNone className={classes.icon2} />
                </Badge>
              </IconButton>

              <Drawer
                anchor="right"
                variant="persistent"
                className={classes.rightDrawer}
                open={notifications_}
                onClose={() => showNotifications_(false)}
                children={
                  <div>
                    <div className="p-3 d-flex justify-content-between">
                      <div>
                        <AppText>Notifications</AppText>
                        {unreadNotications.length !== 0 && (
                          <span className="ms-2 _danger_b">
                            <AppText size="_14" color="_c0" weight="_bold">
                              {unreadNotications.length} NEW
                            </AppText>
                          </span>
                        )}
                      </div>
                      <div>
                        <IconButton
                          onClick={handleNotificationClose}
                          className={classes.iconsBtn}
                          disableFocusRipple
                          disableTouchRipple
                        >
                          <Close className={classes.icon1} />
                        </IconButton>
                      </div>
                    </div>
                    <div className="_sep_" />
                    <div className="_notification_area">
                      {[...allNotifications]
                        .sort((a, b) =>
                          a.sentAt > b.sentAt ? -1 : a.sentAt < b.sentAt ? 1 : 0
                        )
                        .map((notification, i) => {
                          if (i < 15) {
                            return (
                              <div
                                key={i}
                                className={`${i === 14 ? "mb-5" : ""}`}
                              >
                                <NotificationCard
                                  notification={notification}
                                  onClick={handleNotificationClose}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      {allNotifications.length === 0 ? (
                        <NoNotifications />
                      ) : (
                        <div
                          className="boto"
                          style={{
                            width: isDesktop ? "28%" : isTablet ? "40%" : "80%",
                          }}
                        >
                          <Link
                            className="h-100"
                            to="/notifications"
                            onClick={handleNotificationClose}
                          >
                            <div className="pointer h-100 d-flex justify-content-center align-items-center">
                              <AppText size="_14" weight="_medium">
                                All Notifications
                              </AppText>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div className="h-100 line" />
          {isDesktop ? (
            <Button
              className={classes.root}
              disableFocusRipple
              disableTouchRipple
              onClick={() => showDrop(!drop)}
              ref={buttonRef}
            >
              <Avatar
                sizes="small"
                className={classes.avatar}
                src={user.photoUrl || null}
              >
                <AppText size="_14" color="_c0">
                  {returnInitals(
                    `${UpperCaseFirst(user.firstName)} ${UpperCaseFirst(
                      user.lastName
                    )}`
                  )}
                </AppText>
              </Avatar>
              <div className="me-4">
                <AppText size="_14">{UpperCaseFirst(user.firstName)}</AppText>
              </div>
              <DropDownArrow />
            </Button>
          ) : null}
          {!isDesktop && (
            <React.Fragment>
              <IconButton
                className={classes.smbtn}
                disableFocusRipple
                disableTouchRipple
                onClick={() => setMenuOpen(!menuOpen)}
                ref={buttonRef}
              >
                <Menu className={classes.icon2} />
              </IconButton>
              <SwipeableDrawer
                className={classes.drawer}
                anchor="left"
                variant="persistent"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => setMenuOpen(true)}
              >
                <div style={{ width: isTablet ? "375px" : "100vw" }}>
                  <div className="p-4 d-flex align-items-center">
                    <div>
                      <Avatar
                        style={{
                          height: "56px",
                          width: "56px",
                          backgroundColor: randomColor(user.fullName),
                        }}
                        src={user.photoUrl || null}
                      >
                        {returnInitals(
                          `${UpperCaseFirst(user.firstName)} ${UpperCaseFirst(
                            user.lastName
                          )}`
                        )}
                      </Avatar>
                    </div>
                    <div className="ms-3 ">
                      <AppText size="_16" weight="_medium" align="_center">
                        {`${UpperCaseFirst(user.firstName)} ${UpperCaseFirst(
                          user.lastName
                        )}`}
                      </AppText>
                      <div>
                        {/* <AppText size='_14' color='_c90' align='_center'>
                          Software Engineer
                        </AppText> */}
                      </div>
                    </div>
                  </div>
                  <div className="_sep_" />
                  <div className="d-flex flex-column pt-4">
                    <div className="ps-4">
                      <AppText size="_14" weight={"_medium"} color={"_c90"}>
                        MENU
                      </AppText>
                    </div>

                    <div className="mb-2" />
                    {routes.map((route) => (
                      <Link
                        key={route.name}
                        to={route.path}
                        onClick={() => setMenuOpen(false)}
                        className={`py-2 px-4 ${
                          currentRoute === route.path ? "_sele_" : ""
                        }`}
                      >
                        <span className="d-flex justify-content-between">
                          <AppText
                            size="_14"
                            weight={
                              currentRoute === route.path ? "_medium" : ""
                            }
                            color={currentRoute === route.path ? "_g90" : ""}
                          >
                            {route.name}
                          </AppText>
                          <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={route.notifications}
                            color="secondary"
                            max={9}
                          />
                        </span>
                      </Link>
                    ))}
                  </div>
                  <div className="_sep_ my-3" />
                  <div className="py-3">
                    <div className="px-4">
                      <AppText size="_12" color="_c50">
                        YOUR DETAILS
                      </AppText>
                    </div>
                    {/* <div className='mt-1 px-4'>
                      <AppText size='_14'>Basic Information</AppText>
                    </div> */}
                    <Link to="/profile" onClick={() => setMenuOpen(false)}>
                      <div className="_lists px-4">
                        <div className="mt-2">
                          <AppText size="_14">My Profile</AppText>
                        </div>
                        <AppText
                          size="_12"
                          color={
                            strength === 100
                              ? "_g100"
                              : strength > 45
                              ? "_o100"
                              : "_r100"
                          }
                        >
                          Profile Strength {strength}%
                        </AppText>
                      </div>
                    </Link>
                    <div className="mt-2" />
                    <div className="px-4 mt-2">
                      <HashLink
                        to="/profile#password"
                        onClick={() => setMenuOpen(false)}
                      >
                        <AppText size="_14">Password</AppText>
                      </HashLink>
                    </div>
                    <div className="mt-3" />
                    <div className="_sep_" />
                    {/* <div className='px-4 pt-3'>
                      <AppText size='_12' color='_c50'>
                        SETTING
                      </AppText>
                    </div> */}
                    {/* <div
                      className='px-4 pointer'

                    >
                      <Link to='/profile' onClick={() => setMenuOpen(false)}>
                        <div className='mt-2'>
                          <AppText size='_14'>Settings & Notifications</AppText>
                        </div>
                      </Link>

                    </div>
                    <div className='mt-3' /> */}
                    <div className="_sep_" />
                    <div className="px-4 pt-3">
                      <AppText size="_12" color="_c50">
                        HELP & SUPPORT
                      </AppText>
                    </div>
                    <div
                      className="_lists px-4 pointer"
                      onClick={() =>
                        window.open("https://www.taprecruit.co.uk", "_blank")
                      }
                    >
                      <div className="mt-2">
                        <AppText size="_14">Contact Support</AppText>
                      </div>
                    </div>
                  </div>

                  <div className="_sep_ my-3 " />
                  <div>
                    <Button
                      className={classes.btn}
                      onClick={() => {
                        signOutUser({
                          variables: { id: fire.auth().currentUser.uid },
                        }).then(() => {
                          logout(fire);
                        });
                      }}
                    >
                      <AppText size="_12" color="_r100">
                        LOG OUT
                      </AppText>
                    </Button>
                  </div>
                  <div className=" py-5 " />
                </div>
              </SwipeableDrawer>
            </React.Fragment>
          )}
        </div>
        {drop && (
          <Collapse in={drop}>
            <div className="position-absolute _menu_drop" ref={dropRef}>
              <div className="py-3 d-flex flex-column justify-content-center align-items-center">
                <div>
                  <Avatar
                    className={classes.dropAvatar}
                    src={user.photoUrl || null}
                  >
                    {returnInitals(
                      `${UpperCaseFirst(user.firstName)} ${UpperCaseFirst(
                        user.lastName
                      )}`
                    )}
                  </Avatar>
                </div>
                <div className="mt-3 d-flex flex-column justify-content-center ">
                  <AppText size="_16" weight="_medium" align="_center">
                    {`${UpperCaseFirst(user.firstName)} ${UpperCaseFirst(
                      user.lastName
                    )}`}
                  </AppText>
                  <div>
                    {/* <AppText size='_14' color='_c90' align='_center'>
                    Software Engineer
                  </AppText> */}
                  </div>
                </div>
              </div>
              <div className="_sep_" />
              <div className="py-3">
                <div className="px-4">
                  <AppText size="_12" color="_c50">
                    YOUR ACCOUNT
                  </AppText>
                </div>
                {/* <div className='mt-1 px-4'>
                <AppText size='_14'>Basic Information</AppText>
              </div> */}
                <Link to="/profile" onClick={() => showDrop(false)}>
                  <div className="_lists px-4 mt-2">
                    <div>
                      <AppText size="_14">My Profile</AppText>
                    </div>
                    <AppText
                      size="_12"
                      // color={
                      //   strength === 100
                      //     ? "_g100"
                      //     : strength > 45
                      //     ? "_o100"
                      //     : "_r100"
                      // }
                      color={"_g100"}
                    >
                      Profile Strength 100%
                    </AppText>
                  </div>
                </Link>

                <div className="mt-2" />
                <div className="_lists px-4 mt-2">
                  <HashLink
                    to="/profile#password"
                    onClick={() => showDrop(false)}
                  >
                    <AppText size="_14">Password</AppText>
                  </HashLink>
                </div>
                <div className="mt-3" />
                <div className="_sep_" />
                {/* <div className='px-4 pt-3'>
                  <AppText size='_12' color='_c50'>
                    SETTING
                  </AppText>
                </div> */}
                {/* <div
                  className='_lists px-4 pointer'
                >
                  <HashLink to='/profile#password' onClick={() => showDrop(false)}>
                    <div className='mt-2'>
                      <AppText size='_14'>Notifications</AppText>
                    </div>
                  </HashLink>
                </div> */}
                {/* <div className='mt-3' /> */}
                <div className="_sep_" />
                <div className="px-4 pt-3">
                  <AppText size="_12" color="_c50">
                    HELP & SUPPORT
                  </AppText>
                </div>
                <div
                  className="_lists px-4 pointer"
                  onClick={() =>
                    window.open("https://www.taprecruit.co.uk", "_blank")
                  }
                >
                  <div className="mt-2">
                    <AppText size="_14">Contact Support</AppText>
                  </div>
                </div>
              </div>
              <div className="_sep_" />
              <Button
                className={classes.btn}
                onClick={() => {
                  signOutUser({
                    variables: { id: fire.auth().currentUser.uid },
                  }).then(() => {
                    logout(fire);
                  });
                }}
              >
                <AppText size="_12" color="_r100">
                  LOG OUT
                </AppText>
              </Button>
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
}
