import { createStore, combineReducers } from "redux";
import AppAuthReducer from "../pages/AuthDucks";
import AppReducer from "./appDucks";
import FilterDucks from "./filterDucks";
import UserReducer from "./userDucks";
import WorkReducer from "../pages/buildYourProfile/templates/Ducks/WorkDucks";
import BasicReducer from "../pages/buildYourProfile/templates/Ducks/BasicDucks";
import SkillReducer from "../pages/buildYourProfile/templates/Ducks/SkillDucks";
import HomeReducer from "../pages/homeStack/HomeDuck";
import StatusReducer from "../pages/homeStack/templates/StatusDucks";
import SortReducer from "../components/templates/SortDucks";
import JobReducer from "../pages/homeStack/JobsDucks";
import ChatsReducer from "../pages/homeStack/templates/ChatsDucks";

const Store = createStore(
  combineReducers({
    App: AppReducer,
    Auth: AppAuthReducer,
    User: UserReducer,
    Work: WorkReducer,
    Basic: BasicReducer,
    Skill: SkillReducer,
    Home: HomeReducer,
    Status: StatusReducer,
    Sort: SortReducer,
    JobSearch: JobReducer,
    Chats: ChatsReducer,
    Filter: FilterDucks
  })
);

export default Store;
