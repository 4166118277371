import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Search from "@material-ui/icons/Search";
import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined";
import Geocode from "react-geocode";
import { useMediaQuery } from "react-responsive";
import React, { useState } from "react";
import AppText from "../atoms/AppText";
import AppIconButton from "../atoms/AppIconButton";
import LocationSearchMapbox from "./LocationSearchMapbox";

export default function SearchBox1({
  hasError,
  onSubmit,
  onQueryBlur,
  onQueryChange,
  onLocationChange,
  queryDefaultValue,
  locationHasError,
  onCordsChange,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [value, setValue] = useState("");

  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    root1: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input1: {
      fontFamily: "Roboto",
      marginTop: "-2px",
      height: "19px",
      color: "#3E3F42",
      border: hasError ? "1px solid #E6492D" : "1px solid #D8DCE6",
      borderRadius: "4px",
      borderTopRightRadius: isDesktop || isTablet ? 0 : "4px",
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: isDesktop || isTablet ? "4px" : 0,
    },
    input2: {
      paddingTop: "12px",
      marginTop: "-2px",
      height: "42px",
      paddingBottom: "12px",
      fontFamily: "Roboto",
      color: "#3E3F42",
      borderRadius: 0,
      border: "1px solid #D8DCE6",
      borderLeft: "none",
      borderRight: "none",
    },
    btn: {
      backgroundColor: "#03CA98",
      textTransform: "none",
      height: "42px",
      marginTop: "-2px",
      border: "1px solid #03CA98",
      borderTopLeftRadius: isDesktop || isTablet ? 0 : "4px",
      borderBottomLeftRadius: isDesktop || isTablet ? 0 : "4px",
      width: isDesktop || isTablet ? "160px" : "100%",
      "&:hover": {
        backgroundColor: "#03CA98",
      },
      "&:focus": {
        outline: 0,
      },
    },
  });
  const classes = useSyles();

  const getPlace = async (lat, lng) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.setLocationType("ROOFTOP");
    const location = await Geocode.fromLatLng(lat, lng);

    // const locationString = `${location.results[0].formatted_address.split(",")[1]}, ${location.results[0].formatted_address.split(",")[2]
    //   }`;

    setValue(location.plus_code.compound_code.substring(8));
    onLocationChange(location.plus_code.compound_code.substring(9));
    onCordsChange([lng, lat]);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((p) => {
        getPlace(p.coords.latitude, p.coords.longitude);
      });
    }
  };

  return (
    <div
      className={`${
        isDesktop || isTablet ? "d-flex w-60" : "d-block w-95"
      } mt-4 mb-3`}
    >
      <div className={isDesktop || isTablet ? "w-50" : "w-100"}>
        <TextField
          size="small"
          name="query"
          value={queryDefaultValue}
          onChange={onQueryChange}
          onBlur={onQueryBlur}
          variant="outlined"
          placeholder="Search Job title, Company, Keywords"
          className={classes.root}
          inputProps={{
            className: classes.input1,
          }}
        />
      </div>
      <div className={isDesktop || isTablet ? "w-30" : "w-100"}>
        <div
          className={`${
            isDesktop || isTablet ? "_top_bottom_border" : "_lbr_border"
          } d-flex align-items-center`}
          style={{ borderColor: locationHasError ? "#E6492D" : null }}
        >
          <AppIconButton onClick={getCurrentLocation}>
            <LocationOnOutlined
              style={{ color: "#CECFD2", fontSize: "20px", marginLeft: "5px" }}
            />
          </AppIconButton>

          <LocationSearchMapbox
            value={value}
            onChange={(data) => {
              onLocationChange(data.shortText);
              setValue(data.shortText);
              onCordsChange(data.cordinates);
            }}
            onValueChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
      <div className={isDesktop || isTablet ? "w-20" : "w-100"}>
        <Button className={classes.btn} onClick={onSubmit}>
          <Search
            style={{ fontSize: "17px", marginRight: "10px", color: "#fff" }}
          />
          <AppText color="_c0" size="_16">
            Search
          </AppText>
        </Button>
      </div>
    </div>
  );
}
