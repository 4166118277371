import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Educations from "./Educations";
import AddIcon from "@material-ui/icons/Add";
import AppButton from "../molecules/AppButton";
import AppText from "../atoms/AppText";
import Model from "../molecules/Model";
import EducationModal from "../orgnisms/EducationModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppDialog from "../atoms/AppDialog";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import { fire } from "../../firebase/index";

const FETCH_EDUCATION = gql`
  query ($id: String!) {
    getCurrentUser(uid: $id) {
      education {
        id
        school
        field
        degree
        startDate
        endDate
      }
    }
  }
`;

const DELETE_MY_EXPERIENCE = gql`
  mutation ($id: String!) {
    deleteEducation(id: $id)
  }
`;

export default function EducationTemplate({ immutable = true }) {
  const [addEducation, setAddEducation] = useState(false);
  const [education, setEducation] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  const { loading, data, refetch } = useQuery(FETCH_EDUCATION, {
    fetchPolicy: "no-cache",
    variables: {
      id: fire.auth().currentUser.uid,
    },
  });
  const [deleteMyEducation, { loading: deleteLoading }] =
    useMutation(DELETE_MY_EXPERIENCE);

  const deleteEducation = (id) => {
    deleteMyEducation({ variables: { id } }).then(() => {
      refetch();
      setDeleteID(null);
      setDeleteOpen(false);
    });
  };

  const editEducation = (education) => {
    if (education) {
      setEducation(education);
      setAddEducation(true);
    }
  };

  const onClose = () => {
    setEducation({});
    setAddEducation(false);
  };

  useEffect(() => {}, [loading, data]);

  if (loading) return <CircularProgress style={{ color: "#9ea0a6" }} />;

  return (
    <div>
      <Educations
        educations={data?.getCurrentUser?.education}
        editEducation={(e) => editEducation(e)}
        onDeleteEducation={(id) => {
          setDeleteOpen(true);
          setDeleteID(id);
        }}
        refetch={refetch}
        preview={immutable ? false : true}
      />
      {immutable && (
        <AppButton color="_white" onClick={() => setAddEducation(true)}>
          <AddIcon style={{ color: "#9EA0A5" }} />{" "}
          <AppText size="_14">Add Education</AppText>
        </AppButton>
      )}

      {addEducation && (
        <Model onClose={() => setAddEducation(false)} title="Add Education">
          <EducationModal
            refetch={refetch}
            data={education}
            onClose={onClose}
          />
        </Model>
      )}
      {deleteOpen && (
        <Model
          isDelete={true}
          title="Delete Education?"
          isModal={false}
          onClose={() => setDeleteOpen(false)}
        >
          <AppDialog
            saveText="Yes, Delete"
            closeText="No"
            onClose={() => setDeleteOpen(false)}
            onSave={() => deleteEducation(deleteID)}
            isLoading={deleteLoading}
          >
            <div className="py-3">
              <span
                className="d-flex justify-content-center align-items-center"
                style={{
                  display: "inline-block",
                  height: "50px",
                  width: "50px",
                  color: "#E6492D80",
                  borderRadius: "50px",
                  border: "1px solid",
                }}
              >
                <DeleteOutlined
                  style={{ fontSize: "26px", color: "#E6492D" }}
                />
              </span>
            </div>
            <div>
              <AppText weight="_medium">Delete Education?</AppText>
            </div>
            <div className="mt-2 mb-4">
              <AppText size="_14" align="_center">
                Deleting this education data cannot be undone. Continue to
                delete?
              </AppText>
            </div>
          </AppDialog>
        </Model>
      )}
    </div>
  );
}
