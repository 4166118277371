import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
  root: {
    padding: 0,
    "&:hover": {
      backgroundColor: "inherit",
    },
    "&:focus": {
      outline: 0,
    },
  },
});
export default function AppIconButton({ children, onClick }) {
  const classes = useStyles();
  return (
    <IconButton
      onClick={onClick}
      className={classes.root}
      disableFocusRipple
      disableRipple
    >
      {children}
    </IconButton>
  );
}
