import React from "react";
import { useMutation } from "@apollo/client";
import { Check, StarBorderOutlined, Star } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import { STAR_JOB } from "../../apolloUtils";
import { addFav } from "../../pages/homeStack/HomeDuck";
import AppIconButton from "../atoms/AppIconButton";
import AppText from "../atoms/AppText";
import AppButton from "./AppButton";
import { processHeadings, textCut, closeDate } from "../../HelperFunctions";
// import { setAppied } from "../../pages/homeStack/templates/StatusDucks";
import numbro from "numbro";

export default function JobCard({
  job,
  detailed = false,
  showsStatus = true,
  isGrid = false,
  onSearch = false,
}) {
  const [addToFavourites] = useMutation(STAR_JOB);
  // const [sendApplicationNotification] = useMutation(SEND_APPLICATION_NOTIFICATION);
  // const [appTojob] = useMutation(APPLYTOJOB);

  const { user } = useSelector((state) => state.User);
  const { favourites } = useSelector((state) => state.Home);
  const status = useSelector((state) => state.Status);
  const isClosing = closeDate(parseInt(job.closingDate));

  const dispatch = useDispatch();
  const history = useHistory();

  const states = {
    1: "PROCESSING",
    2: "INTERVIEW",
    3: "OFFER",
    4: "UNSUCCESSFUL",
  };

  const didApply = status.find((j) => j.job?.id === job?.id);

  const addTofavs = (e) => {
    e.stopPropagation();
    const { id } = job;

    addToFavourites({ variables: { input: { job: id, candidate: user.id } } });
    dispatch(addFav({ job }));
  };
  // const applyJob = (e) => {
  //   if (!detailed) {
  //     e.stopPropagation();
  //     appTojob({ variables: { input: { jobId: job.id, candidate: user.id } } }).then(() => {
  //       sendApplicationNotification({
  //         variables: {
  //           input: {
  //             string: `${user.firstName} ${user.lastName} Applied to ${job.jobTitle}`,
  //             action: 5,
  //             toUser: job.assignedTo.id,
  //             senderFullName: `${user.firstName} ${user.lastName}`,
  //             senderPhotoUrl: user.photoUrl,
  //           },
  //         },
  //       });
  //       dispatch(setAppied({ job, status: 1 }));
  //     });
  //   }

  // };

  const handleClick = () => {
    history.push(`/job/${job.id}`, job);
  };

  return (
    <div
      className="px-4 py-4 bg-white _j_container pointer"
      onClick={handleClick}
    >
      <div className="mb-2 d-flex justify-content-between align-items-start">
        <div>
          <img src={job?.company.logo} alt="js" className="logo_job" />
        </div>
        <div>
          {didApply && showsStatus ? (
            <span className={`_statetag ${states[didApply?.status]}`}>
              <AppText size="_12" weight="_medium" color="_c0">
                {states[didApply?.status]}
              </AppText>
            </span>
          ) : (
            <>
              {Moment(parseInt(job?.createdAt)).isAfter(
                Moment().subtract(7, "days")
              ) ? (
                <div className="_marker ms-2 d-flex align-items-center justify-content-center">
                  <AppText size="_11" color="_g100">
                    NEW
                  </AppText>
                </div>
              ) : (
                <AppText size="_14" color="_c90">
                  {textCut(Moment(parseInt(job?.createdAt)).fromNow(), 14)}
                </AppText>
              )}
            </>
          )}
        </div>
      </div>
      <div className="mb-4">
        <AppText size="_16" weight="_medium">
          <span style={{ textTransform: "capitalize" }}>
            {processHeadings(textCut(job?.jobTitle, isGrid ? 17 : 23))}
          </span>
        </AppText>
        <div>
          <AppText size="_14">
            <span className="_medium ">{textCut(job?.company.name, 11)}</span> •{" "}
            <span className="_c90">{job?.location.split(",")[0]}</span>
          </AppText>
        </div>
        {onSearch && (
          <div className={"d-flex"}>
            {job?.salaryRange?.defaultType !== "hourly" ? (
              <AppText size="_14" weight="_medium" color="_b100">
                £
                {job?.salaryRange?.start !== 0
                  ? job?.salaryRange?.start < 1000
                    ? numbro(job?.salaryRange?.start).format({ mantissa: 2 })
                    : numbro(job?.salaryRange?.start).format({
                        thousandSeparated: true,
                        average: true,
                      })
                  : null}
                {job?.salaryRange?.start !== 0 ? " - £" : ""}
                {job?.salaryRange?.end < 1000
                  ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                  : numbro(job?.salaryRange?.end).format({
                      thousandSeparated: true,
                      average: true,
                    })}
              </AppText>
            ) : (
              <AppText size="_14" weight="_medium" color="_b100">
                £
                {job?.salaryRange?.end < 1000
                  ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                  : numbro(job?.salaryRange?.end).format({
                      thousandSeparated: true,
                      average: true,
                    })}
                /hr
              </AppText>
            )}

            {!isClosing ? null : (
              <span className={"ms-1 d-flex"}>
                <AppText size="_14" color={"_o100"}>
                  {" •"} {isClosing}
                </AppText>
              </span>
            )}
          </div>
        )}

        <div />
        <div className="d-flex mt-3">
          <div className="w-95">
            {typeof didApply === "undefined" ? (
              <AppButton width="_full" color="_white">
                <AppText size="_14">Apply Now</AppText>
              </AppButton>
            ) : (
              <AppButton
                width="_full"
                color="_green"
                state={
                  states[didApply?.status] === "UNSUCCESSFUL" ? true : false
                }
              >
                <div>
                  <Check style={{ color: "#fff" }} />{" "}
                  <AppText size="_14" color="_c0">
                    Applied
                  </AppText>
                </div>
              </AppButton>
            )}
          </div>
          <div className="d-flex justify-content-center">
            {favourites.find((el) => el.job.id === job?.id) ? (
              <span className="px-2 d-flex justify-content-center">
                <AppIconButton onClick={addTofavs}>
                  <Star style={{ fontSize: "17px", color: "#03CA98" }} />
                </AppIconButton>
              </span>
            ) : (
              <span className="px-2 d-flex justify-content-center">
                <AppIconButton onClick={addTofavs}>
                  <StarBorderOutlined
                    style={{ fontSize: "17px", color: "#BCBCBC" }}
                  />
                </AppIconButton>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
