import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import AppTopBar from "../../../components/orgnisms/AppTopBar";
import Lists from "../../../components/templates/Lists";
import Map from "../../../components/templates/Map";
import Query from "query-string";
import { useLazyQuery } from "@apollo/client";
import { QUERY_JOBS } from "../../../apolloUtils";
import Filters from "../../../components/molecules/Filters";
import AppText from "../../../components/atoms/AppText";
import Switch from "@material-ui/core/Switch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Model from "../../../components/molecules/Model";
import JobAlertCard from "../../../components/molecules/JobAlertCard";
import { getMinAndMax } from "../../../HelperFunctions/index";
import { addKeyword } from "../../../redux/userDucks";
import { setJobSearch } from "../JobsDucks";
import { useLocation } from "react-router-dom";
import { clearFilters } from "../../../redux/filterDucks";
import { getPagination } from "../../../HelperFunctions/index";

const useStyles = makeStyles({
  root: {
    width: 40,
    height: "20px",
    padding: 0,
    marginRight: "0.6rem",
    "& .MuiSwitch-switchBase": {
      padding: 1,
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#34AA44",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      opacity: 1,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#9EA0A5",
      borderRadius: "26px!important",
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px #00000026",
  },
});

export default function Results() {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });
  const home = useSelector((state) => state.Home);
  const { user } = useSelector((state) => state.User);
  const { sortBy } = useSelector((state) => state.Sort);
  const filters = useSelector((state) => state.Filter);
  const location = useLocation();
  const history = useHistory();

  const [filter, setFilter] = useState(null);
  const [filtersLoaded] = useState(true);
  const [filterChanged, setFilterChanged] = useState(0);
  const [alerts, setAlerts] = useState(false);
  const [minAndMax, setMinAndMax] = useState(["0", "0"]);
  const [page, setPage] = useState(
    getPagination(history?.location?.state?.page) || 0
  );
  const [paginationPage, setPaginationPage] = useState(
    history?.location?.state?.page || 0
  );
  const [count, setCount] = useState(0);
  const params = useParams();
  const dispatch = useDispatch();

  const [search, { loading, data }] = useLazyQuery(QUERY_JOBS);

  useEffect(() => {
    const setMinMax = async () => {
      if (data !== undefined) {
        const value = await getMinAndMax(data.queryJobs.jobs);
        setMinAndMax(value);
      }
    };
    setMinMax();
  }, [data]);

  useEffect(() => {
    if (!loading && data) {
      setCount(data.queryJobs.count);
      dispatch(setJobSearch(data.queryJobs.jobs));
    }
  }, [data, dispatch, loading]);

  useEffect(() => {
    let location = Query.parse(params.query).location.trim();
    const query =
      Query.parse(params.query).q.trim() === "allJobs"
        ? ""
        : Query.parse(params.query).q.trim();
    const cords = Query.parse(params.query).cord;

    const searchJobs = {
      location: {
        lng: cords?.split(",")[0]?.trim(),
        lat: cords?.split(",")[1]?.trim(),
      },
      query,
    };

    if (location?.trim() === "") {
      delete searchJobs.location;
    } else if (query?.trim() === "") {
      delete searchJobs.query;
    }

    const filterObj = {
      datePost:
        filters.datePost === "Last 3 Days"
          ? 3
          : filters.datePost === "Last 7 Days"
          ? 7
          : filters.datePost === "Last 14 Days"
          ? 14
          : 0,
    };
    if (filters.experience && filters.experience !== "Any") {
      filterObj.experienceLevel = filters.experience;
    }

    if (filters.jobTypesArray.length !== 0) {
      const newArray = [...filters.jobTypesArray];
      const id = newArray.findIndex((i) => i === "Remote");
      if (id !== -1) {
        newArray.splice(id, 1);
      }
      if (newArray.length !== 0) {
        filterObj.jobType = newArray;
      }
    }
    if (filters.remotePossible) {
      filterObj.openToRemote = filters.remotePossible;
    }

    if (filters.location) {
      filterObj.location = filters.location;
    }

    if (filters.salary.salary.start && filters.salary.salary.end) {
      filterObj.salary = {
        defaultType: filters.salary.defaultType,
        salary: [
          parseInt(filters.salary.salary.start),
          parseInt(filters.salary.salary.end),
        ],
      };
    }
    if (
      !filters.salary.salary.end &&
      (filters.salary.defaultType === "fixed" ||
        filters.salary.defaultType === "hourly")
    ) {
      filterObj.salary = {
        defaultType: filters.salary.defaultType,
      };
    }
    search({
      variables: {
        input: {
          ...searchJobs,
          skip: page,
          limit: 12,
          sortBy: sortBy.trim() === "Highest paid" ? "highestPaid" : "recent",
          filterBy: filters.filtersShow ? filterObj : { datePost: 0 },
          all:
            Query.parse(params.query).q.trim() === "allJobs" &&
            searchJobs.location?.lat === undefined
              ? true
              : false,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, search, page, sortBy, filterChanged]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, [dispatch]);

  // Check if the path change
  useEffect(() => {
    if (location.pathname !== location?.state?.location) {
      setPage(0);
      setPaginationPage(0);
    }
  }, [location]);

  const handlePaginationChange = (value) => {
    window.scrollTo(0, 0);
    if (value <= 1) {
      setPage(0);
      setPaginationPage(0);
    } else if (value > Math.ceil(count / 12)) {
      setPage((Math.ceil(count / 12) - 1) * 12);
      setPaginationPage(value);
    } else {
      setPage((value - 1) * 12);
      setPaginationPage(value);
    }
  };

  return (
    <div>
      <AppTopBar onSubmit={() => setAlerts(false)} />
      <>
        <div className="d-flex justify-content-center _bg_color">
          {isDesktop && (
            <div className="w-30 d-flex flex-column align-items-end pe-4 pt-4">
              <div style={{ width: "214px" }}>
                {user.keywords && (
                  <div>
                    <Switch
                      checked={
                        user?.keywords.find(
                          (key) =>
                            key.toLowerCase().trim() ===
                              Query.parse(params.query)
                                .q.toLowerCase()
                                .trim() ||
                            key.toLowerCase().trim() ===
                              Query.parse(params.query)
                                .location.toLowerCase()
                                .trim()
                        )
                          ? true
                          : alerts
                      }
                      onChange={() => setAlerts(!alerts)}
                      focusVisibleClassName={classes.focusVisible}
                      disableRipple
                      color="primary"
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                      }}
                    />
                    <AppText size="_16" weight="_medium">
                      Create A Job Alert
                    </AppText>
                  </div>
                )}
                {user.keywords && (
                  <div className="mt-2">
                    {user.keywords.find(
                      (key) =>
                        key.toLowerCase().trim() ===
                        Query.parse(params.query).q.toLowerCase().trim()
                    ) && (
                      <AppText size="_12" color="_c90">
                        You will get updates about new jobs opportunities via
                        notifications and email. You can change this in your
                        settings.
                      </AppText>
                    )}
                  </div>
                )}

                <div className="_sep_ mt-3" />
              </div>
              <div className="mt-3" />
              {filtersLoaded ? (
                <Filters
                  filters={filter}
                  onFilter={() => setFilterChanged(filterChanged + 1)}
                  onClearFilters={() => {
                    setFilterChanged(filterChanged + 1);
                    setFilter(null);
                  }}
                  minAndMax={minAndMax}
                />
              ) : (
                "loading"
              )}
            </div>
          )}

          <div
            className={
              isDesktop
                ? "w-70"
                : isIpadPro
                ? "w-75"
                : isTablet
                ? "w-95"
                : "w-100"
            }
          >
            {home.showsMap ? (
              <Map loading={loading} filter={filter} />
            ) : (
              <div className={isDesktop ? "w-82" : "w-100"}>
                <Lists
                  loading={loading}
                  filter={filter}
                  queryParams={{
                    query: Query.parse(params.query).q,
                    location: Query.parse(params.query).location,
                  }}
                  minAndMax={minAndMax}
                  onFilter={() => setFilterChanged(filterChanged + 1)}
                  count={count}
                  pagination={handlePaginationChange}
                  page={paginationPage}
                />
              </div>
            )}
          </div>
        </div>
      </>
      {alerts &&
        !user.keywords.find(
          (key) =>
            key.toLowerCase().trim() ===
            Query.parse(params.query).q.toLowerCase().trim()
        ) && (
          <div className="mt-5">
            <Model title="Create Job Alert" onClose={() => setAlerts(false)}>
              <JobAlertCard
                mobile={!isDesktop && !isTablet && !isIpadPro ? true : false}
                onClose={() => {
                  setAlerts(false);
                }}
                onSave={(word) => {
                  dispatch(addKeyword(word));
                }}
                queryParams={{
                  query: Query.parse(params.query).q,
                  location: Query.parse(params.query).location,
                }}
              />
            </Model>
          </div>
        )}
    </div>
  );
}
// sd
