import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import AppText from "../../../components/atoms/AppText";
import NoMessages from "../../../components/molecules/NoMessages";
import AppCircularProgress from "../../../components/atoms/AppCircularProgress";
import ChatArea from "./chat/index.js";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import {
  getWindowDimensions,
  getTime,
  textCut,
  sortChats,
  randomColor,
  returnInitals,
  UpperCaseFirst,
} from "../../../HelperFunctions";

const GET_CONVERSATIONS = gql`
  query ($id: ID!) {
    getMyConvos(userId: $id) {
      id
      seeker {
        id
        firstName
        lastName
      }
      manager {
        id
        fullName
        avatarUrl
        roles {
          as
          company {
            name
          }
        }
      }
      lastMessage
      lastUpdate
    }
  }
`;

export default function Chat() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const screenSize = getWindowDimensions();

  const { user } = useSelector((state) => state.User);
  const { inChat, unread } = useSelector((state) => state.Chats);

  const { loading, data, refetch } = useQuery(GET_CONVERSATIONS, {
    variables: { id: user.id },
  });

  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [id, setId] = useState(null);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    setId(pathname.split("/")[2]);
  }, [pathname, setId]);

  useEffect(() => {
    if (!loading && data) {
      setChats(sortChats(data.getMyConvos));
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch, unread, inChat]);

  if (!loading && data && chats.length) {
    return (
      <div className="d-flex justify-content-center">
        <div className={isDesktop ? "w-100 d-flex" : "w-100 d-flex flex-wrap"}>
          <div className={isDesktop ? "w-30" : "w-100"}>
            {pathname !== "/chat" && !isDesktop ? null : (
              <div
                style={{
                  height: screenSize.height - 50,
                  borderRight: "1px solid #e2e5ed",
                }}
                className={"d-flex flex-column justify-content-between"}
              >
                <div>
                  <header className="chat_header d-flex align-items-center px-4 botm">
                    <span className="_cactive d-flex align-items-center">
                      <AppText size="_14" weight="_medium">
                        All Conversations
                      </AppText>
                    </span>
                  </header>
                  <div
                    className={"srollbar-y"}
                    style={{ maxHeight: screenSize.height - 184 }}
                  >
                    {loading && <p>loading</p>}
                    {!loading && data
                      ? chats.map((convo) => {
                          return (
                            <Link
                              key={convo.id}
                              to={{
                                pathname: `${url}/${convo?.id}`,
                                state: { ...convo },
                              }}
                            >
                              <div
                                className={`d-flex px-4 py-3 botm ${
                                  id === convo.id ? "_csel" : "_cdes"
                                }`}
                              >
                                <div className="me-2">
                                  <Avatar
                                    src={convo?.manager?.avatarUrl}
                                    style={{
                                      height: "35px",
                                      width: "35px",
                                      backgroundColor: randomColor(
                                        convo?.manager
                                          ? convo?.manager?.fullName
                                          : "Tap User"
                                      ),
                                    }}
                                  >
                                    <AppText size="_14" color="_c0">
                                      {convo?.manager
                                        ? returnInitals(
                                            `${UpperCaseFirst(
                                              convo?.manager.fullName?.split(
                                                " "
                                              )[0]
                                            )} ${UpperCaseFirst(
                                              convo?.manager.lastName?.split(
                                                " "
                                              )[1]
                                            )}`
                                          )
                                        : returnInitals(`Tap User`)}
                                    </AppText>
                                  </Avatar>
                                </div>
                                <div className={"w-100"}>
                                  <div
                                    className={
                                      "d-flex justify-content-between w-100 align-items-center"
                                    }
                                  >
                                    <div
                                      className={"d-flex align-items-center"}
                                    >
                                      <AppText size="_16">
                                        {convo?.manager
                                          ? convo?.manager?.fullName
                                          : "Tap User"}
                                      </AppText>
                                    </div>
                                    <AppText size="_12" color="_c90">
                                      {convo?.lastMessage &&
                                        getTime(convo?.lastUpdate)}
                                    </AppText>
                                  </div>
                                  <div className="mt-1 d-flex justify-content-between">
                                    <AppText size="_14" color="_c90">
                                      {unread.filter(
                                        (chat) =>
                                          chat.conversationId === convo.id
                                      ).length !== 0
                                        ? textCut(
                                            unread?.filter(
                                              (chat) =>
                                                chat.conversationId === convo.id
                                            )[
                                              unread?.filter(
                                                (chat) =>
                                                  chat.conversationId ===
                                                  convo.id
                                              ).length - 1
                                            ]?.messageContent,
                                            30
                                          )
                                        : textCut(
                                            inChat.filter(
                                              (chat) =>
                                                chat.conversationId === convo.id
                                            )[
                                              inChat.filter(
                                                (chat) =>
                                                  chat.conversationId ===
                                                  convo.id
                                              ).length - 1
                                            ]?.messageContent.length
                                              ? inChat.filter(
                                                  (chat) =>
                                                    chat.conversationId ===
                                                    convo.id
                                                )[
                                                  inChat.filter(
                                                    (chat) =>
                                                      chat.conversationId ===
                                                      convo.id
                                                  ).length - 1
                                                ]?.messageContent
                                              : convo?.lastMessage,
                                            30
                                          )}
                                    </AppText>
                                    <div className="me-3 ">
                                      <Badge
                                        badgeContent={
                                          unread.filter(
                                            (chat) =>
                                              chat.conversationId === convo.id
                                          ).length
                                        }
                                        color="secondary"
                                        max={100}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })
                      : null}
                    {!loading && data?.getMyConvos.length === 0 ? (
                      <div className="m-4">
                        <AppText>No Conversations</AppText>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{ height: "84px", borderTop: "1px solid #eaedf3" }}
                />
              </div>
            )}
          </div>
          {id && (
            <div className={isDesktop ? "w-70" : "w-100"}>
              <Switch>
                <Route path={`${url}/:id`} exact>
                  <ChatArea />
                </Route>
              </Switch>
            </div>
          )}
        </div>
      </div>
    );
  }
  if (!loading && data && !chats.length) {
    return <NoMessages height={50} />;
  } else {
    return <AppCircularProgress />;
  }
}
