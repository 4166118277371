import React, { useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import Experiences from "./Experiences";
import AppButton from "../molecules/AppButton";
import AppText from "../atoms/AppText";
import ExperienceModal from "../orgnisms/ExperienceModal";
import Model from "../molecules/Model";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppDialog from "../atoms/AppDialog";
import { DeleteOutlined } from "@material-ui/icons";
import { fire } from "../../firebase/index";

const DELETE_WORK_EXPERIENCE = gql`
  mutation ($id: String!) {
    deleteExperience(id: $id)
  }
`;

const FETCH_EXPERIENCE = gql`
  query ($id: String!) {
    getCurrentUser(uid: $id) {
      experience {
        id
        myRole
        employmentType
        company {
          name
          location
          logoUrl
        }
        startDate
        endDate
        workHere
      }
    }
  }
`;

export default function ExperienceTemplate({ immutable = true }) {
  const [experienceOpen, setExperienceOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [experience, setExperience] = useState({});

  const { loading, data, refetch } = useQuery(FETCH_EXPERIENCE, {
    fetchPolicy: "no-cache",
    variables: {
      id: fire.auth().currentUser.uid,
    },
  });

  const [deleteExperience, { loading: deleteLoading }] = useMutation(
    DELETE_WORK_EXPERIENCE
  );

  const editExperience = (jobExperience) => {
    if (jobExperience) {
      setExperience(jobExperience);
      setExperienceOpen(true);
    }
  };

  const onClose = () => {
    setExperience({});
    setExperienceOpen(false);
  };

  const deleteWorkExperience = (id) => {
    deleteExperience({ variables: { id } }).then(() => {
      refetch();
      setDeleteID(null);
      setDeleteOpen(false);
    });
  };

  if (loading) return <CircularProgress style={{ color: "#9ea0a6" }} />;
  return (
    <div>
      <Experiences
        editExperience={(e) => editExperience(e)}
        onDeleteExperience={(id) => {
          setDeleteOpen(true);
          setDeleteID(id);
        }}
        experiences={data?.getCurrentUser?.experience}
        refetch={refetch}
        preview={immutable ? false : true}
      />
      {immutable && (
        <AppButton color="_white" onClick={() => setExperienceOpen(true)}>
          <AddIcon style={{ color: "#9EA0A5" }} />
          <AppText size="_14">Add Work Experience</AppText>
        </AppButton>
      )}

      {experienceOpen && (
        <Model
          title={
            experience?.startDate
              ? "Edit Work Experience"
              : "Add Work Experience"
          }
          onClose={onClose}
        >
          <ExperienceModal
            refetch={refetch}
            data={experience}
            onClose={onClose}
          />
        </Model>
      )}
      {deleteOpen && (
        <Model
          isDelete={true}
          isModal={false}
          onClose={() => setDeleteOpen(false)}
        >
          <AppDialog
            saveText="Yes, Delete"
            closeText="No"
            onClose={() => setDeleteOpen(false)}
            onSave={() => deleteWorkExperience(deleteID)}
            isLoading={deleteLoading}
          >
            <div className="py-3">
              <span
                className="d-flex justify-content-center align-items-center"
                style={{
                  display: "inline-block",
                  height: "50px",
                  width: "50px",
                  color: "#E6492D80",
                  borderRadius: "50px",
                  border: "1px solid",
                }}
              >
                <DeleteOutlined
                  style={{ fontSize: "26px", color: "#E6492D" }}
                />
              </span>
            </div>
            <div>
              <AppText weight="_medium">Delete Work Experience?</AppText>
            </div>
            <div className="mt-2 mb-4">
              <AppText size="_14" align="_center">
                Deleting this work experience data cannot be undone. Continue to
                delete?
              </AppText>
            </div>
          </AppDialog>
        </Model>
      )}
    </div>
  );
}
