import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "@apollo/client";
import AppChip from "../atoms/AppChip";
import AppText from "../atoms/AppText";
import SearchBox1 from "../molecules/SearchBox1";
import AppButton from "../molecules/AppButton";
import { GET_POPULAR_JOBS } from "../../apolloUtils";

export default function Jumbotoron() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const { loading, data } = useQuery(GET_POPULAR_JOBS);

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      query: "",
      location: "",
      cords: {},
    },
    validationSchema: Yup.object().shape({
      query: Yup.string(),
      location: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      if (values.query === "" && values.location === "") {
        formik.setFieldError("query", "required");
      } else {
        history.push(
          `/search/q=${values.query}&location=${values.location}&cord=${
            values.cords.length ? values.cords : ""
          }`
        );
        resetForm();
      }
    },
  });

  const searchJobs = () => {
    formik.handleSubmit();
  };

  const handleClick = () => {
    formik.setFieldValue("query", "allJobs");
    formik.handleSubmit();
  };

  return (
    <div
      className={`${
        isDesktop || isTablet ? "w-75 _jumbo" : "w-100 _sm_jumbo"
      }   d-flex flex-column justify-content-center align-items-center`}
    >
      <AppText size={isDesktop || isTablet ? "_26" : "_22"} weight="_medium">
        Find your perfect job today!
      </AppText>
      <SearchBox1
        hasError={formik.touched.query && formik.errors.query ? true : false}
        locationHasError={
          formik.touched.location && formik.errors.location ? true : false
        }
        queryDefaultValue={formik.values.query}
        LocationDefaultValue={formik.values.location}
        onQueryChange={formik.handleChange}
        onQueryBlur={formik.handleBlur}
        onCordsChange={(cords) => formik.setFieldValue("cords", cords)}
        onLocationChange={(place) => formik.setFieldValue("location", place)}
        onLocationBlur={formik.handleBlur}
        onSubmit={searchJobs}
      />
      <div className={isDesktop || isTablet ? "" : "w-95"}>
        {!loading && data && data?.getPopularJobs.length !== 0 && (
          <div className={`d-flex  flex-wrap`}>
            <div className="me-2">
              <AppText size="_14" color="_c90">
                Popular:
              </AppText>
            </div>
            {data?.getPopularJobs.map((popular) => (
              <span
                key={popular?.id}
                className="pb-2 pointer"
                onClick={() => formik.setFieldValue("query", popular?.jobTitle)}
              >
                <AppChip>{popular?.jobTitle}</AppChip>
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={"mt-2"}>
        <AppButton fontColor={"#fff"} onClick={handleClick}>
          <AppText size="_14" color={"_c0"}>
            View all jobs
          </AppText>
        </AppButton>
      </div>
    </div>
  );
}
