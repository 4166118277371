import React, { useEffect } from "react";
import {
  ArrowBackSharp,
  Business,
  Event,
  Language,
  PinDrop,
} from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import AppText from "../../../components/atoms/AppText";
import MoreCard from "../../../components/orgnisms/MoreCard";
import { GET_COMPANY_JOBS, GET_COMPANY_BY_ID } from "../../../apolloUtils";

export default function CompanyProfile() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();

  const { loading, data } = useQuery(GET_COMPANY_JOBS, {
    variables: {
      input: {
        companyId: state?.id || id,
        skip: 0,
        limit: 6,
      },
    },
  });

  const [fetchCompany, { loading: companyLoading, data: companyData }] =
    useLazyQuery(GET_COMPANY_BY_ID);

  useEffect(() => {
    if (!state?.id) {
      fetchCompany({ variables: { id: state?.id || id } });
    }
  }, [state?.id, fetchCompany, id]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="d-flex justify-content-center _bg_color ">
      {!companyLoading ? (
        <div
          className={`${
            isDesktop ? "w-75 d-flex" : isTablet ? "w-82" : "w-100 px-1"
          } pt-4`}
        >
          <div className={isDesktop ? "w-70 pe-3" : "w-100"}>
            <div
              className={isDesktop || isTablet ? "_job_cover" : "_job_cover_sm"}
            >
              <img
                alt="er3"
                src={state?.header || companyData?.getCompanyById?.header}
                className="_ccover"
              />
              <button className="_back_btn" onClick={goBack}>
                <ArrowBackSharp style={{ color: "#fff" }} />
              </button>
            </div>
            <div className="pt-4 bg-white _bs">
              <div className="d-flex justify-content-between px-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-3">
                    <img
                      alt="er3"
                      src={state?.logo || companyData?.getCompanyById?.logo}
                      className="_clogo"
                    />
                  </div>
                  <div>
                    <div>
                      <AppText size="_18" weight="_medium">
                        {state?.name || companyData?.getCompanyById?.name}
                      </AppText>
                    </div>
                    <AppText size="_14" color="_c90">
                      {state?.services || companyData?.getCompanyById?.services}{" "}
                      |{" "}
                      {state?.location || companyData?.getCompanyById?.location}
                    </AppText>
                  </div>
                </div>
              </div>
              <div className="my-4 px-4">
                <AppText size="_14" color="_c90">
                  {state?.bio || companyData?.getCompanyById?.bio}
                </AppText>
              </div>
              <div className="d-flex my-4 px-4">
                <div className="w-25">
                  <AppText size="_14" weight="_medium">
                    About
                  </AppText>
                </div>
              </div>
              <div className="_sep_" />
              <div
                className={
                  isDesktop || isTablet ? "d-flex" : "d-flex flex-wrap"
                }
              >
                <div className={"w-100  px-4 pt-4 pb-4"}>
                  <AppText size="_14" weight="_medium">
                    Overview
                  </AppText>
                  <div className="mt-2">
                    <AppText size="_14" color="_c90">
                      {state?.overview || companyData?.getCompanyById?.overview}
                    </AppText>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div
                      className={`d-flex my-3 align-items-center w-50 ${
                        isDesktop || isTablet ? "w-50" : "w-100"
                      }`}
                    >
                      <div className="me-3">
                        <span className="_iconcircle d-flex justify-content-center align-items-center ">
                          <Language
                            style={{ color: "#9EA0A5", size: "16px" }}
                          />
                        </span>
                      </div>
                      <div>
                        <AppText size="_12">Website</AppText>
                        <div>
                          <AppText size="_12" color="_g90">
                            <a
                              className="_g90"
                              target="_blank"
                              rel="noreferrer"
                              href={
                                state?.websiteLink ||
                                companyData?.getCompanyById?.websiteLink
                              }
                            >
                              {state?.websiteLink ||
                                companyData?.getCompanyById?.websiteLink}
                            </a>
                          </AppText>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`d-flex my-3 align-items-center ${
                        isDesktop || isTablet ? "w-50" : "w-100"
                      }`}
                    >
                      <div className="me-3">
                        <span className="_iconcircle d-flex justify-content-center align-items-center ">
                          <PinDrop style={{ color: "#9EA0A5", size: "16px" }} />
                        </span>
                      </div>
                      <div>
                        <AppText size="_12">Headquarters</AppText>
                        <div>
                          <AppText size="_12" color="_c90">
                            {state?.location ||
                              companyData?.getCompanyById?.location}
                          </AppText>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`d-flex my-3 align-items-center ${
                        isDesktop || isTablet ? "w-50" : "w-100"
                      }`}
                    >
                      <div className="me-3">
                        <span className="_iconcircle d-flex justify-content-center align-items-center ">
                          <Business
                            style={{ color: "#9EA0A5", size: "16px" }}
                          />
                        </span>
                      </div>
                      <div>
                        <AppText size="_12">Industry</AppText>
                        <div>
                          <AppText size="_12" color="_c90">
                            {state?.services ||
                              companyData?.getCompanyById?.services}
                          </AppText>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`d-flex my-3 align-items-center ${
                        isDesktop || isTablet ? "w-50" : "w-100"
                      }`}
                    >
                      <div className="me-3">
                        <span className="_iconcircle d-flex justify-content-center align-items-center ">
                          <Event style={{ color: "#9EA0A5", size: "16px" }} />
                        </span>
                      </div>
                      <div>
                        <AppText size="_12">Founded</AppText>
                        <div>
                          <AppText size="_12" color="_c90">
                            {state?.founded ||
                              companyData?.getCompanyById?.founded}
                          </AppText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5" />
          </div>
          <div
            className={
              isDesktop ? "w-30 ps-3" : isTablet ? "w-50 mb-4" : "w-100 mb-4"
            }
          >
            <MoreCard
              loading={loading}
              jobs={data?.getJobsByCompany}
              name={state?.name || companyData?.getCompanyById?.name}
            />
          </div>
        </div>
      ) : (
        "loading"
      )}
    </div>
  );
}
