import React from "react";
import AppText from "../atoms/AppText";
import { useMediaQuery } from "react-responsive";
import { textCut } from "../../HelperFunctions";
import Appddown from "../molecules/Appddown";
import { Create, DeleteOutline, WorkOutline } from "@material-ui/icons";
import Moment from "moment";

export default function ExperienceBoxMobile({
  data,
  index,
  len,
  onDelete,
  onEdit,
  preview,
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  return (
    <div className={`d-flex mb-3 pb-3 ${index === len - 1 ? "" : "_bbotm"}`}>
      {data.company.logoUrl ? (
        <img
          src={data.company.logoUrl}
          alt="com"
          height={isDesktop || isTablet ? "50" : "36"}
          width={isDesktop || isTablet ? "50" : "36"}
        />
      ) : (
        <div className="_emp_logo_sm">
          <WorkOutline style={{ fontsSize: "22px" }} />
        </div>
      )}
      <div className="ms-3 w-95">
        <div className="d-flex justify-content-start align-items-center flex-wrap">
          <div className="w-75">
            <AppText
              size={isDesktop || isTablet ? "_18" : "_16"}
              weight="_medium"
            >
              {data.myRole}
            </AppText>
          </div>
          <div>
            {!preview && (
              <span className="pointer _ws">
                <Appddown vertical="bottom" horizontal="center">
                  <div className="ps-2 pe-5">
                    <div
                      className="d-flex align-items-center py-1 pointer"
                      onClick={onEdit}
                    >
                      <Create
                        style={{ marginRight: "1rem", fontSize: "16px" }}
                      />
                      <AppText size="_14">Edit</AppText>
                    </div>
                    <div
                      className="d-flex align-items-center py-1 pointer"
                      onClick={onDelete}
                    >
                      <DeleteOutline
                        style={{
                          marginRight: "1rem",
                          fontSize: "16px",
                          color: "#E6492D",
                        }}
                      />
                      <AppText size="_14" color="_r100">
                        Delete
                      </AppText>
                    </div>
                  </div>
                </Appddown>
              </span>
            )}
          </div>

          <div className="d-flex align-items-center  w-95 mb-2">
            <div className="me-1">
              <AppText size="_14" color="_c50">
                {data.company.name}
              </AppText>
            </div>
            <AppText size="_14" color="_c50">
              |
            </AppText>
            <div className="ms-1">
              <AppText size="_14" color="_c50">
                {textCut(data.company.location, 15)}
              </AppText>
            </div>
          </div>
          <span className="etype px-2 py-1">
            <AppText size="_12" color="_c90">
              {data?.employmentType?.toUpperCase()}
            </AppText>
          </span>
          <div className="ms-2">
            <AppText size="_14" color="_c50">
              {Moment(data.startDate).format("MMM YYYY")} {"- "}
              {data.workHere
                ? "Present"
                : Moment(data.endDate).format("MMM YYYY")}
            </AppText>
          </div>
        </div>
      </div>
    </div>
  );
}
