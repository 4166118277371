import React from "react";
import AppText from "../atoms/AppText";

export default function Summary({ value, name, onBlur, onChange, disabled = false }) {
  return (
    <div>
      <AppText size='_12' color='_c50'>
        PROFESSIONAL SUMMARY
      </AppText>
      {!disabled ? (
        <div className='w-100 mt-2'>
          <textarea
            onBlur={onBlur}
            className='w-100 _textarea'
            value={value}
            onChange={onChange}
            name={name}
            style={{whiteSpace:'pre-line'}}
            placeholder={`Tell us about yourself.`}
          />
        </div>
      ) : (
        <div className='w-100 mt-2 _textarea'>
          <AppText size='_14'>{value}</AppText>
        </div>
      )}
    </div>
  );
}
