import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Basic from "./templates/Basic";
import Resume from "./templates/Resume";
import Education from "./templates/Education";
import Video from "./templates/Video";
import Skills from "./templates/Skills";
import Preview from "./templates/Preview";
import AppText from "../../components/atoms/AppText";
import { useSelector } from "react-redux";
import Header from "../../components/orgnisms/Header";
import Welcome from "../../components/orgnisms/Welcome";
import Avatar from "@material-ui/core/Avatar";
import { randomColor, returnInitals } from "../../HelperFunctions";
import AppStepper from "../../components/atoms/AppStepper";
import { fire } from "../../firebase/index";

export default function BuilderRouter() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const { path } = useRouteMatch();
  const { user } = useSelector((state) => state.User);
  const { step } = useSelector((state) => state.App);
  const currentUser = fire.auth().currentUser;

  const steps = [
    { name: "Basic Information" },
    { name: "Work Experience" },
    // { name: "Education" },
    // { name: "Skills" },
    // { name: "Add a Video" },
    { name: "Preview" },
  ];

  return (
    <div className="_bg">
      <div className="fixed-header">
        <Header setupStack={true} />
        <Welcome>
          <div className={isDesktop || isTablet ? "me-4" : "me-2"}>
            <Avatar
              style={{
                height: "60px",
                width: "60px",
                backgroundColor: randomColor(
                  user?.firstName
                    ? user?.firstName
                    : `${currentUser?.displayName?.split(" ")[0]} ${
                        currentUser?.displayName?.split(" ")[1]
                      }`
                ),
              }}
              src={user?.photoUrl || ""}
            >
              <AppText weight="_bold" color="_c0">
                {returnInitals(
                  user?.firstName
                    ? `${user?.firstName} ${user?.lastName}`
                    : `${currentUser?.displayName?.split(" ")[0]} ${
                        currentUser?.displayName?.split(" ")[1]
                      }`
                )}
              </AppText>
            </Avatar>
          </div>
          <div>
            <AppText size={isDesktop || isTablet ? "_22" : "_18"}>
              Hi there{" "}
              {user?.firstName
                ? `${user?.firstName} ${user?.lastName}`
                : `${currentUser?.displayName?.split(" ")[0]} ${
                    currentUser?.displayName?.split(" ")[1]
                  }`}
            </AppText>
            <div>
              <AppText
                size={isDesktop || isTablet ? "_16" : "_14"}
                color="_c50"
              >
                Welcome to Tap Recruit. Setup your profile
              </AppText>
            </div>
          </div>
        </Welcome>
      </div>

      <div className={`d-flex pt-4 ovContainer justify-content-center`}>
        <div
          className={`${
            isDesktop
              ? "w-70 flex-row"
              : isTablet
              ? "w-60 flex-column"
              : "w-95 flex-column"
          } d-flex`}
        >
          <div className={`${isDesktop ? "w-1" : "w-100"}`}>
            <div className="free-space" />
            {isDesktop && <div className="mt-4" />}

            <div
              className={`${
                isDesktop
                  ? ""
                  : isTablet
                  ? "d-flex _mobi-line px-3 tabtop pt-2"
                  : "d-flex _mobi-line px-3 pt-2"
              }`}
            >
              <AppStepper steps={steps} active={step} />
            </div>
          </div>
          {isDesktop && <div className="w-30" />}
          <div className={`${isDesktop ? "w-70" : "w-100"}`}>
            {isDesktop && <div className="free-space" />}
            {!isDesktop && <div className="mt-5" />}

            <Switch>
              <Route path={path} exact>
                <Basic />
              </Route>
              <Route path={`${path}/resume`}>
                <Resume />
              </Route>
              <Route path={`${path}/education`}>
                <Education />
              </Route>
              <Route path={`${path}/skills`}>
                <Skills />
              </Route>
              <Route path={`${path}/video-cv`}>
                <Video />
              </Route>
              <Route path={`${path}/preview`}>
                <Preview />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
