import React, { useState, useEffect } from "react";
import { fire } from "../firebase";
import { GET_USER } from "../apolloUtils";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import SignupX from "./authStack/SignupX";
import HomeStack from "./homeStack/";
import FullLoader from "../components/orgnisms/FullLoader";
import { setUser } from "../redux/userDucks";

export default function InitalPage() {
  const dispatch = useDispatch();
  const [getUser] = useLazyQuery(GET_USER);

  const [user, setAuthUser] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [verified, setVerified] = useState(null);

  useEffect(() => {
    fire.auth().onAuthStateChanged(async (user) => {
      console.log(user);
      if (user) {
        setVerified(user.emailVerified);

        const { data } = await getUser({
          variables: {
            id: fire.auth().currentUser.uid,
          },
        });

        if (data && data?.getCurrentUser?.accountComplete) {
          dispatch(setUser(data.getCurrentUser));
          setCompleted(true);
        }

        setAuthUser(true);
      } else {
        setAuthUser(false);
      }
    });
  }, [getUser, dispatch, user]);

  console.log(completed);

  if (user) {
    if (completed) {
      return <HomeStack />;
    }
    console.log("dd");
    return <SignupX page={!verified ? "verification" : "signup"} />;
  } else {
    if (user === null) {
      return <FullLoader />;
    }

    return <SignupX page="signup" />;
  }
}
