import Initail from "./pages";
import "./App.css";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import Store from "./redux";
import Client from "./apollo";


function App() {

  return (
    <ApolloProvider client={Client}>
      <Provider store={Store}>
        <Initail />
      </Provider>
    </ApolloProvider>
  );
}

export default App;
