import React from "react";
import * as Headie from "@material-ui/icons";

export default function SchoolIcon() {
  return (
    <div className='_school_icon d-flex align-items-center justify-content-center'>
      <Headie.School />
    </div>
  );
}
