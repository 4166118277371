import React from "react";
import AppText from "./AppText";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles.css";

export default function AppPhoneInput({ formik, editable = true }) {
  return (
    <div>
      <div className='mb-2'>
        <AppText size='_12' color='_c50' weight='_medium'>
          PHONE NUMBER
        </AppText>
      </div>
      <PhoneInput
        disabled={!editable}
        value={formik.values.phoneNumber}
        onBlur={formik.handleBlur("phoneNumber")}
        onChange={formik.handleChange("phoneNumber")}
        country='gb'
        onlyCountries={["gb"]}
        name='phone'
        inputStyle={{
          width: "100%",
          border:
            formik.touched.phoneNumber && formik.errors.phoneNumber
              ? "1px solid #E6492D"
              : "1px solid #D8DCE6",
        }}
      />
      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
        <AppText size='_12' color='_r100'>
          {formik.errors.phoneNumber}
        </AppText>
      )}
    </div>
  );
}
