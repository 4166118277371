import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useSelector } from "react-redux";
import { uid } from "uid";
// import AWS from "aws-sdk";
import AppText from "../atoms/AppText";
import UploadButton from "../atoms/UploadButton";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppButton from "./AppButton";
import { saveAs } from "file-saver";
import FirebaseApp from "../../firebase";

const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($input: editUserInput) {
    updateUser(input: $input) {
      fullName
      cvUrl
      accountStage
    }
  }
`;

export default function UploadCv({
  save,
  setSave,
  error,
  setError,
  setIsLoading,
  title = "Upload",
  url = "",
  isLoading,
}) {
  const { user } = useSelector((state) => state.User);
  const [newUrl, setNewUrl] = useState(url);

  const [updateUser] = useMutation(UPDATE_USER_INFO);

  const handleChange = (file) => {
    setSave(false);
    setError(false);
    setIsLoading(true);
    const fileName = file[0].name.split(".");

    if (
      fileName[fileName.length - 1].includes("doc") ||
      fileName[fileName.length - 1].includes("pdf")
    ) {
      const storageRef = FirebaseApp.storage().ref();

      const cvRef = storageRef.child(
        `cvs/${uid(10)}.${file[0]?.type?.split("/")[1]}`
      );

      cvRef.put(file[0]).then((snap) => {
        snap.ref.getDownloadURL().then((downloadUrl) => {
          setNewUrl(downloadUrl);
          updateUser({
            variables: { input: { cvUrl: downloadUrl, userId: user.id } },
          }).then(() => {
            setSave(true);
            setIsLoading(false);
          });
        });
      });

      //   const s3Bucket = new AWS.S3({
      //     accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      //     secretAccessKey: process.env.REACT_APP_S3_SECRETE_ACCESS_KEY,
      //     region: process.env.REACT_APP_S3_REGION,
      //   });
      //   const params = {
      //     Bucket: process.env.REACT_APP_S3_BUCKET,
      //     Key: `${uid(10)}.${fileName[fileName.length - 1]}`,
      //     Body: file[0],
      //     ContentDisposition: "attachment",
      //   };
      //   const upload = s3Bucket.upload(params);
      //   upload.send((err, data) => {
      //     setNewUrl(data.Location);
      //     updateUser({
      //       variables: { input: { cvUrl: data.Location, userId: user.id } },
      //     }).then(({ data }) => {
      //       setSave(true);
      //       setIsLoading(false);
      //     });
      //   });
    } else {
      setError(`Please make sure you upload a word or pdf file`);
      setIsLoading(false);
    }
  };

  const saveFile = () => {
    if (newUrl) {
      saveAs(
        newUrl,
        `MyTapCv.${newUrl.split(".")[newUrl.split(".").length - 1]}`
      );
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex w-100 flex-row">
        <UploadButton
          label={`${title} CV`}
          onChange={(file) => handleChange(file)}
        />
        <div className={"ml-1"}>
          <AppButton
            color="_white"
            onClick={() => (isLoading ? null : saveFile())}
          >
            {isLoading ? (
              <CircularProgress
                style={{ color: "#03E0A9", height: "30px", width: "30px" }}
              />
            ) : (
              <DownloadIcon sx={{ color: "#809FB8", fontSize: 20 }} />
            )}
          </AppButton>
        </div>
      </div>

      {error ? (
        <span className="d-flx mt-2">
          <AppText size="_14" color="_r100">
            {error}
          </AppText>
        </span>
      ) : null}
      {save ? (
        <span className="d-flx mt-2">
          <AppText size="_14">{`Your CV was successfully saved!`}</AppText>
        </span>
      ) : null}
    </div>
  );
}
