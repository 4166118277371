import React from "react";
import { useFormik } from "formik";
// import * as Yup from "yup";
import { useMutation, gql } from "@apollo/client";
import { useSelector } from "react-redux";
import ModalFooter from "./ModalFooter";
import AppText from "../atoms/AppText";
import { validatePostalCode } from "../../HelperFunctions/index";
// import CheckyBox from "../atoms/CheckyBox";

const SAVE_KEYWORD = gql`
  mutation ($input: KeywordInput!) {
    addKeyword(input: $input)
  }
`;
export default function JobAlertCard({
  mobile = false,
  onClose,
  queryParams,
  onSave,
}) {
  const { user } = useSelector((state) => state.User);

  const [saveKeyword, { loading }] = useMutation(SAVE_KEYWORD);
  // const [currentReceiveWhen, setCurrentReceiveWhen] = React.useState("daily")

  const formik = useFormik({
    initialValues: {
      receivesAlertsBy: user.receivesAlertsBy.length
        ? user.receivesAlertsBy
        : ["email"],
      email: user.receivesAlertsBy.includes("email") ? true : false,
      pushNotifications: user.receivesAlertsBy.includes("pushNotifications")
        ? true
        : false,
      sms: user.receivesAlertsBy.includes("sms") ? true : false,
      daily: user.receivesAlertWhen === "daily" ? true : false,
      weekly: user.receivesAlertWhen === "weekly" ? true : false,
    },
    onSubmit: (value, { resetForm }) => {
      const keywordWithSettings = {
        key: queryParams.query ? queryParams.query : queryParams.location,
        userId: user.id,
        receivesAlertsBy: "pushNotifications",
        receivesAlertWhen: "daily",
      };

      saveKeyword({ variables: { input: { ...keywordWithSettings } } }).then(
        () => {
          resetForm();
          onSave(queryParams.query ? queryParams.query : queryParams.location);
          onClose();
        }
      );
    },
  });

  // const changeRecieveVia = (value) => {
  //   const newArray = formik.values.receivesAlertsBy.slice()
  //   const index = newArray.findIndex((m) => m === value)

  //   if (index === -1) {
  //     formik.setFieldValue("receivesAlertsBy", [...newArray, value])
  //     formik.setFieldValue(value, true)
  //   } else {
  //     newArray.splice(index, 1)
  //     formik.setFieldValue("receivesAlertsBy", newArray)
  //     formik.setFieldValue(value, false)
  //   }
  // }

  // const changeRecieveWhen = (value) => {
  //   const recieveWhen = ["daily", "weekly"]
  //   recieveWhen.map(w => {
  //     if (formik.values[w] === true) {
  //       formik.setFieldValue(w, false)
  //     } else {
  //       formik.setFieldValue(value, true)
  //       setCurrentReceiveWhen(value)
  //     }

  //     return null;
  //   })
  // }

  return (
    <div>
      {mobile && (
        <div>
          <div className="p-4">
            <AppText>Create Job Alert</AppText>
          </div>
          <div className="_sep" style={{ height: 1, width: "100%" }} />
        </div>
      )}
      <div className="p-2" />
      <form onSubmit={formik.handleSubmit}>
        <div className="px-4 pt-2">
          <div className="border rounded p-2" style={{ height: "70px" }}>
            {`${
              queryParams.query && queryParams.location
                ? queryParams.query + " in " + queryParams.location
                : queryParams.query
                ? queryParams.query === "allJobs"
                  ? ""
                  : queryParams.query
                : validatePostalCode(queryParams.location)
            }` || ""}
          </div>
        </div>

        <div className="py-3" />
        {/* <div className="px-4">
          <div>
            <AppText size="_12" color="_c90" weight="_medium">RECEIVE NOTIFICATIONS VIA</AppText>
            <div className="mt-3" />
            <div>
              <CheckyBox name="email" checked={formik.values.email} onChange={() => changeRecieveVia("email")} />
              <span className="ms-2"><AppText size="_12" color="_c90">Email</AppText></span>
            </div>
            <div>
              <CheckyBox name="pushNotifications" checked={formik.values.pushNotifications} onChange={() => changeRecieveVia("pushNotifications")} />
              <span className="ms-2"><AppText size="_12" color="_c90">Push notifications</AppText></span>
            </div>
            <div>
              <CheckyBox name="sms" checked={formik.values.sms} onChange={() => changeRecieveVia("sms")} />
              <span className="ms-2"><AppText size="_12" color="_c90">SMS (Text)</AppText></span>
            </div>
            <div className="mt-3" />
          </div>
          <div>
            <AppText size="_12" color="_c90" weight="_medium">FREQUENCY</AppText>
            <div>
              <div className="mt-3" />
              <div>
                <CheckyBox name="daily" checked={formik.values.daily} onChange={() => changeRecieveWhen("daily")} />
                <span className="ms-2"><AppText size="_12" color="_c90">Daily</AppText></span>
              </div>
              <div>
                <CheckyBox name="weekly" checked={formik.values.weekly} onChange={() => changeRecieveWhen("weekly")} />
                <span className="ms-2"><AppText size="_12" color="_c90">Weekly</AppText></span>
              </div>
              <div className="mt-3" />
            </div>
          </div>

        </div> */}

        <ModalFooter
          onClose={onClose}
          isLoading={loading}
          hasErrors={
            formik.touched.keyword && formik.errors.keyword ? true : false
          }
        />
      </form>
    </div>
  );
}
