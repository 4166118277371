import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Query from "query-string";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { toggleGrid } from "../../pages/homeStack/HomeDuck";
import AppText from "../atoms/AppText";
import CardSkeleton from "../atoms/CardSkeleton";
import CardListSeleton from "../atoms/CardListSkeleton";
import AppDropDownButton from "../molecules/AppDropDownButton";
import JobListCard from "../molecules/JobListCard";
import { Skeleton } from "@material-ui/lab";
import JobCardSm from "../molecules/JobCardSm";
import ModelMobile from "../molecules/ModelMobile";
import JobAlertCard from "../molecules/JobAlertCard";
import AppButton from "../molecules/AppButton";
import Filters from "../molecules/Filters";
import { FilterList } from "@material-ui/icons";
import FilterChip from "../atoms/FilterChip";
import JobCard from "../molecules/JobCard";
import AppPagination from "../molecules/AppPagination";
import { addKeyword } from "../../redux/userDucks";
import { clearJobType, resetFilter } from "../../redux/filterDucks";

const useStyles = makeStyles({
  root: {
    width: 40,
    height: "20px",
    padding: 0,
    marginRight: "0.6rem",
    "& .MuiSwitch-switchBase": {
      padding: 1,
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#34AA44",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      opacity: 1,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#9EA0A5",
      borderRadius: "26px!important",
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px #00000026",
  },
});

export default function Lists({
  loading,
  data = null,
  queryParams,
  onClick,
  minAndMax,
  onFilter,
  count,
  pagination,
  page,
}) {
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });

  const [alertOpen, setAlertOpen] = useState(false);
  const [alerts, setAlerts] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [newFilter, setNewFilters] = useState(null);
  const [isGrid, setIsGrid] = useState(false);

  const { jobs } = useSelector((state) => state.JobSearch);
  const { sortBy } = useSelector((state) => state.Sort);
  const filter = useSelector((state) => state.Filter);
  const { user } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (alerts) {
      setAlertOpen(true);
    }
  }, [alerts]);

  useEffect(() => {
    if (isMobile) {
      dispatch(toggleGrid(true));
    }
  }, [dispatch, isMobile, data]);

  const handleClear = () => {
    setFilterOpen(false);
    setNewFilters(null);
  };

  const removeFilter = (filter) => {
    dispatch(clearJobType(filter));
    onFilter();
  };
  const handleReset = (key, value) => {
    dispatch(resetFilter(key, value));
    onFilter();
  };

  const handleClick = () => {
    history.push(`/search/q=allJobs&location=&cord=`);
  };

  if (loading) {
    return (
      <div>
        <div
          className={`d-flex justify-content-between ${
            isDesktop || isTablet || isIpadPro ? "" : "px-4"
          }`}
        >
          <div>
            <Skeleton
              style={{
                height: "55px",
                marginTop: "10px",
                width: isDesktop || isTablet ? "180px" : "100px",
              }}
            />
          </div>
          <div className="d-flex">
            {isDesktop || isTablet || isIpadPro ? (
              <Skeleton
                style={{
                  height: "55px",
                  marginTop: "10px",
                  width: "180px",
                  marginRight: "10px",
                }}
              />
            ) : null}

            <Skeleton
              style={{ height: "55px", marginTop: "10px", width: "50px" }}
            />
          </div>
        </div>
        {isDesktop || isTablet || isIpadPro ? (
          <div className="">
            <CardListSeleton />
            <CardListSeleton />
            <CardListSeleton />
            <CardListSeleton />
            <CardListSeleton />
            <CardListSeleton />
          </div>
        ) : (
          <div>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </div>
        )}
      </div>
    );
  }
  if (data?.queryJobs?.count === 0) {
    return (
      <div className={isDesktop || isTablet ? "pt-4" : "pt-4 px-4"}>
        <AppText size={isDesktop || isTablet ? "_18" : "_14"}>
          No results found
        </AppText>
      </div>
    );
  }
  return (
    <div className={!isDesktop ? "px-4 w-100" : ""}>
      <div>
        <div
          className={`d-flex ${
            isMobile
              ? "flex-column"
              : "justify-content-between align-items-center"
          }  mt-4`}
        >
          <div className={`${isMobile ? "mb-3" : ""}`}>
            <AppText size={isDesktop || isTablet ? "_18" : "_14"}>
              {count} Jobs found
            </AppText>
          </div>
          <div className="d-flex">
            {params.query !== "q=allJobs&location=&cord=" ? (
              <div className={"me-2"}>
                <AppButton color={"_white"} height="39px" onClick={handleClick}>
                  <AppText size="_14">
                    {isMobile ? "View all" : "View all jobs"}
                  </AppText>
                </AppButton>
              </div>
            ) : null}
            {!isMobile ? (
              <div className="me-2 d-flex align-items-center">
                <AppButton
                  color="_white"
                  height="39px"
                  onClick={() => {
                    setIsGrid(!isGrid);
                  }}
                >
                  {isGrid ? (
                    <div>
                      <span className={"me-2 mt-1"}>
                        <i
                          className="icon-list"
                          style={{ color: "#07BF91", fontSize: "18px" }}
                        />
                      </span>
                      <AppText size="_14">List view</AppText>
                    </div>
                  ) : (
                    <div>
                      <span className={"me-2 mt-1"}>
                        <i
                          className="icon-grid"
                          style={{ color: "#07BF91", fontSize: "18px" }}
                        />
                      </span>
                      <AppText size="_14">Grid view</AppText>
                    </div>
                  )}
                </AppButton>
              </div>
            ) : null}
            <div>
              <AppDropDownButton sortOption={sortBy} />
            </div>
          </div>
        </div>
        {isDesktop || isIpadPro ? null : (
          <div className="my-4 d-flex justify-content-center _gb">
            <button className="gbtn" onClick={() => setFilterOpen(true)}>
              <FilterList style={{ color: "#9EA0A5", marginRight: "10px" }} />
              <AppText size="_14" color="_c90" weight="_medium">
                Filter results
              </AppText>
            </button>
          </div>
        )}
        <div
          className={`d-flex flex-wrap ${
            isDesktop || isIpadPro ? "mt-3" : ""
          } `}
        >
          {filter.filtersShow ? (
            <>
              <FilterChip
                isDefalut={filter.datePost !== "Anytime" ? false : true}
                onClick={() => handleReset("datePost", "Anytime")}
              >
                Date Posted: {filter.datePost}
              </FilterChip>

              {filter.experience ? (
                <FilterChip
                  isDefalut={filter.experience !== "Any" ? false : true}
                  onClick={() => handleReset("experience", "Any")}
                >
                  Level: {filter?.experience}
                </FilterChip>
              ) : null}

              {filter?.jobTypesArray?.length
                ? filter.jobTypesArray.map((type) => (
                    <FilterChip
                      key={type}
                      isDefalut={false}
                      onClick={() => removeFilter(type)}
                    >
                      {type}
                    </FilterChip>
                  ))
                : null}

              {filter?.location ? (
                <FilterChip
                  isDefalut={false}
                  onClick={() => handleReset("location", "")}
                >
                  Location: {filter?.location}
                </FilterChip>
              ) : null}

              {filter?.salary?.salary?.start && filter?.salary?.salary?.end ? (
                <FilterChip
                  onClick={() =>
                    handleReset("salary", {
                      defaultType: "annually",
                      salary: { start: "", end: "" },
                    })
                  }
                >
                  {filter?.salary?.defaultType === "annually"
                    ? `£ ${filter?.salary?.salary?.start} - £`
                    : "£"}{" "}
                  {filter?.salary?.salary?.end}
                </FilterChip>
              ) : null}
            </>
          ) : null}
        </div>

        {jobs?.length >= 1 ? (
          !isGrid ? (
            <div
              className={
                isDesktop || isTablet || isIpadPro
                  ? "bg-white _j_container mb-2"
                  : null
              }
            >
              <>
                {jobs.map((job, i) =>
                  isDesktop || isTablet || isIpadPro ? (
                    <div key={i}>
                      <JobListCard
                        key={i}
                        job={job}
                        index={i}
                        len={jobs?.length}
                        page={page}
                      />
                    </div>
                  ) : (
                    <JobCardSm
                      key={i}
                      job={job}
                      showsStatus={false}
                      page={page}
                    />
                  )
                )}
              </>
            </div>
          ) : (
            <div className="my-4 mb-2">
              <div
                className={`grid_gap ${
                  isDesktop ? "c3" : isTablet ? "c2" : isIpadPro ? "c2" : ""
                }`}
              >
                {jobs.map((job, i) =>
                  isDesktop || isTablet || isIpadPro ? (
                    <span key={i}>
                      <JobCard
                        key={i}
                        job={job}
                        onSearch={true}
                        showsStatus={false}
                        page={page}
                      />
                    </span>
                  ) : (
                    <JobCardSm
                      key={i}
                      job={job}
                      showsStatus={false}
                      page={page}
                    />
                  )
                )}
              </div>
            </div>
          )
        ) : null}
        {count > 12 ? (
          <div
            className={`d-flex justify-content-center w-100 ${
              isMobile ? "mb-5" : ""
            }`}
          >
            <AppPagination
              count={Math.ceil(count / 12)}
              onChange={pagination}
              paginationPage={page}
            />
          </div>
        ) : null}
      </div>
      <div className="py-5" />
      {alertOpen && (
        <ModelMobile
          onClose={() => {
            setAlertOpen(false);
            setAlerts(false);
          }}
          top="6vh"
        >
          <JobAlertCard
            onClose={() => {
              setAlertOpen(false);
              setAlerts(false);
            }}
            onSave={() => {
              dispatch(addKeyword(Query.parse(params.query).q));
            }}
            mobile={true}
            queryParams={queryParams}
          />
        </ModelMobile>
      )}

      {filterOpen && (
        <ModelMobile top="5vh" onClose={() => setFilterOpen(false)}>
          <Filters
            mobile={true}
            filters={newFilter}
            onFilter={() => {
              onFilter();
              setFilterOpen(false);
            }}
            onClearFilters={() => handleClear}
            minAndMax={minAndMax}
          />
        </ModelMobile>
      )}
      {isMobile && (
        <div
          style={{
            left: "0",
            backgroundColor: "#252529",
            height: "50px",
            position: "fixed",
            width: "100%",
            bottom: "0",
          }}
          className="d-flex align-items-center px-4 justify-content-between"
        >
          {user?.keywords && (
            <>
              <AppText color="_c0" size="_14" weight="_medium">
                Create a job alert for this search
              </AppText>
              <Switch
                checked={
                  user?.keywords.find(
                    (key) =>
                      key.toLowerCase().trim() ===
                      Query.parse(params.query).q.toLowerCase().trim()
                  )
                    ? true
                    : alerts
                }
                onChange={() => setAlerts(!alerts)}
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                color="primary"
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
