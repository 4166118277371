import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import AppButton from "../molecules/AppButton";
import AppText from "./AppText";

export default function AppDialog({
  saveText,
  closeText,
  children,
  onClose,
  onSave,
  isLoading,
}) {
  return (
    <div>
      <div className="p-4 d-flex justify-content-center align-items-center flex-column">
        {children}
        <div className="d-flex">
          <AppButton color="_white" onClick={onClose}>
            <AppText size="_14">No</AppText>
          </AppButton>
          <div className="px-1" />
          <AppButton color="_rc" onClick={onSave}>
            {isLoading ? (
              <CircularProgress
                style={{ color: "#fff", height: "30px", width: "30px" }}
              />
            ) : (
              <AppText size="_14" color="_c0">
                Yes, Delete
              </AppText>
            )}
          </AppButton>
        </div>
      </div>
    </div>
  );
}
