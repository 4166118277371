import React from "react";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Zoom from "@material-ui/core/Zoom";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default function ModelMobile({
  children,
  onClose,
  title,
  isModal = true,
  top = "20vh",
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const useStyles = makeStyles({
    root: {
      padding: "0px",
      position: "absolute",
      right: "1rem",
      top: "1.8rem",
      "&:hover": { background: "none" },
      "&:focus": { outline: 0 },
    },
    modal: {
      width:
        isDesktop && isModal
          ? "40%"
          : isTablet && isModal
          ? "60%"
          : (isDesktop || isTablet) && !isModal
          ? "25%"
          : "95%",
      position: "absolute",
      backgroundColor: "#fff",
      borderRadius: "4px",
      marginTop: top,
      maxHeight: isDesktop || isTablet ? "" : "90%",
      overflow: isDesktop || isTablet ? "" : "scroll",
    },
    modalContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  return (
    <Modal
      className={classes.modalContainer}
      open={true}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,.30)" } }}
    >
      <Zoom in={true}>
        <div className={classes.modal}>
          <div className="">
            <IconButton
              disableFocusRipple
              disableRipple
              className={classes.root}
              onClick={onClose}
            >
              <CloseIcon style={{ color: "#9EA0A5", fontSize: "20px" }} />
            </IconButton>
          </div>

          <div>{children}</div>
        </div>
      </Zoom>
    </Modal>
  );
}
