import React, { useState } from "react";
import debounce from "lodash.debounce";
import axios from "axios";
import OutsideClickHandler from "react-outside-click-handler";

export default function LocationSearchMapbox({
  value,
  onChange,
  onValueChange,
  onBlur,
}) {
  const [results, setResults] = useState([]);

  const handleKeydown = async (e) => {
    const search = async () => {
      const search = e.target.value;
      if (search === "") {
        setResults([]);
      } else {
        const res = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${process.env.REACT_APP_MAP_BOX}`
        );
        setResults(res.data.features);
      }
    };
    const d = debounce(search, 150);
    d();
  };
  const handleOnChange = (e) => {
    onValueChange(e);
  };
  const handleClick = (place) => {
    const shortText = `${place.text}, ${
      place.context[place.context?.length - 1].text
    }`;
    const longText = place.place_name;
    const data = {
      shortText,
      longText,
      cordinates: place.geometry.coordinates,
    };
    onChange(data);
    setResults([]);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setResults([])}>
      <input
        placeholder="Search Location"
        onKeyUp={handleKeydown}
        value={value}
        className="mapbox_search"
        onChange={handleOnChange}
        onBlur={onBlur}
      />
      <ul
        className="result__list"
        style={results.length ? { borderTop: "1px solid #dbdbdb" } : null}
      >
        {results?.map((place) => (
          <li onClick={() => handleClick(place)} key={place.place_name}>
            {place.place_name.split("").slice(0, 40)}
          </li>
        ))}
      </ul>
    </OutsideClickHandler>
  );
}
