import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import BoardingPanelSkills from "../../../components/orgnisms/BoardingPanelSkills";
import SkillsTemplate from "../../../components/templates/SkillsTemplate";
import { setStep } from "../../../redux/appDucks";
import AppText from "../../../components/atoms/AppText";

const SAVE_SKILL = gql`
  mutation saveSkill($input: [skillsInput]) {
    addOrUpdateSkill(input: $input)
  }
`;
const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($input: editUserInput) {
    updateUser(input: $input) {
      id
      fullName
    }
  }
`;

export default function Skills({ state }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const skills = useSelector((state) => state.Skill);
  const [saveSkill] = useMutation(SAVE_SKILL);
  const [updateUser] = useMutation(UPDATE_USER_INFO);
  const { user } = useSelector((state) => state.User);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state !== "preview") {
      dispatch(setStep(4));
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (skills.length >= 3) {
      setIsError(false);
    }
  }, [skills]);

  const moveForward = () => {
    setIsLoading(true);
    if (skills.length >= 3) {
      saveSkill({ variables: { input: skills } }).then(() => {
        updateUser({ variables: { input: { accountStage: 4, userId: user.id } } });
        history.push("/build-your-profile/video-cv");
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };
  return (
    <div>
      <BoardingPanelSkills
        heading="Your Skills"
        subHeading="Add a combination of both hard and soft skills to stand out."
        onBack={() => history.goBack()}
        onSubmit={moveForward}
        state={state}
        isLoading={isLoading}
      >
        <SkillsTemplate />
        <div className="mt-3" />
        {skills.length < 3 && isError && (
          <AppText size="_14" color="_r100">
            Please add a minimum of 3 skills
          </AppText>
        )}
      </BoardingPanelSkills>
    </div>
  );
}
