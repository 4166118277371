import React, { useState } from "react";
import AppInput from "../molecules/AppInput";
import { useFormik } from "formik";
import { useMutation, gql } from "@apollo/client";
import * as Yup from "yup";
import { useMediaQuery } from "react-responsive";
import AppText from "../atoms/AppText";
import ModalFooter from "../molecules/ModalFooter";
import AppDropdown from "../molecules/AppDropdown";
import ApiSearchInput from "../molecules/ApiSearchInput";
import LocationSearch from "../molecules/LocationSearch";
import CheckyBox from "../atoms/CheckyBox";
import Moment from "moment";
import AppDateDropdown from "../molecules/AppDateDropdown";

const SAVE_EXPERIENCE = gql`
  mutation saveExperience($input: experienceInput) {
    addOrUpdateExperience(input: $input)
  }
`;

export default function ExperienceModal({ refetch, onClose, data = {} }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [saveExperience] = useMutation(SAVE_EXPERIENCE);
  const [isLoading, setIsLoading] = useState(false);

  const DEFAULT_DATE = new Date();

  const DEFAULT_STARDATE =
    data.startDate !== undefined ? new Date(data.startDate) : DEFAULT_DATE;

  const DEFAULT_ENDDATE =
    data.endDate !== undefined ? new Date(data.endDate) : DEFAULT_DATE;

  const formik = useFormik({
    initialValues: {
      company: data.company?.name || "",
      myRole: data.myRole || "",
      location: data.company?.location || "",
      startDate: DEFAULT_STARDATE,
      endDate: DEFAULT_ENDDATE,
      employType: data?.employmentType || "",
      workHere: data.workHere || false,
    },
    validationSchema: Yup.object().shape({
      company: Yup.string().required("Company is a required field"),
      location: Yup.string().required("Location is a required field"),
      myRole: Yup.string().required("Your Role is a required field"),
      employType: Yup.string().required("Employment type is a required field"),
      startDate: Yup.string().required(),
      endDate: Yup.string()
        .required()
        .test("is", "End date can't before start Date", (value) =>
          Moment(formik.values.startDate).isSameOrBefore(value)
        ),
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      const experience = {
        myRole: values.myRole,
        company: {
          name: values.company.name || values.company,
          logoUrl: values.company.logo || data.company.logoUrl,
        },
        employmentType: values.employType,
        startDate: values.startDate,
        endDate: values.workHere ? DEFAULT_DATE : values.endDate,
        workHere: values.workHere,
      };
      experience.company.location = values.location;

      if (data) {
        experience.id = data.id;
      }

      saveExperience({ variables: { input: experience } }).then((res) => {
        if (res) {
          refetch();
          onClose();
          setIsLoading(false);
        }
      });
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-4 py-3">
          <div className="d-flex justify-content-between flex-wrap">
            <div className={isDesktop || isTablet ? "w-48" : "w-100"}>
              <ApiSearchInput
                defaultValue={formik.values.company}
                formik={formik}
                onChange={(data) => formik.setFieldValue("company", data)}
                hasError={
                  formik.touched.company && formik.errors.company ? true : false
                }
              />
            </div>
            <div className={isDesktop || isTablet ? "w-48" : "w-100 mt-3"}>
              <LocationSearch
                label="LOCATION"
                onChange={(data) =>
                  formik.setFieldValue("location", data.address)
                }
                hasError={
                  formik.touched.location && formik.errors.location
                    ? true
                    : false
                }
                defaultValue={formik.values.location}
              />
              {formik.touched.location && formik.errors.location && (
                <AppText size="_12" color="_r100">
                  {formik.errors.location}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-48 mt-3" : "w-100 mt-3"}>
              <AppInput
                label="YOUR ROLE"
                placeholder="My Role"
                name="myRole"
                value={formik.values.myRole}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={
                  formik.touched.myRole && formik.errors.myRole ? true : false
                }
              />
              {formik.touched.myRole && formik.errors.myRole && (
                <AppText size="_12" color="_r100">
                  {formik.errors.myRole}
                </AppText>
              )}
            </div>
            <div className={isDesktop || isTablet ? "w-48 mt-3" : "w-100 mt-3"}>
              <AppDropdown
                defaultValue={formik.values.employType}
                searchDisabled={true}
                formik={formik}
                name="employType"
                label="EMPLOYMENT TYPE"
                placeholder="Employment type"
                items={["Full Time", "Part Time", "Internship", "Contract"]}
                onChange={(value) => formik.setFieldValue("employType", value)}
              />
            </div>
            <div className={isDesktop || isTablet ? "w-48 mt-3" : "w-100 mt-3"}>
              <AppDateDropdown
                label="START DATE"
                value={formik.values.startDate}
                onChange={(v) => formik.setFieldValue("startDate", v)}
              />
            </div>
            <div className={isDesktop || isTablet ? "w-48 mt-3" : "w-100 mt-3"}>
              <AppDateDropdown
                label="END DATE"
                value={formik.values.endDate}
                onChange={(v) => formik.setFieldValue("endDate", v)}
                disabled={formik.values.workHere ? true : false}
              />

              {formik.touched.endDate && formik.errors.endDate && (
                <AppText size="_12" color="_r100">
                  {formik.errors.endDate}
                </AppText>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <CheckyBox
              checked={formik.values.workHere}
              name="workHere"
              onChange={(e) => {
                formik.handleChange("workHere")(e);

                if (e.target.checked) {
                  formik.setFieldValue(
                    "endDate",
                    new Date("December 17, 3000 03:24:00")
                  );
                } else {
                  formik.setFieldValue("endDate", new Date(DEFAULT_DATE));
                }
              }}
            />
            <span className="d-flex ms-2">
              <AppText size="_14" color="_c90">
                I currently work here
              </AppText>
            </span>
          </div>
        </div>
        <ModalFooter
          onClose={onClose}
          hasErrors={
            !formik.errors.company &&
            !formik.errors.employType &&
            !formik.errors.myRole
              ? false
              : true
          }
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}
