import React, { useState } from "react";
import { Check, PeopleOutlineOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import { STAR_JOB } from "../../apolloUtils";
import AppText from "../atoms/AppText";
import { textCut } from "../../HelperFunctions";
import AppButton from "./AppButton";
import { addFav } from "../../pages/homeStack/HomeDuck";
// import { setAppied } from "../../pages/homeStack/templates/StatusDucks";
import { processHeadings, closeDate } from "../../HelperFunctions";
import numbro from "numbro";

export default function JobListCard({ job, index, len, page }) {
  const status = useSelector((state) => state.Status);
  const { user } = useSelector((state) => state.User);
  const { favourites } = useSelector((state) => state.Home);

  // const [sendApplicationNotification] = useMutation(SEND_APPLICATION_NOTIFICATION);

  const history = useHistory();
  const [hovered, setHovered] = useState(false);
  const [isClick] = useState(false);
  const dispatch = useDispatch();
  const isClosing = closeDate(parseInt(job.closingDate));

  const [addToFavourites] = useMutation(STAR_JOB);
  // const [appTojob] = useMutation(APPLYTOJOB);

  const didApply = status.find((j) => j.job?.id === job?.id);
  const favourited = favourites.find((el) => el.job.id === job?.id);

  const handleClick = () => {
    history.push(`/job/${job.id}`, {
      job,
      location: history.location.pathname,
      page: page,
    });
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const addTofavs = (e) => {
    e.stopPropagation();

    addToFavourites({
      variables: { input: { candidate: user.id, job: job.id } },
    });
    dispatch(addFav({ job }));
  };

  // const applyJob = async (e, didApply) => {
  //   setIsclick(true);
  //   e.stopPropagation();
  //   if (!didApply) {
  //     appTojob({ variables: { input: { jobId: job.id, candidate: user.id } } }).then(() => {
  //       sendApplicationNotification({
  //         variables: {
  //           input: {
  //             string: `${user.firstName} ${user.lastName} Applied to ${job.jobTitle}`,
  //             action: 5,
  //             toUser: job.assignedTo.id,
  //             senderFullName: `${user.firstName} ${user.lastName}`,
  //             senderPhotoUrl: user.photoUrl,
  //           },
  //         },
  //       });
  //       dispatch(setAppied({ job, status: 1 }));
  //     });
  //   }
  // };

  return (
    <div
      className={`_job_list_card pointer `}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="h-100 m-1 rounded _job_list_card_inner">
        <div
          className={`d-flex justify-content-between align-items-center mx-4 h-100 ${
            index !== len - 1 && "_botm_"
          }`}
        >
          <div className="d-flex align-items-center">
            <div className="me-3">
              <img src={job?.company.logo} alt="js" height="46" width="46" />
            </div>
            <div>
              <AppText weight="_medium">
                <span style={{ textTransform: "capitalize" }}>
                  {processHeadings(textCut(job.jobTitle, 30))}
                </span>
              </AppText>
              <div>
                <AppText size="_14" color="_c90">
                  <AppText size="_14" weight="_medium">
                    {textCut(job?.company.name, 12)}
                  </AppText>{" "}
                  • {job?.location.split(",")[0]}
                </AppText>
                <div>
                  {didApply && (
                    <AppText size="_14" color="_g100" weight="_medium">
                      Applied
                    </AppText>
                  )}

                  {job.applicationsCount >= 4 && !didApply ? (
                    <AppText size="_14" color="_g100" weight="_medium">
                      <PeopleOutlineOutlined style={{ fontSize: "15px" }} />{" "}
                      {job.applicationsCount} Applicants
                    </AppText>
                  ) : job.applicationsCount < 4 && !didApply ? (
                    <AppText size="_14" color="_c50" weight="_medium">
                      <PeopleOutlineOutlined style={{ fontSize: "15px" }} /> Be
                      the first to apply{" "}
                    </AppText>
                  ) : null}
                  {!isClosing ? null : (
                    <span className="_12 _medium">
                      <span style={{ color: "#F6AB2F" }}>
                        {" "}
                        {" •"} {isClosing}{" "}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end">
            {hovered ? (
              <div className="d-flex w-100">
                <AppButton
                  color={didApply && "_white"}
                  // onClick={(e) => {
                  //   applyJob(e, didApply);
                  // }}
                  state={isClick}
                >
                  <div>
                    {didApply && <Check style={{ color: "#3E3F42" }} />}
                    <AppText size="_14" color={!didApply && "_c0"}>
                      {didApply ? "Applied" : "Apply Now"}
                    </AppText>
                  </div>
                </AppButton>
                <div className="px-1" />
                {user.firsName && (
                  <AppButton color={"_white"} onClick={addTofavs}>
                    <div>
                      <AppText size="_14">
                        {favourited ? "Saved" : "Save"}
                      </AppText>
                    </div>
                    )
                  </AppButton>
                )}
              </div>
            ) : (
              <>
                <AppText size="_14" color="_c90">
                  Posted {Moment(parseInt(job?.createdAt)).fromNow()}
                </AppText>
                <div />
                {job?.salaryRange?.defaultType !== "hourly" ? (
                  <AppText size="_14" weight="_medium" color="_b100">
                    £
                    {job?.salaryRange?.start !== 0
                      ? job?.salaryRange?.start < 1000
                        ? numbro(job?.salaryRange?.start).format({
                            mantissa: 2,
                          })
                        : numbro(job?.salaryRange?.start).format({
                            thousandSeparated: true,
                            average: true,
                          })
                      : null}
                    {job?.salaryRange?.start !== 0 ? " - £" : ""}
                    {job?.salaryRange?.end < 1000
                      ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                      : numbro(job?.salaryRange?.end).format({
                          thousandSeparated: true,
                          average: true,
                        })}
                  </AppText>
                ) : (
                  <AppText size="_14" weight="_medium" color="_b100">
                    £
                    {job?.salaryRange?.end < 1000
                      ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                      : numbro(job?.salaryRange?.end).format({
                          thousandSeparated: true,
                          average: true,
                        })}
                    /hr
                  </AppText>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
