import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation, gql } from "@apollo/client";
import * as Yup from "yup";
import AppInput from "../molecules/AppInput";
import ModalFooter from "../molecules/ModalFooter";
import AppText from "../atoms/AppText";
import { validateEmbedUrl } from '../../HelperFunctions/index'

const SAVE_EMBED_CODE = gql`
  mutation saveEmbedCode($input: editUserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;

export default function EmbedModal({ refetch, onClose }) {
  const [saveEmbedCode] = useMutation(SAVE_EMBED_CODE);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false)

  const formik = useFormik({
    initialValues: {
      embedCode: "",
    },
    validationSchema: Yup.object().shape({
      embedCode: Yup.string().required().url(),
    }),
    onSubmit: (value) => {
      if (validateEmbedUrl(value)) {
        setError(true)
      } else {
        setIsLoading(true);
        saveEmbedCode({ variables: { input: { embedCode: value.embedCode } } }).then(() => {
          refetch();
          onClose();
          setIsLoading(false);
        });
      }
    },
  });

  useEffect(() => { }, [error])
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='px-4 py-3'>
          <div>
            <AppInput
              label='EMBED CODE OR URL'
              name='embedCode'
              placeholder='Paste url code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.embedCode}
            />
            {formik.touched.embedCode && formik.errors.embedCode && (
              <AppText size='_12' color='_r100'>
                {formik.errors.embedCode}
              </AppText>
            )}
            {error && (
              <AppText size='_12' color='_r100'>
                Please enter a valid video format
              </AppText>
            )}
          </div>
        </div>

        <ModalFooter
          onClose={onClose}
          hasErrors={formik.errors.embedCode ? true : false}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}
