export const allSkills = [
  { value: "General", label: "User Interface Design" },
  { value: "General", label: "Foreign Languages" },
  { value: "General", label: "Social Media" },
  { value: "General", label: "Teaching" },
  { value: "General", label: "Project Management" },
  { value: "General", label: "Computer Technology" },
  { value: "General", label: "Accounting & Finance" },
  { value: "General", label: "Business & Data Analysis" },
  { value: "General", label: "Nursing" },
  { value: "General", label: "Economics" },
  { value: "General", label: "Automotive Services, Parts and Design" },
  { value: "General", label: "SEO/SEM Marketing" },
  { value: "General", label: "Cloud and Distributed Computing" },
  { value: "General", label: "Data Presentation" },
  { value: "General", label: "Database Management and Software" },
  { value: "General", label: "Electronic and Electrical Engineering" },
  { value: "General", label: "Copywriting" },
  { value: "General", label: "Statistical Analysis and Data Mining" },
  { value: "General", label: "PHP" },
  { value: "General", label: "CSS/HTML" },
  { value: "General", label: "Ruby" },
  { value: "General", label: "Python" },
  { value: "General", label: "Perl" },
  { value: "General", label: "C/C++" },
  { value: "General", label: "JavaScript" },
  { value: "General", label: "Java" },
  { value: "General", label: "Engineering" },
  { value: "General", label: "Technical Writing" },
  { value: "General", label: "Human Resources (HR)" },
  { value: "General", label: "Objective C" },
  { value: "General", label: "Google Analytics" },
  { value: "General", label: "Android Development" },
  { value: "General", label: "IOS Development" },
  { value: "General", label: "Design Research" },
  { value: "Tools", label: "Adobe Xd" },
  { value: "Tools", label: "Adobe Illustrator" },
  { value: "Tools", label: "Wireframes" },
  { value: "Soft skills", label: "Communication" },
  { value: "Soft skills", label: "Collaboration" },
  { value: "Soft skills", label: "Research" },
  { value: "Soft skills", label: "Public Speaking" },
  { value: "Soft skills", label: "Cultural Intelligence" },
  { value: "Soft skills", label: "Analytical Thinking" },
  { value: "Soft skills", label: "Multi-tasking" },
  { value: "Soft skills", label: "Conflict Management" },
  { value: "Soft skills", label: "Empathy" },
  { value: "Soft skills", label: "Self-starter" },
  { value: "Soft skills", label: "Strategic Planning" },
];
