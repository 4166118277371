import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined";
import Geocode from "react-geocode";
import { useMediaQuery } from "react-responsive";
import AppText from "../atoms/AppText";
import AppIconButton from "../atoms/AppIconButton";
import LocationSearchMapbox from "./LocationSearchMapbox";
import AppButton from "./AppButton";
import { validatePostalCode } from "../../HelperFunctions/index";

export default function SearchBox2({ values = {}, onSubmit }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [value, setValue] = useState(values);
  const [l, setl] = useState(validatePostalCode(values.location));
  // console.log(values.location)

  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "40px",
      },
    },
    input1: {
      fontFamily: "Roboto",
      height: "17px",
      color: "#3E3F42",
      border: "1px solid #D8DCE6",
      borderRadius: "4px",
    },
  });
  const classes = useSyles();

  const getPlace = async (lat, lng) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.setLocationType("APPROXIMATE");
    const location = await Geocode.fromLatLng(lat, lng);
    // const locationString = `${location.results[0].formatted_address.split(",")[1]}, ${location.results[0].formatted_address.split(",")[2]
    //   }`;

    setValue({
      query: value.query,
      location: location.plus_code.compound_code,
      cords: [lng, lat],
    });

    setl(location.plus_code.compound_code.substring(8));
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          getPlace(p.coords.latitude, p.coords.longitude);
        },
        () => {},
        { enableHighAccuracy: true }
      );
    }
  };

  return (
    <div className="d-flex justify-content-between w-100 flex-wrap">
      <div className={isDesktop || isTablet ? "w-38" : "w-100 mb-1"}>
        <TextField
          value={value.query === "allJobs" ? "" : value.query}
          name="query"
          onChange={(e) => setValue({ ...value, query: e.target.value })}
          size="small"
          variant="outlined"
          placeholder="Search Job title, Company, Keywords"
          className={classes.root}
          inputProps={{
            className: classes.input1,
          }}
        />
      </div>
      <div className={isDesktop || isTablet ? "w-38" : "w-100 mb-1"}>
        <div className="d-flex align-items-center _search_x px-2">
          <div className="w-100">
            <LocationSearchMapbox
              onBlur={(e) => setValue({ ...value, location: e.target.value })}
              value={l}
              onChange={(data) => {
                setl(data.shortText);
                setValue({
                  ...value,
                  location: data.shortText,
                  cords: data.cordinates,
                });
              }}
              onValueChange={(e) => setl(e.target.value)}
            />
          </div>

          <AppIconButton onClick={getCurrentLocation}>
            <LocationOnOutlined
              style={{ fontSize: "20px", color: "#CECFD2" }}
            />
          </AppIconButton>
        </div>
      </div>
      <div className={isDesktop || isTablet ? "w-20" : "w-100"}>
        <AppButton
          width="_full"
          onClick={() => onSubmit(value)}
          state={value?.query === "" && value?.location === "" && true}
        >
          <AppText color="_c0" size="_16">
            Search
          </AppText>
        </AppButton>
      </div>
    </div>
  );
}
