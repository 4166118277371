import React, { useImperativeHandle } from "react";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Popover from "@material-ui/core/Popover";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#1318201d",
    "& .MuiPopover-paper": {
      backgroundColor: "#fff",
      boxShadow: "0 1px 2px #00000014!important",
      border: "1px solid #D8DCE6",
    },
  },
});

const Appddown = React.forwardRef(
  ({ vertical = "bottom", horizontal = "start", children }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      close: () => {
        handleClose();
      },
    }));

    const open = Boolean(anchorEl);
    const id = "simple-popover";

    return (
      <div>
        <span onClick={handleClick}>
          <MoreHoriz />
        </span>
        <Popover
          className={classes.root}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: vertical,
            horizontal: horizontal,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {children}
        </Popover>
      </div>
    );
  }
);

export default Appddown;
