import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import DeleteIcon from "@material-ui/icons/Delete";
import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";
import Model from "../molecules/Model";
import EmbedModal from "../orgnisms/EmbedModal";
import UploadVideoModal from "../orgnisms/UploadVideoModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import VideoCV from "../orgnisms/VideoCV";

import { fire } from '../../firebase/index'
import { useSelector } from "react-redux";

const FETCH_VIDE_CV = gql`
  query ($id: String!){
    getCurrentUser (uid: $id) {
      id
      embedCode
      videoUrl
      watchedVideo
      videoAskStatus
    }
  }
`;

const REMOVE_VIDEO = gql`
  mutation removeVideo($input: editUserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;


export default function VideoCvTemplate({ immutable = true, uploaded, preview = false }) {
  const { loading, data, refetch } = useQuery(
    FETCH_VIDE_CV,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: fire.auth().currentUser.uid
      }
    });

  const { user } = useSelector((state) => state.User);
  const [isEmbed, setIsEmbed] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const [removeVideo] = useMutation(REMOVE_VIDEO);

  useEffect(() => {
    if (
      !loading &&
      (data?.getCurrentUser?.embedCode || data?.getCurrentUser?.videoUrl)
    ) {
      if (uploaded !== undefined) {
        uploaded(true);
      }
    } else {
      if (uploaded !== undefined) {
        uploaded(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    data?.getCurrentUser?.embedCode,
    data?.getCurrentUser?.videoUrl,
  ]);

  if (loading) return <CircularProgress style={{ color: "#9ea0a6" }} />;

  const handleRemoveVideo = () => {
    if (data?.getCurrentUser?.embedCode !== null) {
      removeVideo({ variables: { input: { embedCode: null, userId: user.id } } }).then(() => {
        refetch();
      });
    } else {
      removeVideo({ variables: { input: { videoUrl: null, userId: user.id } } }).then(() => {
        refetch();
      });
    }
  };

  return (
    <div>
      {!loading && data ? (
        data?.getCurrentUser?.embedCode || data?.getCurrentUser?.videoUrl ? (
          <div>
            <VideoCV
              key={"videoCv"}
              url={
                data?.getCurrentUser?.videoUrl !== null
                  ? data?.getCurrentUser?.videoUrl
                  : data?.getCurrentUser?.embedCode
              }
            />
            {immutable && (
              <div className="mt-3">
                <AppButton color="_white" onClick={handleRemoveVideo}>
                  <DeleteIcon
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: "#E6492D",
                    }}
                  />
                  <AppText size="_14" color="_r100">
                    Remove Video
                  </AppText>
                </AppButton>
              </div>
            )}
          </div>
        ) : (
          <div>

            {data?.getCurrentUser?.watchedVideo || data?.getCurrentUser?.videoAskStatus === 'completed' ? (
              <div>
                <AppText size='_14' color='_c50'>
                  Your video is currently being processed. We will let you know when it has been uploaded.
                </AppText>
                <VideoCV key={"videoCv"} url={
                  data?.getCurrentUser?.videoUrl !== null ?
                    data?.getCurrentUser?.videoUrl : data?.getCurrentUser?.embedCode} />
              </div>
            ) :
              preview ? null : (
                <iframe src="https://www.videoask.com/f480a9cyq"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="100%"
                  height="600px"
                  style={{ border: "none", borderRadius: "24px" }}
                  title="videoAsk"
                >
                </iframe>
              )}

            {/* {immutable && (
              <div className={'d-flex w-100 justify-content-between'}>
                <div>
                  <div className='mb-2'>
                    <AppText size='_14' color='_c50'>
                      Embed a video from an online source
                    </AppText>
                  </div>
                  <AppButton color='_white' onClick={() => setIsEmbed(true)}>
                    <AppText size='_14'>Embed Video</AppText>
                  </AppButton>
                </div>
                <div>
                  <div className='mb-2'>
                    <AppText size='_14' color='_c50'>
                      Upload a video from your library
                    </AppText>
                  </div>
                  <AppButton color='_white' onClick={() => setIsUpload(true)}>
                    <AppText size='_14'>Upload Video</AppText>
                  </AppButton>
                </div>
              </div>
            )} */}
          </div>
        )
      ) : null}
      {isEmbed && (
        <Model title="Embed Video" onClose={() => setIsEmbed(false)}>
          <EmbedModal refetch={refetch} onClose={() => setIsEmbed(false)} />
        </Model>
      )}
      {isUpload && (
        <Model title="Upload Video" onClose={() => setIsUpload(false)}>
          <UploadVideoModal
            refetch={refetch}
            onClose={() => setIsUpload(false)}
          />
        </Model>
      )}
    </div>
  );
}
