import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function CardListSkeleton() {
  return (
    <div className="_job_list_card bg-white d-flex justify-content-between align-items-center p-4">
      <div className="d-flex align-items-center">
        <Skeleton width={50} height={70} />
        <div className="ms-2">
          <Skeleton width={150} height={22} />
          <Skeleton width={150} height={22} />
        </div>
      </div>
      <div>
        <Skeleton width={100} height={60} />
      </div>
    </div>
  );
}
