import React, { useState } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import All from "./notificationTemplates/all";
import Messages from "./notificationTemplates/Messages";
import Alerts from "./notificationTemplates/JobAlerts";
import Status from "./notificationTemplates/JobStatus";

const useStyles = makeStyles({
  root: {
    height: "50px",
    fontWeight: "normal",
  },
  tab: {
    height: "50px",
    textTransform: "none",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#03CA98",
  },
});

export default function Notifications() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  //  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const { allNotifications } = useSelector((state) => state.User);
  const messages = allNotifications.filter((el) => el.action === 1);
  const jobAlerts = allNotifications.filter((el) => el.action === 2);
  const JobStatus = allNotifications.filter((el) => el.action === 3);

  function statuses() {
    switch (value) {
      case 1:
        return <Messages notification={messages} />;
      case 2:
        return <Alerts notification={jobAlerts} />;
      case 3:
        return <Status notification={JobStatus} />;
      default:
        return <All notification={allNotifications} />;
    }
  }

  return (
    <div className="_bg_color">
      <div className="_status_header d-flex justify-content-center">
        <div className={isDesktop ? "w-75" : "w-100"}>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={(w, v) => setValue(v)}
            className={classes.root}
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              disableRipple
              label="All Notifications"
              className={classes.tab}
            />
            <Tab disableRipple label="Messages" className={classes.tab} />
            <Tab disableRipple label="Job Alerts" className={classes.tab} />
            <Tab disableRipple label="Job Status" className={classes.tab} />
          </Tabs>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div className={isDesktop ? "w-75" : "w-95"}>{statuses()}</div>
      </div>
    </div>
  );
}
