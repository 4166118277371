import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/styles/makeStyles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&$checked": {
      color: "#3D70B2",
    },
    padding: 0,
  },
});

export default function CheckyBox({ checked, name, onChange, disabled }) {
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      className={classes.root}
      checked={checked}
      name={name}
      onChange={onChange}
      disabled={disabled}
      icon={<CheckBoxOutlineBlankIcon style={{ color: "#D8DCE6" }} />}
      checkedIcon={
        <CheckBoxIcon style={{ color: disabled ? "#D8DCE6" : "#03CA98" }} />
      }
    />
  );
}
