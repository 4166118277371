import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($id: String!) {
    getCurrentUser(uid: $id) {
      id
      fullName
      firstName
      lastName
      email
      accountStage
      accountComplete
      phoneNumber
      photoUrl
      country
      strength
      city
      embedCode
      summary
      keywords
      videoUrl
      verified
      heardUs
      desiredJob
      receivesAlertsBy
      receivesPromotionsBy
      receivesAlertWhen
      notificationsLastSeen
      statusLastSeen
      messagesLastSeen
      class
      sessionExp
      cvUrl
    }
  }
`;

export const QUERY_JOBS = gql`
  query ($input: SearchInput) {
    queryJobs(searchInput: $input) {
      jobs {
        id
        jobTitle
        company {
          id
          name
          logo
          header
          location
          services
          websiteLink
          instagramLink
          linkedinLink
          twitterLink
          facebookLink
          founded
          culture
          bio
          overview
          size
        }
        mapCordinates {
          coordinates
        }
        location
        description
        experienceLevel
        employmentType
        remotePossible
        applicationsCount
        industry
        createdAt
        closingDate
        skills
        assignedTo {
          id
        }
        salaryRange {
          defaultType
          start
          end
        }
      }
      count
    }
  }
`;

export const STAR_JOB = gql`
  mutation ($input: FavInput) {
    addToFavourites(input: $input) {
      id
    }
  }
`;

export const APPLYTOJOB = gql`
  mutation apply($input: _ApplyJobInput) {
    appyForJob(input: $input) {
      id
    }
  }
`;

export const SAVE_SUMMARY = gql`
  mutation saveSummary($input: editUserInput) {
    updateUser(input: $input) {
      id
      summary
      cvUrl
    }
  }
`;

export const GET_MY_JOBS = gql`
  query ($input: ID!) {
    getAppliedJobs(userId: $input) {
      id
      status
      job {
        id
        jobTitle
        company {
          id
          name
          services
          bio
          overview
          size
          logo
          header
          instagramLink
          founded
          location
          facebookLink
          twitterLink
          linkedinLink
          culture
          websiteLink
        }
        experienceLevel
        location
        mapCordinates {
          lat
          lng
        }
        employmentType
        description
        salaryRange {
          start
          end
        }
        industry
        remotePossible
        applicationsCount
        skills
        assignedTo {
          id
        }
        visitCount
        createdAt
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: userInput) {
    createUser(input: $input) {
      id
      fullName
      firstName
      lastName
      email
      accountStage
      accountComplete
      phoneNumber
      photoUrl
      country
      strength
      city
      embedCode
      summary
      keywords
      videoUrl
      verified
      heardUs
      desiredJob
      receivesAlertsBy
      receivesPromotionsBy
      receivesAlertWhen
      notificationsLastSeen
      statusLastSeen
      messagesLastSeen
      class
    }
  }
`;

export const SAVE_PROFILE_IMAGE = gql`
  mutation saveProfileImage($input: editUserInput) {
    updateUser(input: $input) {
      id
      photoUrl
    }
  }
`;
export const GET_POPULAR_JOBS = gql`
  query {
    getPopularJobs {
      id
      jobTitle
    }
  }
`;
export const UPDATE_JOB_VIEW = gql`
  mutation ($input: JobViewInput) {
    jobViewed(input: $input)
  }
`;
export const SIMILAR_JOBS = gql`
  query ($query: String!) {
    similarJobs(jobTitle: $query) {
      id
      jobTitle
      experienceLevel
      location
      mapCordinates {
        lat
        lng
      }
      employmentType
      description
      salaryRange {
        start
        end
      }
      industry
      remotePossible
      applicationsCount
      skills
      assignedTo {
        id
      }
      company {
        id
        logo
        name
        services
        bio
        overview
        size
        websiteLink
        founded
        culture
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        header
      }
    }
  }
`;
export const SEND_APPLICATION_NOTIFICATION = gql`
  mutation ($input: NoticationInput) {
    sendNotification(input: $input) {
      id
    }
  }
`;
export const GET_USER_IMPRESSION = gql`
  query ($id: String!) {
    getCurrentUser(uid: $id) {
      sessionExp
      experience {
        id
      }
      education {
        id
      }
      skills {
        value
      }
      videoUrl
      embedCode
    }
  }
`;

export const FETCH_NOTIFICATIONS = gql`
  subscription ($input: ID!) {
    notificationListener(userId: $input) {
      id
      string
      action
      read
      senderFullName
      senderPhotoUrl
      sentAt
    }
  }
`;

export const FETCH_ALL_NOTIFICATIONS = gql`
  query ($input: ID!) {
    getMyNotifications(userId: $input) {
      id
      string
      action
      sentAt
      senderFullName
      senderPhotoUrl
      read
    }
  }
`;
export const GET_MY_FAVS = gql`
  query ($input: ID!) {
    getMyFavs(userId: $input) {
      job {
        id
        jobTitle
        company {
          id
          name
          logo
          header
          location
          services
          websiteLink
          instagramLink
          linkedinLink
          twitterLink
          facebookLink
          founded
          culture
          bio
          overview
          size
        }
        mapCordinates {
          lat
          lng
        }
        location
        description
        experienceLevel
        employmentType
        remotePossible
        applicationsCount
        industry
        createdAt
        skills
        assignedTo {
          id
        }
        salaryRange {
          start
          end
        }
      }
    }
  }
`;

export const GET_COMPANY_JOBS = gql`
  query ($input: CompanyJobInput) {
    getJobsByCompany(input: $input) {
      id
      jobTitle
      experienceLevel
      location
      mapCordinates {
        coordinates
      }
      employmentType
      description
      salaryRange {
        start
        end
      }
      industry
      remotePossible
      applicationsCount
      skills
      closingDate
      company {
        id
        logo
        name
        services
        bio
        overview
        size
        websiteLink
        culture
        founded
        location
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        header
      }
      assignedTo {
        id
      }
      createdAt
    }
  }
`;

export const GET_JOB_BY_ID = gql`
  query ($id: String!) {
    getJobById(jobId: $id) {
      id
      jobTitle
      description
      location
      employmentType
      experienceLevel
      createdAt
      salaryRange {
        defaultType
        start
        end
      }
      assignedTo {
        id
        fullName
        avatarUrl
        roles {
          as
          company {
            name
          }
        }
      }
      applyLinkUrl
      industry
      applicationsCount
      skills
      closingDate
      company {
        id
        name
        culture
        founded
        websiteLink
        facebookLink
        instagramLink
        twitterLink
        header
        logo
        linkedinLink
        overview
        bio
        size
        location
        services
      }
    }
  }
`;

export const SET_ALERTS = gql`
  mutation ($input: AlertInput) {
    setAlerts(input: $input)
  }
`;

export const SEND_SLACK_ALERT = gql`
  query ($name: String!, $platform: Int!) {
    sendSlackAlert(name: $name, platform: $platform)
  }
`;
export const GET_COMPANY_BY_ID = gql`
  query ($id: ID!) {
    getCompanyById(id: $id) {
      logo
      location
      size
      linkedinLink
      facebookLink
      bio
      overview
      header
      founded
      name
      twitterLink
      culture
      services
      websiteLink
      instagramLink
    }
  }
`;

export const SIGNOUT = gql`
  mutation signOut($id: String!) {
    signOutUser(id: $id)
  }
`;

export const SUBSCRIBE_TO_SMS = gql`
  query ($input: SmsInput) {
    subscribeToSmsNotifications(input: $input)
  }
`;

export const GET_NOTIFICATION_SETTING = gql`
  query ($input: ID!) {
    getNotificationSettings(userId: $input) {
      emails
      sms
      subscribedTo
    }
  }
`;

export const CHANGE_SUBSCRIPTION = gql`
  mutation ($input: SubscriptionStatusInput) {
    changeSubscriptionStatus(input: $input) {
      emails
      sms
    }
  }
`;

export const SAVE_SKILL = gql`
  mutation saveSkill($input: [skillsInput]) {
    addOrUpdateSkill(input: $input)
  }
`;
