import React, { Suspense, lazy } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import BuilderRouter from "../buildYourProfile";
import Header from "../../components/orgnisms/Header";
// import FindJob from "../homeStack/templates/FindJob";
// import Results from "../homeStack/templates/Results";
const CodeVerification = lazy(() => import("./templates/CodeVerification"));
const ForgotPassword = lazy(() => import("./templates/ForgotPassword"));
const Login = lazy(() => import("./templates/Login"));
const ResetPassword = lazy(() => import("./templates/ResetPassword"));
const NotFound = lazy(() => import("./templates/NotFound"));
const Job = lazy(() => import("./templates/Job"));
const ResetPasswordModal = lazy(() =>
  import("../../components/templates/ResetPasswordModal")
);
const SignUp = lazy(() => import("./templates/SignUp"));

export default function SignupX({ page = "signup" }) {
  return (
    <Router>
      {page === "signup" ? (
        <Switch>
          {/* <Route path="/">
            <Header />
            <div className="mb-5" />
            <Suspense fallback="">
              <FindJob />
            </Suspense>
          </Route> */}
          {/* <Route path="/search/:query">
            <Header />
            <div className="mb-5" />
            <Suspense fallback="">
              <Results />
            </Suspense>
          </Route> */}
          <Route path="/" exact>
            <Header />
            <div className="mb-5" />
            <Suspense fallback="">
              <SignUp />
            </Suspense>
          </Route>
          <Route path="/job/:id">
            <Header />
            <Suspense fallback="">
              <Job />
            </Suspense>
          </Route>
          <Route path="/login">
            <Header />
            <div className="mb-5" />
            <Suspense fallback="">
              <Login />
            </Suspense>
          </Route>
          <Route path="/forgot_password">
            <Header />
            <div className="mb-5" />
            <Suspense fallback="">
              <ForgotPassword />
            </Suspense>
          </Route>
          <Route path="/reset_password">
            <Header />
            <div className="mb-5" />
            <Suspense fallback="">
              <ResetPassword />
            </Suspense>
          </Route>
          <Route path="/sent">
            <Header />
            <Suspense fallback="">
              <ResetPasswordModal
                heading="Reset link sent!"
                sub="Password reset link has been sent to"
              />
            </Suspense>
          </Route>
          <Route path="/success">
            <Header />
            <Suspense fallback="">
              <ResetPasswordModal
                heading="Your password has been reset!"
                sub="You can now log into your account with the new password."
              />
            </Suspense>
          </Route>
          <Route component={Login} />
        </Switch>
      ) : (
        <Switch>
          <Route path={"/build-your-profile"}>
            <BuilderRouter />
          </Route>
          <Route exact path={"/"}>
            <Header setupStack={true} />
            <Suspense fallback="">
              <CodeVerification verified={false} />
            </Suspense>
          </Route>
          <Suspense fallback="">
            <Route component={NotFound} />
          </Suspense>
        </Switch>
      )}
    </Router>
  );
}
