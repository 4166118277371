import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import AppText from "../../../components/atoms/AppText";
import { useDispatch, useSelector } from "react-redux";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import VideoCvTemplate from "../../../components/templates/VideoCvTemplate";
import { setStep } from "../../../redux/appDucks";
import { sleep } from "../../../HelperFunctions/index"

const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($input: editUserInput) {
    updateUser(input: $input) {
      id
      fullName
      watchedVideo
      videoAskStatus
    }
  }
`;


export default function Video({ state }) {
  // const [hasVideo, setHasVideo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [updateUser] = useMutation(UPDATE_USER_INFO);

  const { user } = useSelector((state) => state.User);

  const dispatch = useDispatch();
  useEffect(() => {
    if (state !== "preview") {
      dispatch(setStep(5));
    }
  }, [dispatch, state]);

  const moveBack = () => {
    history.goBack();
  };

  const moveForward = async () => {
    setIsLoading(true)
    await sleep(8000)
    updateUser({ variables: { input: { accountStage: 4, userId: user.id } } }).then((data) => {
      const userInfo = data.data.updateUser
      if (userInfo.videoAskStatus === 'completed') {
        updateUser({ variables: { input: { accountStage: 5, userId: user.id } } });
        setIsLoading(false)
        history.push("/build-your-profile/preview");
      } else {
        setHasError(true);
        setIsLoading(false)
      }
    })
  };

  const onSkip = () => {
    updateUser({ variables: { input: { accountStage: 5 , userId: user.id } } });
    history.push("/build-your-profile/preview");
  };

  const handleVideo = (v) => {
    // setHasVideo(v);
    // if (v) {
    //   setHasError(false);
    // }
  };
  // const test = '#'

  return (
    <div>
      <BoardingPanel
        heading="Video"
        subHeading="Adding a video increases your chances of getting hired."
        onBack={moveBack}
        onSubmit={moveForward}
        state={state}
        // onSkip={hasVideo ? undefined : user.class === 'B' ? undefined : onSkip}
        // help={true}
        // helpText={["Don't know where to start with your video? click",'to watch our video for some tips']}
        // herf={'https://www.youtube.com/watch?v=jjFq4Qs2i_k&ab_channel=TapRecruit'}
        isLoading={isLoading}
        onSkip={user.class === 'A' ? onSkip : undefined}
      >
        <div>
          <VideoCvTemplate uploaded={(empty) => handleVideo(empty)} />
          {hasError && (
            <AppText size="_12" color="_r100">
              Please record your video
            </AppText>
          )}
          {/* <div className={'mt-2'}>
            <AppText size='_14' color='_c50'>
              Don't know where to start with your video? click {' '}
              <a href={test} onClick={handleModalVideo}>here</a>
              {' '} to watch our video for some tips
            </AppText>
          </div> */}
        </div>
      </BoardingPanel>

      <div className="pt-5" />
      <div className="pt-5" />
      {/* {modalOpen && (
        <Model onClose={handleModalVideo} title={'Video Tips'}>
          <VideoModal />
        </Model>
      )} */}
    </div>
  );
}
