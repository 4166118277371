import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import AppInputIcon from "../atoms/AppInputIcon";
import AppText from "../atoms/AppText";

export default function SalaryRange({
  formik,
  onChange,
  isFixed,
  isHourly,
  setIsFixed,
  setIsHourly,
}) {
  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <AppText size="_12" color="_c50" weight="_medium">
          SALARY RANGE (
          {!isFixed && !isHourly ? "ANNUALLY" : isFixed ? "FIXED" : "HOURLY"})
        </AppText>
      </div>
      {/* Salary Range */}
      <div>
        {!isFixed && !isHourly ? (
          // ANNUALLY
          <div className={"d-flex flex-row"}>
            <div className="me-2">
              <AppInputIcon
                placeholder="Min"
                name="salary.salary.start"
                value={formik.values?.salary?.salary?.start}
                onChange={(e) => onChange(e, "salary", "start")}
                formik={formik}
              />
            </div>
            <div>
              <AppInputIcon
                label=""
                placeholder="Max"
                name="salary.salary.end"
                value={formik.values?.salary?.salary?.end}
                onChange={(e) => onChange(e, "salary", "end")}
                formik={formik}
              />
            </div>
          </div>
        ) : (
          // FIXED or HOURLY
          <div className={"d-flex flex-row"}>
            <div className="me-2 w-100">
              <AppInputIcon
                placeholder="Enter amount"
                name="salary.salary.end"
                value={formik.values?.salary?.salary?.end}
                onChange={(e) => onChange(e, "salary", "end")}
                formik={formik}
              />
            </div>
          </div>
        )}
        {/* Check Boxs */}
        <div className={"d-flex flex-row"}>
          <div className="mt-2 d-flex align-items-center">
            <Checkbox
              name="Fixed"
              value="Fixed"
              checked={isFixed}
              icon={
                <CheckBoxOutlineBlankIcon
                  style={{ fontSize: "16px", color: "#D8DCE6" }}
                />
              }
              checkedIcon={
                <CheckBoxIcon style={{ fontSize: "16px", color: "#03CA98" }} />
              }
              onChange={() => {
                setIsHourly(false);
                setIsFixed(!isFixed);
              }}
              style={{
                padding: 0,
                marginRight: "5px",
              }}
            />{" "}
            <AppText size="_14" color="_c90">
              Fixed
            </AppText>
          </div>
          <div className="mt-2 ms-3 d-flex align-items-center">
            <Checkbox
              name="Hourly"
              value="Hourly"
              checked={isHourly}
              icon={
                <CheckBoxOutlineBlankIcon
                  style={{ fontSize: "16px", color: "#D8DCE6" }}
                />
              }
              checkedIcon={
                <CheckBoxIcon style={{ fontSize: "16px", color: "#03CA98" }} />
              }
              onChange={() => {
                setIsFixed(false);
                setIsHourly(!isHourly);
              }}
              style={{
                padding: 0,
                marginRight: "5px",
              }}
            />{" "}
            <AppText size="_14" color="_c90">
              Hourly
            </AppText>
          </div>
        </div>
        {formik.touched.salary?.salary?.start &&
          formik.errors.salary?.salary?.start &&
          !formik.errors.salary?.salary?.end &&
          !isFixed &&
          !isHourly && (
            <AppText size="_12" color="_r100">
              {formik.errors.salary?.salary?.start}
            </AppText>
          )}
        {formik.touched.salary?.salary?.end &&
          formik.errors.salary?.salary?.end &&
          !isFixed &&
          !isHourly && (
            <AppText size="_12" color="_r100">
              {formik.errors.salary?.salary?.end}
            </AppText>
          )}
        {formik.touched.salary?.salary?.end &&
          formik.touched.salary?.salary?.start &&
          !formik.errors.salary?.salary?.end &&
          !formik.errors.salary?.salary?.start &&
          parseFloat(formik.values.salary?.salary?.start) >=
            parseFloat(formik.values.salary?.salary?.end) &&
          !isFixed &&
          !isHourly && (
            <AppText size="_12" color="_r100">
              The Min value must be lower than the Max value
            </AppText>
          )}
        {formik.touched.salary?.salary?.end &&
          formik.errors.salary?.salary?.end &&
          (isFixed || isHourly) && (
            <AppText size="_12" color="_r100">
              Please enter an amount
            </AppText>
          )}
      </div>
    </div>
  );
}
