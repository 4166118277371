const FETCH_SKILLS = "FETCH_SKILLS";
const ADD_SKILL = "ADD_SKILL";
const DELETE_SKILL = "DELETE_SKILL";

const initialState = [];

const SkillReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SKILLS:

      return [...action.payload];
    case ADD_SKILL:

      const newState = [...state];
      const skill = newState.findIndex((el) => el.label === action.payload.label);
      if (skill === -1) {
        newState.push(action.payload);
      }
      return [...newState];
    case DELETE_SKILL:

      const skills = [...state]
      const index = skills.findIndex((el) => el.label === action.payload.label);

      skills.splice(index, 1);
      return [ ...skills];
    default:
      return state;
  }
};

export const fetchSkills = (st) => {
  return {
    type: FETCH_SKILLS,
    payload: st,
  };
};
export const addSkill = (st) => {
  return {
    type: ADD_SKILL ,
    payload: st,
  };
};

export const deleteSkill = (st) => {
  return {
    type: DELETE_SKILL ,
    payload: st,
  };
};


export default SkillReducer;
