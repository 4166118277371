import React from "react";
import { useMediaQuery } from "react-responsive";
import JobCard from "../../../components/molecules/JobCard";
import { useSelector } from "react-redux";
import JobCardSm from "../../../components/molecules/JobCardSm";
import NoFavorites from "../../../components/molecules/NoFavourites";

export default function Favourites() {
  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isIpadPro = useMediaQuery({ minWidth: 993 });

  const { favourites } = useSelector((state) => state.Home);

  return (
    <div className='d-flex justify-content-center _bg_color'>
      <div className={isDesktop || isIpadPro ? "w-75" : isTablet ? "w-82" : "w-95"}>
        <div
          className={`mt-4 grid_gap ${isDesktop ? "c4" : isTablet ? "c2" : isIpadPro ? "c3" : ""}`}
        >
          {favourites?.map(({ job }) =>
            isDesktop || isTablet || isIpadPro ? (
              <span key={job.id}>
                <JobCard showsStatus={false} job={job} isGrid={true} />
              </span>
            ) : (
              <JobCardSm key={job.id} job={job} showsStatus={false} />
            )
          )}

        </div>
        {favourites.length === 0 && <NoFavorites />}
      </div>
    </div>
  );
}
