import React from "react";
import InputFiles from "react-input-files";
import AppButton from "../molecules/AppButton";
import AppText from "./AppText";

export default function UploadButton({ onChange, label }) {
  return (
    <InputFiles onChange={onChange} accept={"*"}>
      <AppButton color="_white">
        <AppText>{label}</AppText>
      </AppButton>
    </InputFiles>
  );
}
