import React from "react";
import { Link } from "react-router-dom";
import { textCut } from "../../HelperFunctions";
import AppText from "../atoms/AppText";

export default function MoreCard({
  header = "Available Jobs",
  footer = "SEE MORE JOBS",
  loading,
  jobs,
  name,
}) {
  return (
    <div className="bg-white _companybox">
      <div className="py-3 px-4">
        <AppText size="_18" weight="_medium">
          {header}
        </AppText>
      </div>
      <div className="_sep_" />
      <div className="px-4 pt-3 pb-4">
        {loading && <AppText>Loading...</AppText>}
        {!loading &&
          jobs.map((job, index) => (
            <div key={job.id} className="my-3">
              <Link to={{ pathname: `/job/${job.id}`, state: job }}>
                <div className="d-flex mb-3">
                  <div>
                    <img
                      src={job?.company.logo}
                      alt="js"
                      height="46"
                      width="46"
                      className="me-3"
                    />
                  </div>
                  <div>
                    <AppText weight="_medium">
                      {textCut(job.jobTitle, 18)}
                    </AppText>
                    <br />
                    <AppText size="_14" color="_c90">
                      {textCut(job.company.name, 11)} |{" "}
                      {job.location.split(",")[0]}
                    </AppText>
                  </div>
                </div>
              </Link>
              {index !== jobs.length - 1 && <div className="_sep_" />}
            </div>
          ))}
      </div>

      <div className="_sep_" />
      <div className="py-3 d-flex justify-content-center align-items-center">
        <Link to={`/search/q=${name}&location=&cord=`}>
          <AppText size="_12" weight="_medium" color="_c90">
            {footer}
          </AppText>
        </Link>
      </div>
    </div>
  );
}
